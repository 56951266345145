import React from "react";
import { Tag } from "antd";
import { CustomBtn } from "../../series/StyledComponent";
import { EditOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { Image } from "antd";
import { getFileNameWithURL } from "../../videos/modals/AddVideosMetaDataForm";
import { refIdColumn } from "../../utils/table-columns";
import { DeletePopConfirm } from "../../custom-components";

const getThumbnailColumns = (
	editHelper: (record: any) => void,
	deleteThumbnails: (id: string) => void,
	isEpisodePage?: boolean
) => {
	const columnThumbnails: ColumnsType<any> = [
		{
			title: "Preview",
			dataIndex: "preview_url",
			key: "url-image",
			render: (value: string) => {
				return (
					<Image
						width={100}
						height={"auto"}
						onClick={e => e.stopPropagation()}
						src={value}
						fallback="FALLBACK_IMAGE"
					/>
				);
			},
		},
		!isEpisodePage ? refIdColumn : {},
		{
			title: "File Name",
			dataIndex: "file_name",
			key: "file_name",
			render: (value: string, record: any) => {
				const fileName = getFileNameWithURL(record.url);
				return <span>{value?.trim() ? value : fileName}</span>;
			},
		},
		{
			title: "Width",
			dataIndex: "width",
			key: "width",
		},
		{
			title: "Height",
			dataIndex: "height",
			key: "height",
		},
		{
			title: "Tags",
			dataIndex: "thumbnail_tag_titles",
			key: "thumbnail_tag_titles",
			render: (value: string) => {
				return value?.length
					? value?.split(",")?.map(tag => (
							<Tag key={tag} color="success" title={tag}>
								{tag}
							</Tag>
					  ))
					: null;
			},
		},
		{
			title: "Distribution Platforms",
			dataIndex: "thumbnail_distribution_names",
			key: "thumbnail_distribution_names",
			width: "220px",
			render: (value: any) => {
				return value
					? value?.split(",").map((title: string) => (
							<Tag key={title} title={title}>
								{title}
							</Tag>
					  ))
					: "";
			},
		},
		{
			title: "Actions",
			dataIndex: "action",
			key: "action",
			render: (value, record: any) => {
				return (
					<>
						<CustomBtn
							icon={<EditOutlined />}
							type={"link"}
							onClick={e => {
								e.stopPropagation();
								editHelper(record);
							}}
						/>
						<DeletePopConfirm
							title="Delete Thumbnail?"
							deleteHandler={() => deleteThumbnails(record.id)}
						>
							Are you sure you want to delete this thumbnail?
						</DeletePopConfirm>
					</>
				);
			},
		},
	];

	return columnThumbnails;
};

export default getThumbnailColumns;
