import React from 'react';
import { Select, DatePicker, Input, Popover } from 'antd';
import dayjs from 'dayjs';
import { UploadFile } from 'antd/es/upload/interface';
import { InvoiceDataType } from '../../../utils/common.interface';
const { Option } = Select;



interface DropdownValues {
  partners: string[];
  channels: string[];
  territories: string[];
  platforms: string[];
  domains: string[];
  labels: string[];
  platformsByDomain: Record<string, string[]>;
  partnersByDomain: Record<string, string[]>;
  channelsByPartner: Record<string, string[]>;
  territoriesByPartner: Record<string, string[]>;
  partnersByPlatform: Record<string, string[]>;
  platformsPartnersTerritories: Record<string, string[]>;
}

const getColumns = (
  handleInputChange: (value: any, key: string, column: string, dataSource: InvoiceDataType[], setDataSource: React.Dispatch<React.SetStateAction<InvoiceDataType[]>>) => void,
  dataSource: InvoiceDataType[],
  setDataSource: React.Dispatch<React.SetStateAction<InvoiceDataType[]>>,
  dropdownValues: DropdownValues,
  fileList: UploadFile[],
  renamedFiles: { [key: string]: string },
  uploadUrls: { [key: string]: { url: string; fileId: string } },
  handleFileSelect: (fileName: string, fileId: string, key: string, dataSource: InvoiceDataType[], setDataSource: React.Dispatch<React.SetStateAction<InvoiceDataType[]>>) => void
) => [
  {
    title: 'Domain*',
    dataIndex: 'domain',
    key: 'domain',
    width: 200, // Set default width
    render: (text: string, record: InvoiceDataType) => {
      const domainOptions = dropdownValues.domains.sort().map((domain) => (
        <Option key={domain} value={domain}>{domain}</Option>
      ));
      return (
        <Popover
          content={
            <Select
              value={text}
              onChange={(value) => handleInputChange(value, record.key, 'domain', dataSource, setDataSource)}
              style={{ width: '200px' }}
            >
              {domainOptions}
            </Select>
          }
          trigger="click"
        >
          <Select
            value={text}
            onChange={(value) => handleInputChange(value, record.key, 'domain', dataSource, setDataSource)}
            style={{ width: '100%' }}
          >
            {domainOptions}
          </Select>
        </Popover>
      );
    },
  },
  {
    title: 'Platform*',
    dataIndex: 'platform',
    key: 'platform',
    width: 200, // Set default width
    render: (text: string, record: InvoiceDataType) => {
      const platformOptions = (dropdownValues.platformsByDomain[record.domain] || []).sort().map((platform) => (
        <Option key={platform} value={platform}>{platform}</Option>
      ));
      return (
        <Popover
          content={
            <Select
              value={text}
              onChange={(value) => handleInputChange(value, record.key, 'platform', dataSource, setDataSource)}
              style={{ width: '200px' }}
              disabled={!record.domain}
            >
              {platformOptions}
            </Select>
          }
          trigger="click"
        >
          <Select
            value={text}
            onChange={(value) => handleInputChange(value, record.key, 'platform', dataSource, setDataSource)}
            style={{ width: '100%' }}
            disabled={!record.domain}
          >
            {platformOptions}
          </Select>
        </Popover>
      );
    },
  },
  {
    title: 'Partner*',
    dataIndex: 'partner',
    key: 'partner',
    width: 200, // Set default width
    render: (text: string, record: InvoiceDataType) => {
      const partnerOptions = (dropdownValues.partnersByPlatform[record.platform] || []).sort().map((partner) => (
        <Option key={partner} value={partner}>{partner}</Option>
      ));
      return (
        <Popover
          content={
            <Select
              value={text}
              onChange={(value) => handleInputChange(value, record.key, 'partner', dataSource, setDataSource)}
              style={{ width: '200px' }}
              disabled={!record.platform}
            >
              {partnerOptions}
            </Select>
          }
          trigger="click"
        >
          <Select
            value={text}
            onChange={(value) => handleInputChange(value, record.key, 'partner', dataSource, setDataSource)}
            style={{ width: '100%' }}
            disabled={!record.platform}
          >
            {partnerOptions}
          </Select>
        </Popover>
      );
    },
  },
  {
    title: 'Payment Date*',
    dataIndex: 'paymentDate',
    key: 'paymentDate',
    width: 150, // Set default width
    render: (text: string, record: InvoiceDataType) => {
      const displayFormat = 'MM/DD';
      const valueFormat = 'MM/DD/YYYY';
      return (
        <Popover
          content={
            <DatePicker
              value={text ? dayjs(text, valueFormat) : null}
              onChange={(date) => {
                const formattedDateString = date ? date.format(valueFormat) : '';
                handleInputChange(formattedDateString, record.key, 'date', dataSource, setDataSource);
              }}
              format={valueFormat}
              style={{ width: '200px' }}
            />
          }
          trigger="click"
        >
          <DatePicker
            value={text ? dayjs(text, valueFormat) : null}
            onChange={(date, dateString) => {
              const formattedDateString = date ? date.format(valueFormat) : '';
              handleInputChange(formattedDateString, record.key, 'date', dataSource, setDataSource);
            }}
            format={displayFormat}
            style={{ width: '100%' }}
          />
        </Popover>
      );
    },
  },
  {
    title: 'Channel*',
    dataIndex: 'channel',
    key: 'channel',
    width: 200, // Set default width
    render: (text: string, record: InvoiceDataType) => {
      const channelOptions = (dropdownValues.channelsByPartner[record.partner] || []).sort().map((channel) => (
        <Option key={channel} value={channel}>{channel}</Option>
      ));
      return (
        <Popover
          content={
            <Select
              value={text}
              onChange={(value) => handleInputChange(value, record.key, 'channel', dataSource, setDataSource)}
              style={{ width: '200px' }}
              disabled={!record.partner}
            >
              {channelOptions}
            </Select>
          }
          trigger="click"
        >
          <Select
            value={text}
            onChange={(value) => handleInputChange(value, record.key, 'channel', dataSource, setDataSource)}
            style={{ width: '100%' }}
            disabled={!record.partner}
          >
            {channelOptions}
          </Select>
        </Popover>
      );
    },
  },
  {
    title: 'Territory',
    dataIndex: 'territory',
    key: 'territory',
    width: 200, // Set default width
    render: (text: string, record: InvoiceDataType) => {
      const territoryOptions = (dropdownValues.platformsPartnersTerritories[`${record.platform}_${record.partner}`] || []).sort().map((territory) => (
        <Option key={territory} value={territory}>{territory}</Option>
      ));
      return (
        <Popover
          content={
            <Select
              value={text}
              onChange={(value) => handleInputChange(value, record.key, 'territory', dataSource, setDataSource)}
              style={{ width: '200px' }}
              disabled={!record.channel}
            >
              {territoryOptions}
            </Select>
          }
          trigger="click"
        >
          <Select
            value={text}
            onChange={(value) => handleInputChange(value, record.key, 'territory', dataSource, setDataSource)}
            style={{ width: '100%' }}
            disabled={!record.channel}
          >
            {territoryOptions}
          </Select>
        </Popover>
      );
    },
  },
  {
    title: 'Label*',
    dataIndex: 'label',
    key: 'label',
    width: 200, // Set default width
    render: (text: string, record: InvoiceDataType) => {
      const labelOptions = dropdownValues.labels.sort().map((label) => (
        <Option key={label} value={label}>{label}</Option>
      ));
      return (
        <Popover
          content={
            <Select
              value={text}
              onChange={(value) => handleInputChange(value, record.key, 'label', dataSource, setDataSource)}
              style={{ width: '200px' }}
            >
              {labelOptions}
            </Select>
          }
          trigger="click"
        >
          <Select
            value={text}
            onChange={(value) => handleInputChange(value, record.key, 'label', dataSource, setDataSource)}
            style={{ width: '100%' }}
          >
            {labelOptions}
          </Select>
        </Popover>
      );
    },
  },
  {
    title: 'Register Name*',
    dataIndex: 'registerName',
    key: 'registerName',
    width: 200, // Set default width
    render: (text: string, record: InvoiceDataType) => (
      <Popover
        content={
          <Input
            value={text}
            onChange={(e) => handleInputChange(e.target.value, record.key, 'registerName', dataSource, setDataSource)}
            style={{ width: '200px' }}
          />
        }
        trigger="click"
      >
        <Input
          value={text}
          onChange={(e) => handleInputChange(e.target.value, record.key, 'registerName', dataSource, setDataSource)}
          style={{ width: '100%' }}
        />
      </Popover>
    ),
  },
  {
    title: 'Amount*',
    dataIndex: 'amount',
    key: 'amount',
    width: 150, // Set default width
    render: (text: string, record: InvoiceDataType) => (
      <Popover
        content={
          <Input
            value={text}
            onChange={(e) => handleInputChange(e.target.value, record.key, 'amount', dataSource, setDataSource)}
            style={{ width: '200px' }}
          />
        }
        trigger="click"
      >
        <Input
          value={text}
          onChange={(e) => handleInputChange(e.target.value, record.key, 'amount', dataSource, setDataSource)}
          style={{ width: '100%' }}
        />
      </Popover>
    ),
  },
  {
    title: 'File',
    dataIndex: 'fileName',
    key: 'fileName',
    width: 250, // Set default width
    render: (text: any, record: any) => {
      const fileOptions = fileList.sort((a, b) => (a.name > b.name ? 1 : -1)).map(file => {
        const fileId = uploadUrls[file.uid]?.fileId || '';
        return (
          <Select.Option
            key={file.uid}
            value={JSON.stringify({ fileName: renamedFiles[file.uid] || file.name, fileId })}
          >
            {renamedFiles[file.uid] || file.name}
          </Select.Option>
        );
      });
      const recordValue = record.fileName && record.fileId ? JSON.stringify({ fileName: record.fileName, fileId: record.fileId }) : '';
      return (
        <Popover
          content={
            <Select
              value={recordValue}
              onChange={(value) => {
                const { fileName, fileId } = JSON.parse(value);
                handleFileSelect(fileName, fileId, record.key, dataSource, setDataSource);
              }}
              style={{ width: '200px' }}
            >
              {fileOptions}
            </Select>
          }
          trigger="click"
        >
          <Select
            value={recordValue}
            onChange={(value) => {
              const { fileName, fileId } = JSON.parse(value);
              handleFileSelect(fileName, fileId, record.key, dataSource, setDataSource);
            }}
            style={{ width: '100%' }}
          >
            {fileOptions}
          </Select>
        </Popover>
      );
    },
  },
];

export default getColumns;
