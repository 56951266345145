import styled from "@emotion/styled";

export const TaskRow = styled.div`
	/* margin-top: 24px; */
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const TaskCol = styled.div`
	padding-bottom: 12px;
	display: flex;
	justify-content: space-around;
`;

export const TaskText = styled.p`
	margin: 0;
	padding: 0;
	display: inline-block;
	width: 200px;
	// color: #f5f5dc;
`;
