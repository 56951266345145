import React from "react";
import { ColumnsType } from "antd/es/table";
import { Checkbox } from "antd";

export const getBulkSelectColumns = (
  itemsData: any[],
  selectedItemsData: any[],
  setSelectedItemsData: (data: any) => void,
  handleSelectedRowStyling: (keys: string[], isRemove?: boolean) => void,
  sessionStorageKey: string,
) => {
  const columnBulkSelect: ColumnsType<any> = [
    {
      title: (
        <Checkbox
          checked={(() => {
            if (!itemsData.length) return false;
            const totalSelectedIds = selectedItemsData.map(item => item.id);
            const selectedIds = itemsData.filter(item =>
              totalSelectedIds.includes(item.id)
            );
            return selectedIds.length === itemsData.length;
          })()}
          onChange={e => {
            e.stopPropagation();
            
            if (e.target.checked) {
              const combinedSelectedData = [
                ...selectedItemsData,
                ...itemsData.map((item: any) => ({ id: item.id, key: item.key })),
              ];
              // unique values
              const combinedSelectedIds = combinedSelectedData.map(v => v.id);
              const uniqueData = combinedSelectedData.filter(
                (v, i) => combinedSelectedIds.indexOf(v.id) === i
              );
              // const uniqueKeys = combinedSelectedData.filter((v, i, a) => a.indexOf(v.id) === i).map((v) => v.key);
              setSelectedItemsData(uniqueData);
              const selectedItemsKeys = uniqueData.map((item: any) => item.key);
              handleSelectedRowStyling(selectedItemsKeys);
              sessionStorage.setItem(sessionStorageKey, JSON.stringify(uniqueData));
            } else {
              const selectedItemsKeys = selectedItemsData.map((item: any) => item.key);
              handleSelectedRowStyling(selectedItemsKeys, true);
              const currentIds = itemsData.map((item: any) => item.id);
              const remainingIds = selectedItemsData.filter(v => !currentIds.includes(v.id));
              setSelectedItemsData(remainingIds);
              sessionStorage.setItem(sessionStorageKey, JSON.stringify(remainingIds));
            }
          }
        }
        />
      ),
      dataIndex: "select_item",
      key: "select_item",
      width: "60px",
      align: "center",
      render: (value, record: any) => {
        const { id, key } = record;
        return (
          <Checkbox
            id={"checkBox_" + id}
            checked={selectedItemsData.map(item => item.id).includes(id) ? true : false}
            onClick={e => {
              e.stopPropagation();
            }}
            onChange={e => {
              const checkBox = document.getElementById("checkBox_" + id) as HTMLInputElement;
              if (checkBox.checked) {
                handleSelectedRowStyling([record.key]);
                const updatedSelectedData = [...selectedItemsData, { id, key }];
                setSelectedItemsData(updatedSelectedData);
                sessionStorage.setItem(sessionStorageKey, JSON.stringify(updatedSelectedData));
              } else {
                handleSelectedRowStyling([record.key], true);
                const updatedSelectedData = selectedItemsData.filter(
                  item => item.id !== id
                );
                setSelectedItemsData(updatedSelectedData);
                sessionStorage.setItem(sessionStorageKey, JSON.stringify(updatedSelectedData));
              }
              e.stopPropagation();
            }}
          />
        );
      },
    }];

  return columnBulkSelect;
};

export default getBulkSelectColumns;