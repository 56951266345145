import { useState } from 'react';
import { UploadFile } from 'antd/es/upload/interface';
import { InvoiceDataType } from '../../../utils/common.interface';
import { useDropdownValues } from './invoiceUploadHook';
import {
  handleInputChange,
  handleAddLine,
  handleRemoveLine,
  handleSelectAll,
  handleFileChange,
  handleFileSelect,
  handleSaveFile,
  handleUploadData
} from '../helpers/helpers';
import getColumns from '../helpers/columns';
import { authAxios } from "../../../utils/session_utils";


export const useInvoiceUpload = (initialDataSource: InvoiceDataType[]) => {
  const [dataSource, setDataSource] = useState<InvoiceDataType[]>(initialDataSource);
  const [viewershipPool, setViewershipPool] = useState<any>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [renamedFiles, setRenamedFiles] = useState<{ [key: string]: string }>({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddInvoiceModalVisible, setIsAddInvoiceModalVisible] = useState(false);
  const [currentFile, setCurrentFile] = useState<UploadFile | null>(null);
  const [renamedFile, setRenamedFile] = useState('');
  const [uploadUrls, setUploadUrls] = useState<{ [key: string]: { url: string; fileId: string } }>({});
  const [fileId, setFileId] = useState<string | null>(null);
  const [uploadedFiles, setUploadedFiles] = useState<{ [key: string]: boolean }>({});
  const [uploadStatus, setUploadStatus] = useState<string>('');
  const [operationId, setOperationId] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [showUploadView, setShowUploadView] = useState(true);
  const [selectedRecord, setSelectedRecord] = useState<InvoiceDataType | null>(null);
  const [isProgressModalVisible, setIsProgressModalVisible] = useState(false);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [invoiceForm, setInvoiceForm] = useState({
    domain: '',
    platform: '',
    partner: '',
    paymentDate: '',
    channel: '',
    territory: '',
    label: '',
    registerName: '',
    lineItems: 1,
    allocationLevel: '',
    allocationPeriod: '',
  });

  const dropdownValues = useDropdownValues();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const columns = getColumns(handleInputChange, dataSource, setDataSource, dropdownValues, fileList, renamedFiles, uploadUrls, handleFileSelect);

  const handleInvoiceFormChange = (field: string, value: any) => {
    setInvoiceForm({ ...invoiceForm, [field]: value });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const handleUploadDataWithErrorHandling = async () => {
    setIsProgressModalVisible(true);
    try {
      await handleUploadData(
        dataSource, 
        setIsUploading, 
        setUploadStatus, 
        setOperationId, 
        setShowUploadView, 
        setViewershipPool, 
        setIsProgressModalVisible,
        setErrorMessage, 
        setIsErrorModalVisible
      );
    } catch (error) {
      console.log('error caught in handleUploadDataWithErrorHandling ')
      let errorMessage = 'An unknown error occurred';
      if (error instanceof Error) {
        errorMessage = error.message;
        console.log(error.message)
      }
      setErrorMessage(errorMessage);
      setIsErrorModalVisible(true);
      setIsProgressModalVisible(false);
    } 
  };
  
  
  

  const handleAddInvoice = () => {
    const newRows: InvoiceDataType[] = [];
    for (let i = 0; i < invoiceForm.lineItems; i++) {
      newRows.push({
        key: `${dataSource.length + i + 1}`,
        domain: invoiceForm.domain,
        platform: invoiceForm.platform,
        partner: invoiceForm.partner,
        paymentDate: invoiceForm.paymentDate,
        channel: invoiceForm.channel,
        territory: invoiceForm.territory,
        label: invoiceForm.label,
        registerName: invoiceForm.registerName,
        amount: '',
        selected: false,
        allocationStrategy: {
          platform: true,
          partner: true,
          channel: true,
          territory: true,
          customAllocation: {}
        }, 
        allocationLevel: invoiceForm.allocationLevel,
        allocationPeriod: invoiceForm.allocationPeriod,
        linkedViewershipPool: '',
        linkedToViewership: false,
      });
    }
    setDataSource([...dataSource, ...newRows]);
    setIsAddInvoiceModalVisible(false);
  };

  const handleFileChangeWrapper = (info: any) => {
    handleFileChange(
      info,
      setFileList,
      setCurrentFile,
      setRenamedFile,
      setIsModalVisible,
      setUploadUrls,
      setFileId
    );
  };



  const handleSaveFileWrapper = () => {
    handleSaveFile(
      currentFile,
      uploadUrls,
      renamedFile,
      fileList,
      setFileList,
      renamedFiles,
      setRenamedFiles,
      dataSource,
      setDataSource,
      uploadedFiles,
      setUploadedFiles,
      setIsModalVisible
    );
  };

  const handleSelectAllWrapper = () => {
    handleSelectAll(dataSource, setSelectedRowKeys);
  };

  const handleRemoveLineWrapper = () => {
    handleRemoveLine(selectedRowKeys, dataSource, setDataSource, setSelectedRowKeys);
  };

  const handleAddLineWrapper = () => {
    handleAddLine(dataSource, setDataSource);
  };



  const handleAllocationChange = (key: string, field: string, value: string | boolean | Record<string, number>) => {
    setDataSource(prevDataSource => 
      prevDataSource.map(record => 
        record.key === key
          ? { ...record, [field]: value }
          : record
      )
    );
    
    setSelectedRecord(prev => 
      prev ? { ...prev, [field]: value } : prev
    );
  };

  const handleAllocate = async (keys: string[]): Promise<boolean> => {
    const userEmail = window.sessionStorage.getItem("mvmgsid") || ""; 
  
    const selectedRecords = keys.map(key => {
      const record = dataSource.find(r => r.key === key);
      if (!record) {
        console.error(`No record found for key: ${key}`);
        return null;
      }
      return {
        key: record.key,
        platform: record.platform,
        partner: record.partner,
        channel: record.channel,
        territory: record.territory,
        amount: record.amount,
        paymentDate: record.paymentDate,
        allocationStrategy: record.allocationStrategy,
        allocationLevel: record.allocationLevel,
        allocationPeriod: record.allocationPeriod,
      };
    }).filter(record => record !== null);
  
    if (selectedRecords.length === 0) {
      console.error('No valid records selected for allocation');
      return false;
    }

    const payload = {
      selectedRecords,
      userEmail
    };
  
    console.log('payload: ', payload);
    setIsProgressModalVisible(true);

    try {
      const { allocationOperationId } = await initiateAllocation(payload);

  
      // Start polling immediately
      pollAllocationStatus(
        allocationOperationId,
        setAllocationStatus,
        keys,
        setIsProgressModalVisible,
        setErrorMessage,
        setIsErrorModalVisible
      );
  
      return true;
    } catch (error) {
      console.error('Error during allocation:', error);
      setIsProgressModalVisible(false);
      setErrorMessage('Failed to initiate allocation process');
      setIsErrorModalVisible(true);
      return false;
    }
  };
  
  const initiateAllocation = async (payload: any) => {
    const response = await authAxios({
      method: 'POST',
      url: `${process.env.REACT_APP_BACK_END_API}/snowflake/allocate`,
      data: payload,
    });
    return response.data;
  };

  const linkViewershipPoolToRecord = (recordKey: string, poolId: string) => {
    setDataSource(prevDataSource =>
      prevDataSource.map(record =>
        record.key === recordKey
          ? { ...record, linkedViewershipPool: poolId }
          : record
      )
    );
  };
  type AllocationStatusType = string;

  const [allocationStatus, setAllocationStatus] = useState<AllocationStatusType>('');
  const [isAllocationModalVisible, setIsAllocationModalVisible] = useState(false);
  
  const updateAllocationStatus = (status: string) => {
    setAllocationStatus(status);
    setIsAllocationModalVisible(status !== 'allocation_completed' && status !== 'error');
  };
  
  // Update the handleAllocationStart function
  const handleAllocationStart = async (keys: string[]) => {
    updateAllocationStatus('allocation_started');
    setIsAllocationModalVisible(true);
  
    console.log('handle allocation');
  
    try {
      const success = await handleAllocate(keys);
      if (!success) {
        updateAllocationStatus('error');
      }
    } catch (error) {
      updateAllocationStatus('error');
    }
  };

  

const pollAllocationStatus = async (
  operationId: string,
  setAllocationStatus: React.Dispatch<React.SetStateAction<string>>,
  keys: string[],
  setIsProgressModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  setIsErrorModalVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  let attempts = 0;
  const maxAttempts = 60;
  const pollInterval = 5000;

  const poll = async () => {
    try {
      const data = await pollAllocationStatusAPI(operationId);
      console.log('Received allocation data:', data);
      console.log('op id:', operationId);

      setAllocationStatus(data.status);

      switch (data.status) {
        case 'allocation_started':
        case 'pre_processing':
        case 'checking_rollup_table_for_dupes':
        case 'performing_rollups':
        case 'preparing_lambda_payloads':
        case 'invoking_lambda':
          attempts++;
          if (attempts < maxAttempts) {
            setTimeout(poll, pollInterval);
          } else {
            handleErrorState('Allocation operation timed out');
          }
          break;
        case 'allocation_completed':
          setIsProgressModalVisible(false);
          break;
        case 'error':
          handleErrorState(data.message || 'An error occurred during the allocation operation');
          break;
        default:
          console.log(`Unexpected allocation status: ${data.status}, continuing to poll`);
          attempts++;
          if (attempts < maxAttempts) {
            setTimeout(poll, pollInterval);
          } else {
            handleErrorState(`Polling stopped after ${maxAttempts} attempts. Last status: ${data.status}`);
          }
      }
    } catch (error) {
      console.error('Error during allocation status polling:', error);
      attempts++;
      if (attempts < maxAttempts) {
        console.log(`Allocation polling attempt ${attempts} failed. Retrying...`);
        setTimeout(poll, pollInterval);
      } else {
        handleErrorState('Allocation polling failed after maximum attempts');
      }
    }
  };

  const handleErrorState = (errorMsg: string) => {
    setAllocationStatus('error');
    setIsProgressModalVisible(false);
    setErrorMessage(errorMsg);
    setIsErrorModalVisible(true);
  };

  poll();
};
  
  const pollAllocationStatusAPI = async (operationId: string) => {
    try {
      const response = await authAxios({
        method: 'GET',
        url: `${process.env.REACT_APP_BACK_END_API}/snowflake/payment-upload-job-status/${operationId}`,
      });
      
      console.log('API Response:', response.data);
      return response.data;
    } catch (error) {
      console.error('API Error:', error);
      if (error instanceof Error) {
        return { status: 'error', message: error.message };
      } else {
        return { status: 'error', message: 'An unknown error occurred' };
      }
    }
  };

  return {
    dataSource,
    setDataSource,
    fileList,
    renamedFiles,
    isModalVisible,
    isAddInvoiceModalVisible,
    currentFile,
    renamedFile,
    uploadUrls,
    fileId,
    uploadedFiles,
    uploadStatus,
    operationId,
    isUploading,
    showUploadView,
    invoiceForm,
    dropdownValues,
    selectedRowKeys,
    columns,
    rowSelection,
    setIsModalVisible,
    setIsAddInvoiceModalVisible,
    setCurrentFile,
    setRenamedFile,
    setShowUploadView,
    handleInvoiceFormChange,
    handleAddInvoice,
    handleFileChangeWrapper,
    handleSaveFileWrapper,
    handleSelectAllWrapper,
    handleRemoveLineWrapper,
    handleAddLineWrapper,
    handleAllocationChange,
    handleAllocate,
    linkViewershipPoolToRecord,
    selectedRecord, 
    setSelectedRecord,
    viewershipPool,
    isProgressModalVisible, 
    allocationStatus, 
    setAllocationStatus, 
    updateAllocationStatus,
    handleAllocationStart,
    isErrorModalVisible, 
    setIsErrorModalVisible,
    errorMessage, 
    setErrorMessage,
    handleUploadDataWithErrorHandling
  };
};

