import { message } from "antd";
import { useState } from "react";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { authAxios, getEmailFromSessionStorage } from "../../utils/session_utils";

const useAutoPodderUploadHook = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [file, setFile] = useState<any>(null);

	const handleFileRemove = () => {
		setFile(null);
		const index = fileList.indexOf(file);
		const newFileList = fileList.slice();
		newFileList.splice(index, 1);
		setFileList(newFileList);
		return true;
	};

	const beforeUpload = (file: any) => {
		fileList.push(file);
		setFileList(fileList);
		setFile(file);
		return false;
	};

	const uploadProps: UploadProps = {
		accept: ".csv",
		onRemove: handleFileRemove,
		beforeUpload: beforeUpload,
		fileList,
	};

	const handleUpload = async () => {
		// access input element and get value from parent component
		// const toEmailInput = document.querySelector("input[name=toEmailInput]") as HTMLInputElement;
		const toEmail = getEmailFromSessionStorage();
		// if email is empty or domain part is not mvmediasales.com then add error
		if (toEmail?.length === 0 || !toEmail?.endsWith("mvmediasales.com")) {
			message.error("Please enter a valid mvmediasales email address");
		} else if (fileList.length !== 1) {
			message.error("Please upload exactly 1 csv file, and it must be post log file");
		} else {
			setLoading(true);
			const formData = new FormData();
			formData.append("file1", fileList[0] as RcFile);
			// formData.append("file2", fileList[1] as RcFile);
			formData.append("toEmail", toEmail);
			formData.append("func", "postLogProcessing");

			try {
				const config = {
					method: "POST",
					url: `${process.env.REACT_APP_BACK_END_API}/auto-podder/upload`,
					data: formData,
					headers: {
						"Content-Type": "multipart/form-data",
					},
				};
				const response = await authAxios(config);
				if (!response.data.err) {
					message.success(response.data.message);
					setFile(null);
					setFileList([]);
				} else {
					message.error("Something went wrong");
				}
				setLoading(false);

			} catch (error) {
				setLoading(false);
				console.error(error);
			}
		}
	};
	return { loading, file, handleUpload, uploadProps };
};

export default useAutoPodderUploadHook;
