import React from 'react';
import { StyledCard, TitleGreen, SubTitleGreen } from './styles/styles';
import './styles/styles.css';
import { InvoiceDataType } from '../../utils/common.interface';
import InvoiceUploadView from './components/InvoiceUploadView';
import { useInvoiceUpload } from './hooks/useInvoiceUpload';
import AllocationManager from './components/AllocationManager/AllocationManager';
import ProgressModal from './modals/progressModal';
import ErrorModal from './modals/errorModal'; 


const InvoiceUploadComponent: React.FC = () => {
  
  const initialDataSource: InvoiceDataType[] = [];
  

  const {
    dataSource,
    setDataSource,
    fileList,
    renamedFiles,
    isModalVisible,
    isAddInvoiceModalVisible,
    currentFile,
    renamedFile,
    uploadStatus,
    isUploading,
    showUploadView,
    invoiceForm,
    dropdownValues,
    selectedRowKeys,
    columns,
    rowSelection,
    setIsModalVisible,
    setIsAddInvoiceModalVisible,
    setCurrentFile,
    setRenamedFile,
    handleInvoiceFormChange,
    handleAddInvoice,
    handleFileChangeWrapper,
    handleSaveFileWrapper,
    handleSelectAllWrapper,
    handleRemoveLineWrapper,
    handleAddLineWrapper,
    handleAllocate,
    linkViewershipPoolToRecord,
    selectedRecord, 
    setSelectedRecord,
    viewershipPool,
    isProgressModalVisible, 
    allocationStatus, 
    isErrorModalVisible, 
    setIsErrorModalVisible,
    errorMessage, 
    handleUploadDataWithErrorHandling
  } = useInvoiceUpload(initialDataSource);

  return (
    <StyledCard className="card-custom">
      <TitleGreen>Nosey</TitleGreen>
      <SubTitleGreen>Payment Upload</SubTitleGreen>
      
      {showUploadView ? (
        <InvoiceUploadView
          dataSource={dataSource}
          columns={columns}
          rowSelection={rowSelection}
          selectedRowKeys={selectedRowKeys}
          fileList={fileList}
          renamedFiles={renamedFiles}
          isModalVisible={isModalVisible}
          isAddInvoiceModalVisible={isAddInvoiceModalVisible}
          isUploading={isUploading}
          uploadStatus={uploadStatus}
          currentFile={currentFile}
          renamedFile={renamedFile}
          dropdownValues={dropdownValues}
          invoiceForm={invoiceForm}
          handleUploadData={handleUploadDataWithErrorHandling}
          handleSelectAll={handleSelectAllWrapper}
          handleRemoveLine={handleRemoveLineWrapper}
          handleAddLine={handleAddLineWrapper}
          setIsAddInvoiceModalVisible={setIsAddInvoiceModalVisible}
          setCurrentFile={setCurrentFile}
          setRenamedFile={setRenamedFile}
          setIsModalVisible={setIsModalVisible}
          handleFileChange={handleFileChangeWrapper}
          handleSaveFile={handleSaveFileWrapper}
          handleAddInvoice={handleAddInvoice}
          handleInvoiceFormChange={handleInvoiceFormChange}
          setDataSource={setDataSource}
        />
      ) : (
        <AllocationManager
          dataSource={dataSource}
          setDataSource={setDataSource}
          handleAllocate={handleAllocate}
          linkViewershipPoolToRecord={linkViewershipPoolToRecord}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          viewershipPool={viewershipPool}
          allocationStatus={allocationStatus}
        />
      )}
      <ProgressModal 
        visible={isProgressModalVisible}
        uploadStatus={uploadStatus}
        allocationStatus={allocationStatus}
      />
      <ErrorModal
        visible={isErrorModalVisible}
        message={errorMessage}
        onClose={() => setIsErrorModalVisible(false)}
      />
    </StyledCard>
  );
};


export default InvoiceUploadComponent;