import React, { FC } from "react";
import styled from "@emotion/styled";
import { Modal, Typography } from "antd";
import moment from "moment-timezone";

interface StyledModalProps {
	ref_id?: string;
	videoEditId?: string;
}

export const CustomModal = styled(Modal)<StyledModalProps>`
	& .ant-modal-body {
		padding: 0;
		overflow-y: auto;
		height: 80vh;
		overflow-x: auto;
		transition: all 0.3s;
	}
`;

export const VideoFormModal = styled(Modal)<StyledModalProps>`
	&.ant-modal {
		width: 1175px !important;
	}

	& .ant-modal-body {
		max-height: calc(100vh - 115px);
		overflow: hidden;
	}

	/* & #video-form-main-row {
		height: calc(100vh - 115px);
		overflow: hidden;
	} */

	& #video-details,
	#distribution-details {
		max-height: calc(100vh - 115px);
		overflow-y: auto;
	}
`;

type ModalTitleWithLastModifiedDateType = {
	title: string;
	lastModifiedDate: string;
};

export const ModalTitleWithLastModifiedDate: FC<ModalTitleWithLastModifiedDateType> = ({
	title,
	lastModifiedDate,
}) => {
	return (
		<>
			<Typography.Title level={4} style={{ marginBottom: -4 }}>
				{title}
			</Typography.Title>
			<Typography.Text type="secondary">
				Last Modified: {moment(lastModifiedDate).tz("America/New_York").format("M/D/YYYY hh:mmA z")}
			</Typography.Text>
		</>
	);
};
