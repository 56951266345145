import { Col, Row } from "antd";
import React, { FC, Fragment } from "react";
import { useLanguageHook } from "./hooks/useLanguageHooks";
import { CustomTable } from "../../series/StyledComponent";

import { ComponentTitle } from "../../custom-components";
import { TabComponentProps } from "../../utils/common.interface";

const Language: FC<TabComponentProps> = () => {
	const { columnLanguage, languagesList } = useLanguageHook();
	return (
		<Fragment>
			<Row justify="space-between">
				<Col>
					<ComponentTitle>Languages</ComponentTitle>
				</Col>
			</Row>
			<CustomTable
				columns={columnLanguage}
				dataSource={languagesList}
				pagination={{ defaultPageSize: 10, position: ["bottomRight"] }}
			/>
		</Fragment>
	);
};

export default Language;
