import { authAxios } from "./session_utils";

export const probeVideo = async (Bucket: string, Key: string, VideoURL: string) => {
	const data = { bucket: Bucket, key: Key, video_url: VideoURL };
	const config = {
		method: "post",
		url: `${process.env.REACT_APP_BACK_END_API}/video/ffprobe`,
		headers: {
			"Content-Type": "application/json",
		},
		data: JSON.stringify(data),
	};
	const response = await authAxios(config);
	return response.data.result;
};

export const probeThumbnail = async (ThumbnailURL: string) => {
	const data = { thumbnail_url: ThumbnailURL };
	const config = {
		method: "post",
		url: `${process.env.REACT_APP_BACK_END_API}/video/thumbnail-probe`,
		headers: {
			"Content-Type": "application/json",
		},
		data: JSON.stringify(data),
	};
	const response = await authAxios(config);
	return response.data;
};
