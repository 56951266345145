import { Button, Col, Row } from "antd";
import { CustomTable } from "./StyledComponent";
import { useSeriesHooks } from "./hooks/useSeriesHooks";
import React, { FC, Fragment } from "react";
import AddSeriesForm from "./modals/AddSeriesForm";

import { ComponentTitle } from "../custom-components";
import { TabComponentProps } from "../utils/common.interface";
import useScreenSizeHook from "../utils/useScreenSizeHook";

const Series: FC<TabComponentProps> = () => {
	const { height } = useScreenSizeHook();
	const {
		editId,
		columnSeries,
		seriesData,
		isLoading,
		setIsLoading,
		saveSeriesData,
		isAddSeriesFormOpen,
		setIsSeriesFormOpen,
		form,
		handleCancel,
		editHelper,
		pagination,
		handleTableChange,
		ratings,
		exportSeriesData,
		languagesList,
		seriesMultipleItems,
		setMultipleSeriesItems,
	} = useSeriesHooks();
	return (
		<Fragment>
			<Row justify="space-between" align="middle">
				<Col>
					<ComponentTitle>Series</ComponentTitle>
				</Col>
				<Col>
					<Button onClick={exportSeriesData}>Export</Button>
					&nbsp;
					<Button onClick={() => setIsSeriesFormOpen(true)}>+ Add Series</Button>
				</Col>
			</Row>
			<CustomTable
				onChange={handleTableChange}
				columns={columnSeries}
				dataSource={seriesData}
				scroll={{ y: height - 265 }}
				pagination={pagination}
				loading={isLoading}
				onRow={(record: any) => {
					return {
						onClick: () => {
							editHelper(record);
						},
					};
				}}
			/>
			<AddSeriesForm
				editId={editId}
				form={form}
				isAddSeriesFormOpen={isAddSeriesFormOpen}
				saveSeriesData={saveSeriesData}
				isLoading={isLoading}
				setIsLoading={setIsLoading}
				handleCancel={handleCancel}
				ratings={ratings}
				languagesList={languagesList}
				seriesMultipleItems={seriesMultipleItems}
				setMultipleSeriesItems={setMultipleSeriesItems}
			/>
		</Fragment>
	);
};

export default Series;
