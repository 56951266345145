import React from "react";
import useUserSettingsHook from "./hooks/useUserSettingsHook";
import { TaskCol, TaskRow, TaskText } from "../../zype/styled-components";
import { Col, Row, Switch } from "antd";
import { ComponentTitle } from "../../custom-components";
import { ExportCard } from "../../export/video";

const UserSettingsComponent = () => {
	const { user, updateSettings } = useUserSettingsHook();
	return (
		<>
			<Row justify="center">
				<Col>
					<ComponentTitle>User Settings</ComponentTitle>
				</Col>
			</Row>
			<TaskRow>
				<ExportCard>
					<TaskCol>
						<TaskText>Wide Scrollbars</TaskText>
						<Switch
							onChange={value => updateSettings("wide_scrollbars", value)}
							checked={user.wide_scrollbars}
							checkedChildren="Yes"
							unCheckedChildren="No"
						/>
					</TaskCol>
				</ExportCard>
			</TaskRow>
		</>
	);
};

export default UserSettingsComponent;
