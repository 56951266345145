import { Form, message } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import { useEffect, useState } from "react";
import { ConvertedDataType } from "../../utils/common.interface";
import { ColumnsType } from "antd/es/table";
import { getBulkSelectColumns } from "../../custom-components/BulkSelectColumn";
import {
	SETTINGS_DATA,
	exportCSVWithLambda,
	searchHandler,
	updateUserPageSizeSettings,
	handleSelectedRowStyling,
} from "../../utils";
import { initialPaginationObj } from "../../episode/hooks/useEpisodeHook";
import { useOutletContext } from "react-router-dom";
import { deleteCaption, editCaptionHelper, getCaptionsData, saveUpdateCaptionsData } from "./utils";
import { getCaptionDataColumns } from "../../cue-points/hooks/columns";
import useDistributionData from "../../distribution_partner/hooks/useDistributionData";
import { GetDataHandlerParams } from "../../videos/hooks/useVideosHooks";

export const captionFileTypeObject: any = {
	vtt: ".vtt",
	scc: ".scc",
	srt: ".srt",
};

export interface CaptionsFilterObj {
	series?: string[];
	distribution_platforms?: string[];
	not_distribution_platforms?: string[];
	searchQuery?: string;
	languages?: string[];
	offset_time?: number;
	types?: string[];
	video_lists?: string[];
}

export type SelectedCaptionsData = { key: string; id: string };

export interface GetCaptionDataHandler extends GetDataHandlerParams {
	filters?: CaptionsFilterObj;
}

const useCaptionsHook = () => {
	const captionFilterFields = [
		"series_filter",
		"languages_filter",
		"distribution_platforms_filter",
		"not_distribution_platforms_filter",
		"offset_time_filter",
		"caption_type_filter",
		"video_lists_filter",
	];
	const { distributionPlatformData, user, setUser, languagesList, seriesList, videoLists }: any =
		useOutletContext();
	const [form] = Form.useForm();
	const {
		distributionCheckedList,
		setDistributionCheckedList,
		distributionChange,
		onDistributionCheckAllChange,
		checkAll,
		indeterminate,
	} = useDistributionData();

	const [selectedCaptionsData, setSelectedCaptionsData] = useState<SelectedCaptionsData[]>([]);
	const [isAddToDistributorsModalOpen, setIsAddToDistributorsModalOpen] = useState<boolean>(false);
	const [captionsData, setCaptionsData] = useState<ConvertedDataType[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isOpenCaptionsForm, setIsOpenCaptionsForm] = useState<boolean>(false);
	const [captionEditId, setCaptionEditId] = useState<string | undefined>();
	const [episodeId, setEpisodeId] = useState<string>("");
	const [pagination, setPagination] = useState<PaginationProps>({
		...initialPaginationObj,
		pageSize: user.page_sizes?.captions ?? 10,
	});
	const [captionsFilterObj, setCaptionsFilterObj] = useState<CaptionsFilterObj>();
	const [isCaptionssFilterModalOpen, setIsCaptionsfilterModalOpen] = useState<boolean>(false);
	const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [isBulkDeleteModalOpen, setIsBulkDeleteModalOpen] = useState<boolean>(false);

	useEffect(() => {
		getCaptionsDataHandler({});
	}, [searchQuery]);

	useEffect(() => {
		const selectedCaptionsData = JSON.parse(sessionStorage.getItem("selectedCaptionsData") || "[]");
		setSelectedCaptionsData(selectedCaptionsData);
		const selectedCuePointsKeys = selectedCaptionsData.map((item: any) => item.key);
		handleSelectedRowStyling(selectedCuePointsKeys);
	}, [captionsData]);

	const getCaptionsDataHandler = async ({
		filters,
		currentPaginationInfo,
		searchString,
	}: GetCaptionDataHandler) => {
		setIsLoading(true);
		await getCaptionsData(
			setCaptionsData,
			setIsLoading,
			filters || searchString
				? { ...initialPaginationObj, pageSize: pagination.pageSize }
				: currentPaginationInfo ?? pagination,
			setPagination,
			{
				...(filters ?? captionsFilterObj),
				searchQuery: searchString ?? searchQuery,
			}
		);
	};

	const handleTableChange = (pagination: PaginationProps, _: any, __: any, { action }: any) => {
		if (action === "paginate") {
			if (pagination.pageSize !== user.page_sizes.captions) {
				const updatedSettingsData: SETTINGS_DATA = {
					user_id: user.user_id,
					page_sizes: { ...user.page_sizes, captions: pagination.pageSize },
				};
				updateUserPageSizeSettings(updatedSettingsData);
				setUser({ ...user, ...updateUserPageSizeSettings });
			}
			getCaptionsDataHandler({ currentPaginationInfo: pagination });
		}
	};

	const clearCaptionsSelection = () => {
		const selectedCaptionsKeys = selectedCaptionsData.map((item: any) => item.key);
		handleSelectedRowStyling(selectedCaptionsKeys, true);
		sessionStorage.removeItem("selectedCaptionsData");
		setSelectedCaptionsData([]);
	};

	const captionDataHandler = async () => {
		try {
			const values = await form.validateFields();
			setIsLoading(true);
			const response = await saveUpdateCaptionsData(
				values,
				episodeId,
				distributionCheckedList,
				captionEditId
			);
			if (!response.data.err) {
				setIsOpenCaptionsForm(false);
				message.success(response.data.message);
				await getCaptionsDataHandler({});
				setCaptionEditId(undefined);
				setDistributionCheckedList([]);
				form.resetFields();
			}
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
			message.error("Something went wrong");
			console.error("error in saving/ updating caption data", err);
		}
	};

	const cancelHandler = () => {
		form.resetFields();
		setDistributionCheckedList([]);
		setIsOpenCaptionsForm(false);
	};

	const editHelper = async (record: any) => {
		try {
			editCaptionHelper(
				record,
				form,
				setCaptionEditId,
				setIsOpenCaptionsForm,
				setDistributionCheckedList,
				setEpisodeId
			);
		} catch (err) {
			setIsLoading(false);
			message.error("Something went wrong");
			console.error("error in editHelper", err);
		}
	};

	const deleteCaptionHandler = async (id: string) => {
		try {
			setIsLoading(true);
			const response = await deleteCaption(id);
			if (response.status === 200) {
				getCaptionsDataHandler({});
				message.success(response.data.message);
			}
		} catch (err) {
			console.error("erro", err);
			setIsLoading(true);
		}
	};

	const exportCaptionsCSV = async () => {
		try {
			const selectedIds = selectedCaptionsData.map(ep => ep.id);
			const data = {
				...captionsFilterObj,
				captions: true,
				selectedIds,
			};
			await exportCSVWithLambda(data, "assets/captions", user.name, "Captions Export");
		} catch (err) {
			console.error(err);
		}
	};

	const getFilteredCaptions = async () => {
		try {
			const values = form.getFieldsValue(captionFilterFields);
			const {
				series_filter,
				distribution_platforms_filter,
				not_distribution_platforms_filter,
				languages_filter,
				caption_type_filter,
				offset_time_filter,
				video_lists_filter,
			} = values;
			setIsCaptionsfilterModalOpen(false);
			if (
				series_filter?.length ||
				caption_type_filter?.length ||
				distribution_platforms_filter?.length ||
				not_distribution_platforms_filter?.length ||
				typeof offset_time_filter === "number" ||
				languages_filter?.length ||
				video_lists_filter?.length
			) {
				const filters = {
					series: series_filter,
					types: caption_type_filter,
					distribution_platforms: distribution_platforms_filter,
					not_distribution_platforms: not_distribution_platforms_filter,
					languages: languages_filter,
					offset_time: offset_time_filter,
					video_lists: video_lists_filter,
				};
				setCaptionsFilterObj(filters);
				getCaptionsDataHandler({ filters });
			}
		} catch (error) {
			console.error("error in getting filtered captions", error);
		}
	};

	const searchData = async () => {
		try {
			setPagination({ ...initialPaginationObj, pageSize: pagination.pageSize });
			searchHandler(form, timeoutId, setTimeoutId, setSearchQuery);
		} catch (err) {
			console.error({ err });
		}
	};
	const clearFilter = () => {
		form.resetFields(captionFilterFields);
		setCaptionsFilterObj(undefined);
		getCaptionsDataHandler({ filters: {} });
	};
	const filterModalCancelHandler = () => {
		setIsCaptionsfilterModalOpen(false);
	};

	let columnCaptionsWithBulkSelect: ColumnsType<any> = getBulkSelectColumns(
		captionsData,
		selectedCaptionsData,
		setSelectedCaptionsData,
		handleSelectedRowStyling,
		"selectedCaptionsData"
	);
	columnCaptionsWithBulkSelect = columnCaptionsWithBulkSelect.concat(
		getCaptionDataColumns(deleteCaptionHandler, editHelper, isLoading)
	);

	return {
		captionsData,
		isLoading,
		isOpenCaptionsForm,
		setIsOpenCaptionsForm,
		captionEditId,
		setCaptionEditId,
		captionColumns: columnCaptionsWithBulkSelect, //getCaptionDataColumns(deleteCaptionHandler, editHelper, isLoading),
		form,
		captionDataHandler,
		cancelHandler,
		languages: languagesList,
		handleTableChange,
		editHelper,
		pagination,

		distributionPlatformData,
		distributionCheckedList,
		distributionChange,
		onDistributionCheckAllChange,
		checkAll,
		indeterminate,
		exportCaptionsCSV,
		searchData,
		isCaptionssFilterModalOpen,
		captionsFilterObj,
		setCaptionsFilterObj,
		setIsCaptionsfilterModalOpen,
		clearFilter,
		getFilteredCaptions,
		seriesList,
		filterModalCancelHandler,
		selectedCaptionsData,
		clearCaptionsSelection,
		isAddToDistributorsModalOpen,
		setIsAddToDistributorsModalOpen,
		setDistributionCheckedList,
		getCaptionsDataHandler,
		videoLists,
		isBulkDeleteModalOpen,
		setIsBulkDeleteModalOpen,
	};
};

export default useCaptionsHook;
