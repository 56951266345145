import React from "react";
import { Button, Col, Form, Row } from "antd";
import { TaskCol, TaskRow, TaskText } from "./styled-components";
import { useZypeHook } from "./hooks";
import { ComponentTitle } from "../custom-components";
import styled from "@emotion/styled";
import { ExportCard } from "../export/video";
const MRSSFeedCreateForm = styled(Form)`
	width: 270px;
`;

const MRSSFeedText = styled(TaskText)`
	width: 270px;
`;

const Zype = () => {
	const { contextHolder, createPlaylists, updateNonMRSSData } = useZypeHook();
	return (
		<>
			{contextHolder}
			<Row justify="center">
				<Col>
					<ComponentTitle>Zype</ComponentTitle>
				</Col>
			</Row>
			<TaskRow>
				<ExportCard>
					{/* <TaskCol>
					<TaskText>Delete all videos</TaskText>
					<Button onClick={deleteAllVideos} danger>
						Delete
					</Button>
				</TaskCol>
				<TaskCol>
					<TaskText>Delete all Sources</TaskText>
					<Button onClick={deleteAllSources} danger>
						Delete
					</Button>
				</TaskCol>
				<TaskCol>
					<TaskText>Delete all Categories</TaskText>
					<Button onClick={deleteAllCategories} danger>
						Delete
					</Button>
				</TaskCol> */}
					<TaskCol>
						<TaskText>Update non-MRSS data</TaskText>
						<Button onClick={updateNonMRSSData} type="primary">
							Update
						</Button>
					</TaskCol>
					<TaskCol>
						<TaskText>Create Playlists</TaskText>
						<Button onClick={createPlaylists} type="primary">
							Create
						</Button>
					</TaskCol>
					{/* <TaskCol>
					<MRSSFeedText>Create MRSS Video Source</MRSSFeedText>
				</TaskCol>
				<TaskCol>
					<MRSSFeedCreateForm form={form}>
						<Form.Item name="feedName" rules={[{ required: true, message: "Required Field" }]}>
							<Input placeholder="Source Name" />
						</Form.Item>
					</MRSSFeedCreateForm>
					<Button onClick={createMRSSVideoSource} type="primary">
						Create
					</Button>
				</TaskCol> */}
				</ExportCard>
			</TaskRow>
		</>
	);
};

export default Zype;
