import { useEffect, useState } from "react";
import { getVideoLists, saveUpdateVideoListHandler, videoListsColumns } from "../utils";
import { Form, PaginationProps, message } from "antd";
import { useOutletContext } from "react-router-dom";
import { initialPaginationObj } from "../../episode/hooks/useEpisodeHook";
import { authAxios } from "../../utils/session_utils";
import { HomeTabKeyEnum, SETTINGS_DATA, updateUserPageSizeSettings } from "../../utils";
import { AxiosRequestConfig } from "axios";
import { GetDataHandlerParams } from "../../videos/hooks/useVideosHooks";

export interface VideoLists {
	id: string;
	name: string;
}

export interface GetVideoListsHandler extends GetDataHandlerParams {
	filters?: { showAllLists: boolean };
}

const useVideoListsHook = () => {
	const { user, setUser, setVideoLists }: any = useOutletContext();
	const [form] = Form.useForm();
	const [isVideoListFormOpen, setIsVideoListFormOpen] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [listEditId, setListEditId] = useState<string>();
	const [videoListsData, setVideoListsData] = useState<any[]>([]);
	const [showAllLists, setShowAllLists] = useState<boolean>(user.show_public_lists);
	const [pagination, setPagination] = useState<PaginationProps>({
		...initialPaginationObj,
		pageSize: user.page_sizes?.videosList ?? 10,
	});

	useEffect(() => {
		if (location.pathname.includes(HomeTabKeyEnum.VIDEO_LISTS)) {
			document.title = "Video List";
			setIsLoading(true);
			getVideoListDataHandler({});
		}
	}, []);

	const handleTableChange = (pagination: PaginationProps, _: any, sorter: any, { action }: any) => {
		switch (action) {
			case "paginate": {
				if (pagination.pageSize !== user.page_sizes.videosList) {
					const updatedSettingsData: SETTINGS_DATA = {
						user_id: user.user_id,
						page_sizes: { ...user.page_sizes, videosList: pagination.pageSize },
					};
					updateUserPageSizeSettings(updatedSettingsData);
					setUser({ ...user, ...updatedSettingsData });
				}
				setIsLoading(true);
				getVideoListDataHandler({ currentPaginationInfo: pagination });
				break;
			}
		}
	};

	const saveUpdateVideoList = async () => {
		try {
			setIsLoading(true);
			const values: any = await form.validateFields();
			const data = {
				...values,
				user_id: user.user_id,
				id: listEditId,
			};
			await saveUpdateVideoListHandler(data, listEditId);
			setIsVideoListFormOpen(false);
			form.resetFields();
			setListEditId(undefined);
			getVideoListDataHandler({});
		} catch (error) {
			console.error(error);
			setIsLoading(false);
		}
	};

	const handleCancel = () => {
		setIsVideoListFormOpen(false);
		form.resetFields();
	};

	const editHelper = (record: any) => {
		form.setFieldsValue({ ...record });
		setListEditId(record.id);
		setIsVideoListFormOpen(true);
	};

	const deleteHandler = async (recordID: string) => {
		try {
			setIsLoading(true);
			const config = {
				method: "DELETE",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/video-list/${recordID}`,
			};
			const response = await authAxios(config);
			message.success(response.data.message);
			getVideoListDataHandler({});
		} catch (error) {
			message.error("Something went wrong in deleting the list");
			console.error(error);
			setIsLoading(false);
		}
	};

	const getVideoListDataHandler = async ({
		filters,
		currentPaginationInfo,
		searchString,
	}: GetVideoListsHandler) => {
		await getVideoLists(
			setVideoListsData,
			setIsLoading,
			filters || searchString
				? { ...initialPaginationObj, pageSize: pagination.pageSize }
				: currentPaginationInfo ?? pagination,
			setPagination,
			{
				...(filters ?? { showAllLists: true }),
				user_id: user.user_id,
			}
		);
	};

	const updateListVisibility = async () => {
		try {
			const updatedSettingsData = {
				user_id: user.user_id,
				show_public_lists: !showAllLists,
			};
			// updateUserPageSizeSettings(updatedSettingsData, setPageSizeUserSettings);

			const config: AxiosRequestConfig = {
				method: "PUT",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/settings/updateListVisibility`,
				headers: {
					"Content-Type": "application/json",
				},
				data: JSON.stringify(updatedSettingsData),
			};
			authAxios(config);
			setUser({ ...user, show_public_lists: !showAllLists });
			setShowAllLists(!showAllLists);
			setIsLoading(true);
			getVideoListDataHandler({ filters: { showAllLists: !showAllLists } });
		} catch (error) {
			console.error(error);
		}
	};

	return {
		videoListsCols: videoListsColumns(editHelper, deleteHandler),
		isLoading,
		videoListsData,
		isVideoListFormOpen,
		setIsVideoListFormOpen,
		form,
		saveUpdateVideoList,
		handleCancel,
		listEditId,
		showAllLists,
		setShowAllLists,
		pagination,
		handleTableChange,
		editHelper,
		updateListVisibility,
	};
};

export default useVideoListsHook;
