import { DatePicker, Form, Modal } from "antd";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";

type ActiveDatesModalProps = {
	isOpen: boolean;
	closeActiveDatesModal: () => void;
	saveActiveDateHandler: (startDate: string, endDate: string) => void;
	platformName: string;
};

const ActiveDates: React.FC<ActiveDatesModalProps> = ({
	isOpen,
	closeActiveDatesModal,
	platformName,
	saveActiveDateHandler,
}) => {
	const [startDate, setStartDate] = useState<string>("");
	const [endDate] = useState<string>("");
	const [startDayJS, setStartDayJS] = useState<Dayjs | Dayjs[]>();
	const [endDayJS, setEndDayJS] = useState<Dayjs | Dayjs[]>();

	const getSingleDayjs = (value: Dayjs | Dayjs[] | undefined): Dayjs | undefined => {
		if (dayjs.isDayjs(value)) return value;
		if (Array.isArray(value) && value.length > 0 && dayjs.isDayjs(value[0])) return value[0];
		return undefined;
	};

	useEffect(() => {
		setStartDayJS(undefined);
		setEndDayJS(undefined);
	}, [isOpen]);

	return (
		<Modal
			wrapProps={{ style: { zIndex: "1050" } }}
			open={isOpen}
			title={`Active Dates for ${platformName}`}
			onCancel={closeActiveDatesModal}
			closeIcon={null}
			okText="Save"
			cancelText="Cancel"
			onOk={() => {
				saveActiveDateHandler(startDate, endDate);
			}}
			maskClosable={false}
		>
			<Form.Item label="Activate">
				<DatePicker
					onChange={(date: Dayjs | null, dateString: string) => {
						setStartDate(dateString);
						setStartDayJS(date ? date : undefined);
					}}
					format={["MM/DD/YYYY"]}
					placeholder="MM/DD/YYYY"
					value={getSingleDayjs(startDayJS)}
				/>
			</Form.Item>
			<Form.Item label="Deactivate">
				<DatePicker
					onChange={(date: Dayjs | null, dateString: string) => {
						setStartDate(dateString);
						setStartDayJS(date ? date : undefined);
					}}
					format={["MM/DD/YYYY"]}
					placeholder="MM/DD/YYYY"
					value={getSingleDayjs(endDayJS)}
				/>
			</Form.Item>
		</Modal>
	);
};

export default ActiveDates;
