import { Form, message } from "antd";
import { NoticeType } from "antd/es/message/interface";
import { authAxios, getEmailFromSessionStorage } from "../../utils/session_utils";

export const useZypeHook = () => {
	const [messageApi, contextHolder] = message.useMessage();
	const email = getEmailFromSessionStorage();
	const key = "updatable";
	const [form] = Form.useForm();

	const openMessage = (type?: NoticeType, message?: string) => {
		messageApi.open({
			key,
			type: type ?? "loading",
			content: message ?? "Loading...",
			duration: message ? 2 : 0,
		});
	};
	const deleteAllVideos = () => {
		try {
			openMessage();
			const config = {
				method: "get",
				url: `${process.env.REACT_APP_BACK_END_API}/delete-all-videos`,
				headers: {},
			};

			authAxios(config).then(() => {
				openMessage("success", "Deleted all videos successfully");
			});
		} catch (e) {
			openMessage("error", "Please try again!!");
			console.error("Error deleting videos", e);
		}
	};

	const deleteAllSources = () => {
		try {
			openMessage();
			const config = {
				method: "get",
				url: `${process.env.REACT_APP_BACK_END_API}/delete-all-sources`,
				headers: {},
			};

			authAxios(config).then(() => {
				openMessage("success", "Deleted all sources successfully");
			});
		} catch (e) {
			openMessage("error", "Please try again!!");
			console.error("Error deleting sources", e);
		}
	};

	const deleteAllCategories = () => {
		try {
			openMessage();
			const config = {
				method: "get",
				url: `${process.env.REACT_APP_BACK_END_API}/delete-all-categories`,
				headers: {},
			};

			authAxios(config).then(() => {
				openMessage("success", "Deleted all categories successfully");
			});
		} catch (e) {
			openMessage("error", "Please try again!!");
			console.error("Error deleting categories", e);
		}
	};

	const createPlaylists = () => {
		try {
			openMessage();
			const config = {
				method: "get",
				url: `${process.env.REACT_APP_BACK_END_API}/create-playlists`,
				headers: {},
			};

			authAxios(config).then(() => {
				openMessage("success", "Created Playlists successfully");
			});
		} catch (e) {
			openMessage("error", "Please try again!!");
			console.error("Error creating playlists", e);
		}
	};
	const createMRSSVideoSource = async () => {
		try {
			const { feedName } = await form.validateFields();
			openMessage();
			const config = {
				method: "post",
				url: `${process.env.REACT_APP_BACK_END_API}/zype/source`,
				headers: {
					"Content-Type": "application/json",
				},
				data: JSON.stringify({ feedName }),
			};

			authAxios(config).then(() => {
				openMessage("success", "Created Playlists successfully");
				form.resetFields();
			});
		} catch (e) {
			console.error("Error creating playlists", e);
		}
	};

	const updateNonMRSSData = async () => {
		try {
			openMessage();
			const config = {
				method: "POST",
				url: `${process.env.REACT_APP_BACK_END_API}/zype/update-non-mrss-data`,
				headers: {},
				data: { email },
			};

			authAxios(config).then(() => {
				openMessage("success", "Zype data will be sync soon!");
			});
		} catch (e) {
			console.error("er", e);
		}
	};

	return {
		contextHolder,
		deleteAllVideos,
		deleteAllSources,
		deleteAllCategories,
		createPlaylists,
		createMRSSVideoSource,
		updateNonMRSSData,
		form,
	};
};
