import React from "react";
import { Col, Form, FormInstance, Input } from "antd";

import { StyledFilterModal } from "../../episode/modals/EpisodeFilterModal";
import { getDigitsOnly } from "../../utils";
import { BorderedRow, FilterInfoTitle } from "../../custom-components";
import {
	CaptionTypeFilterItem,
	DistributionPlatformsItem,
	EpisodeRelatedFiltersForAssets,
	LanguageSelectItem,
} from "../../custom-components/form/fliter";
import { VideoLists } from "../../video-lists/hooks/useVideoListsHook";

const { Item } = Form;

interface CaptionFilterModalProps {
	captionsFilterModalOpen: boolean;
	form: FormInstance;
	getFilteredCaptions: () => void;
	seriesData: any[];
	captionsFilterModalCancelHandler: () => void;
	languages: any[];
	distributionPlatformData: any[];
	videoLists: VideoLists[];
}

const CaptionsFilterModal: React.FC<CaptionFilterModalProps> = ({
	captionsFilterModalOpen,
	form,
	seriesData,
	getFilteredCaptions,
	languages,
	distributionPlatformData,
	captionsFilterModalCancelHandler,
	videoLists,
}) => {
	return (
		<StyledFilterModal
			open={captionsFilterModalOpen}
			okText="Filter"
			cancelText="Close"
			onOk={getFilteredCaptions}
			title="Filter Captions By"
			maskClosable={false}
			centered
			onCancel={captionsFilterModalCancelHandler}
		>
			<FilterInfoTitle>
				Show captions that belong to Episodes that match these filters:
			</FilterInfoTitle>
			<Form form={form} layout="vertical">
				<EpisodeRelatedFiltersForAssets seriesData={seriesData} videoLists={videoLists} />
				<FilterInfoTitle>Show captions that match these filters:</FilterInfoTitle>
				<BorderedRow>
					<Col span={24}>
						<DistributionPlatformsItem
							distributionPlatformData={distributionPlatformData}
							mode="multiple"
							name="distribution_platforms_filter"
						/>
					</Col>
					<Col span={24}>
						<DistributionPlatformsItem
							distributionPlatformData={distributionPlatformData}
							label="Not On Distribution Platforms"
							name="not_distribution_platforms_filter"
							placeholder="Isn't distributed on"
							mode="multiple"
						/>
					</Col>
					<Col span={24}>
						<LanguageSelectItem
							languages={languages}
							placeholder="Caption Languages"
							mode="multiple"
							name="languages_filter"
						/>
					</Col>
					<Col span={24}>
						<CaptionTypeFilterItem mode="multiple" />
					</Col>
					<Col span={24}>
						<Item name="offset_time_filter" label="Offset Time">
							<Input
								placeholder="Offset Time(in sec)"
								onChange={e =>
									form.setFieldValue("offset_time_filter", getDigitsOnly(e.target.value))
								}
							/>
						</Item>
					</Col>
				</BorderedRow>
			</Form>
		</StyledFilterModal>
	);
};

export default CaptionsFilterModal;
