import React, { useState } from "react";
import { Row, Col, Button, notification } from "antd";
import useTitleConflicts from "./hooks/useTitleConflicts";
import useScreenSizeHook from "../utils/useScreenSizeHook";
import TitleConflictsTable from "./components/ConflictsTable";
import MetadataPool from "./components/MetadataPool";
import { TitleConflictData } from './hooks/title-conflict';
import { MetadataRecord, useMetadata } from './hooks/useMetadata';
import { saveAllTitleConflicts } from './hooks/utils';

const TitleConflictsPage: React.FC = () => {
    const { height } = useScreenSizeHook();
    const titleConflictsHook = useTitleConflicts();
    const { allMetadataData, setAllMetadataData, loading: metadataLoading } = useMetadata();
    const [selectedConflict, setSelectedConflict] = useState<TitleConflictData | null>(null);
    const [linkedMetadata, setLinkedMetadata] = useState<{ [key: string]: { ref_id: string, operation: string, language: string, title: string } }>({});
	const [currentUserEmail, setCurrentUserEmail] = useState<string>(
		loadSessionStateByKey("mvmgsid", "")
	);
    const handleConflictSelect = (record: TitleConflictData) => {
        setSelectedConflict(record);
    };
    function loadSessionStateByKey(key: string, defaultValue: any) {
        const item = window.sessionStorage.getItem(key);
        return item != null ? item : defaultValue;
    }
    

    const handleMetadataLink = (metadata: MetadataRecord, operation: string) => {
        if (selectedConflict && selectedConflict.ref_id) {
            setLinkedMetadata(prev => ({
                ...prev,
                [selectedConflict.ref_id]: {
                    ref_id: metadata.ref_id,
                    operation,
                    language: metadata.language,
                    title: metadata.title
                }
            }));
            notification.success({
                message: 'Metadata Linked',
                description: `Metadata ${metadata.ref_id} linked to conflict ${selectedConflict.ref_id} with operation: ${operation}`
            });
        } else {
            notification.error({
                message: 'Link Failed',
                description: 'Could not link metadata. Selected conflict is missing a reference ID.'
            });
        }
    };

    const handleSaveAll = async () => {
        try {
            const updatedTitleConflictData = titleConflictsHook.titleConflictData
            .filter(conflict => linkedMetadata[conflict.ref_id] !== undefined)
            .map(conflict => ({
                ...conflict,
                linked_metadata: linkedMetadata[conflict.ref_id].ref_id,
                linked_metadata_title: linkedMetadata[conflict.ref_id].title,
                resolution_operation: linkedMetadata[conflict.ref_id].operation,
                language: linkedMetadata[conflict.ref_id].language,
            }));

            if (updatedTitleConflictData.length === 0) {
                notification.info({
                    message: 'No Changes',
                    description: 'No changes detected. Nothing to save.'
                });
                return;
            }

            console.log('updatedTitleConflictData: ', updatedTitleConflictData);
            await saveAllTitleConflicts(updatedTitleConflictData, currentUserEmail);
            notification.success({
                message: 'Save Successful',
                description: `${updatedTitleConflictData.length} title conflicts have been updated successfully.`
            });
        } catch (error) {
            console.error("Failed to save title conflicts", error);
            notification.error({
                message: 'Save Failed',
                description: 'An error occurred while saving title conflicts. Please try again.'
            });
        }
    };



    return (
       <Row gutter={16} align="top">
            <Col span={8}>
                <TitleConflictsTable
                    height={height * 0.85}  
                    isLoading={titleConflictsHook.isLoading}
                    titleConflictData={titleConflictsHook.titleConflictData}
                    selectedConflict={selectedConflict}
                    pagination={titleConflictsHook.pagination}
                    titleConflictColumns={titleConflictsHook.titleConflictColumns}
                    onConflictSelect={handleConflictSelect}
                    linkedMetadata={linkedMetadata}
                />
            </Col>
            <Col span={16}>
                <MetadataPool
                    selectedConflict={selectedConflict}
                    onMetadataLink={handleMetadataLink}
                    allMetadataData={allMetadataData}
                    setAllMetadataData={setAllMetadataData}
                    loading={metadataLoading}
                    onSaveAll={handleSaveAll}
                />
            </Col>
        </Row>
    );
};
export default TitleConflictsPage;