import { Checkbox, Modal } from "antd";
import { FormInstance } from "antd/lib";
import React, { Dispatch, SetStateAction } from "react";

interface Props {
	titleFilterModalOpen: boolean;
	form: FormInstance;
	getFileteredAlternateData: () => void;
	filterModalCancelHandler: () => void;
	searchWithBlankRefId: boolean;
	setSearchWithBlankRefId: Dispatch<SetStateAction<boolean>>;
}

const AlternateTitleFilterModal: React.FC<Props> = ({
	titleFilterModalOpen,
	getFileteredAlternateData,
	filterModalCancelHandler,
	searchWithBlankRefId,
	setSearchWithBlankRefId,
}) => {
	return (
		<Modal
			open={titleFilterModalOpen}
			okText="Filter"
			width={400}
			cancelText="Close"
			onOk={getFileteredAlternateData}
			title="Filter Alternate Titles By"
			maskClosable={false}
			centered
			onCancel={filterModalCancelHandler}
		>
			<Checkbox
				checked={searchWithBlankRefId}
				onChange={() => setSearchWithBlankRefId(!searchWithBlankRefId)}
			>
				Blank Ref ID
			</Checkbox>
		</Modal>
	);
};

export default AlternateTitleFilterModal;
