import React from 'react';
import { Button, Col, Row, Table, Upload, Modal, Input } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { InvoiceDataType } from '../../../utils/common.interface';
import AddInvoiceModal from '../modals/addInvoiceModal';
import FileUploadTable from './FileUploadComponent'; 

interface InvoiceUploadViewProps {
  dataSource: InvoiceDataType[];
  setDataSource: React.Dispatch<React.SetStateAction<InvoiceDataType[]>>; // Add this line
  columns: any[];
  rowSelection: any;
  selectedRowKeys: React.Key[];
  fileList: UploadFile[];
  renamedFiles: { [key: string]: string };
  isModalVisible: boolean;
  isAddInvoiceModalVisible: boolean;
  isUploading: boolean;
  uploadStatus: string;
  currentFile: UploadFile | null;
  renamedFile: string;
  dropdownValues: any;
  invoiceForm: any;
  handleUploadData: () => void;
  handleSelectAll: () => void;
  handleRemoveLine: () => void;
  handleAddLine: () => void;
  setIsAddInvoiceModalVisible: (visible: boolean) => void;
  setCurrentFile: (file: UploadFile | null) => void;
  setRenamedFile: (fileName: string) => void;
  setIsModalVisible: (visible: boolean) => void;
  handleFileChange: (info: any) => void;
  handleSaveFile: () => void;
  handleAddInvoice: () => void;
  handleInvoiceFormChange: (field: string, value: any) => void;
}

const InvoiceUploadView: React.FC<InvoiceUploadViewProps> = ({
  dataSource,
  columns,
  rowSelection,
  fileList,
  renamedFiles,
  isModalVisible,
  isAddInvoiceModalVisible,
  isUploading,
  renamedFile,
  dropdownValues,
  invoiceForm,
  handleUploadData,
  handleSelectAll,
  handleRemoveLine,
  handleAddLine,
  setIsAddInvoiceModalVisible,
  setCurrentFile,
  setRenamedFile,
  setIsModalVisible,
  handleFileChange,
  handleSaveFile,
  handleAddInvoice,
  handleInvoiceFormChange,
}) => {
  return (
    <>
      <Row justify="end">
        <Col>
          <Button 
            onClick={handleUploadData}
            type="primary"
            disabled={isUploading}
          >
            Upload Data
          </Button>
        </Col>
      </Row>
      <Row justify="space-between" align="top" gutter={16}>
        <Col span={18}>
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            rowSelection={rowSelection}
            scroll={{ x: 'max-content' }}
          />
          <Row justify="space-between" className="button-custom" gutter={16}>
            <Col>
              <Button onClick={handleSelectAll}>Select All</Button>
            </Col>
            <Col>
              <Button onClick={handleRemoveLine} style={{ marginRight: '10px' }}>
                - Remove Line
              </Button>
              <Button onClick={handleAddLine} style={{ marginRight: '10px' }}>+ Add Line</Button>
              <Button onClick={() => setIsAddInvoiceModalVisible(true)}>+ Add Invoice</Button>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <FileUploadTable
            fileList={fileList}
            renamedFiles={renamedFiles}
            setCurrentFile={setCurrentFile}
            setRenamedFile={setRenamedFile}
            setIsModalVisible={setIsModalVisible}
            handleFileChange={handleFileChange}
          />
        </Col>
      </Row>
      <Modal
        title="Rename File"
        open={isModalVisible}
        onOk={handleSaveFile}
        onCancel={() => setIsModalVisible(false)}
      >
        <Input
          value={renamedFile}
          onChange={e => setRenamedFile(e.target.value)}
          placeholder="Enter new file name"
        />
      </Modal>
      <AddInvoiceModal
        open={isAddInvoiceModalVisible}
        onCancel={() => setIsAddInvoiceModalVisible(false)}
        onSave={handleAddInvoice}
        dropdownValues={dropdownValues}
        invoiceForm={invoiceForm}
        handleInvoiceFormChange={handleInvoiceFormChange}
      />
    </>
  );
};

export default InvoiceUploadView;