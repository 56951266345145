import { ColumnsType } from "antd/es/table";
import { useOutletContext } from "react-router-dom";


export const useLanguageHook = () => {
	const { languagesList }: any = useOutletContext();

	const columnLanguage: ColumnsType<object> = [
		{
			title: "Language Code",
			dataIndex: "CODE",
			key: "CODE",
		},
		{
			title: "Language Name",
			dataIndex: "NAME",
			key: "NAME",
		},
	];
	return {
		languagesList,
		columnLanguage,
	};
};
