import styled from "@emotion/styled";
import {
	Button,
	Checkbox,
	Col,
	Form,
	FormInstance,
	Input,
	Modal,
	Row,
	Spin,
	Tooltip,
	Typography,
} from "antd";
import React, { Dispatch, FC, SetStateAction } from "react";
import { getDigitsOnly, getDurationOnly } from "../../utils";
import { DistributionDataPropsTypes } from "../../episode";
import { QuestionCircleOutlined } from "@ant-design/icons";

interface Props extends DistributionDataPropsTypes {
	cuePointEditId: string | undefined | null;
	isOpen: boolean;
	isDuplicateCuePointFormOpen: boolean;
	saveEditCuePointsData: () => void;
	handleCancel: () => void;
	isLoading: boolean;
	cuepointFields: string[];
	form: FormInstance;
	distributionPlatformData: any[];
	setCuepointFields: Dispatch<SetStateAction<string[]>>;
	create5SecOffset: boolean;
	setCreate5SecOffset: Dispatch<SetStateAction<boolean>>;
}

const CuepointsModal = styled(Modal)`
	& .ant-modal-content {
		/* max-height: 100vh; */
		/* overflow-y: auto; */
	}
	& .ant-modal-body {
		max-height: calc(100vh - 120px);
		overflow-y: auto;
		overflow-x: hidden;
	}
	& #cuepoint_distribution {
		/* max-height: calc(100vh - 444px); */
		/* overflow-y: auto; */
		/* overflow-x: hidden; */
	}
`;

const CuePointsFormModal: FC<Props> = ({
	cuePointEditId,
	isOpen,
	isDuplicateCuePointFormOpen,
	saveEditCuePointsData,
	handleCancel,
	isLoading,
	form,
	distributionPlatformData,
	distributionCheckedList,
	distributionChange,
	onDistributionCheckAllChange,
	checkAll,
	indeterminate,
	cuepointFields,
	setCuepointFields,
	create5SecOffset,
	setCreate5SecOffset,
}) => {
	return (
		<CuepointsModal
			centered
			width={900}
			open={isOpen || isDuplicateCuePointFormOpen}
			maskClosable={false}
			title={
				isDuplicateCuePointFormOpen
					? "Clone Cue Points"
					: cuePointEditId
					? "Edit Cue Points"
					: "Add Cue Points"
			}
			okText={isDuplicateCuePointFormOpen ? "Clone" : cuePointEditId ? "Update" : "Add"}
			onOk={saveEditCuePointsData}
			onCancel={handleCancel}
			okButtonProps={{ disabled: isLoading }}
			cancelButtonProps={{ disabled: isLoading }}
		>
			<Spin spinning={isLoading}>
				<Form form={form} requiredMark={false} layout="vertical">
					<Form.Item label="Ref Id" name="cuepoint_ref_id">
						<Input style={{ width: "220px" }} disabled />
					</Form.Item>
					<Row style={{ marginBottom: "8px" }} align="middle">
						{isDuplicateCuePointFormOpen ? "Original Cue Points" : "Cue Points"}
						{isDuplicateCuePointFormOpen && (
							<Tooltip title={"Original Cue Points can't be updated while creating clone"}>
								&nbsp;&nbsp;
								<QuestionCircleOutlined />
							</Tooltip>
						)}
					</Row>
					<Row gutter={[16, 16]}>
						{cuepointFields.map(key => {
							return (
								<Col span={4} key={"cuepoint" + key}>
									<Form.Item
										name={"cue_points" + key}
										// rules={[{ required: true, message: "Required Field" }]}
									>
										<Input
											readOnly={isDuplicateCuePointFormOpen}
											placeholder="HH:MM:SS"
											onChange={e => {
												const duration = getDurationOnly(e.target.value);
												form.setFieldValue("cue_points" + key, duration);
											}}
										/>
									</Form.Item>
								</Col>
							);
						})}
						{!isDuplicateCuePointFormOpen && (
							<Col span={4}>
								<Button
									type="primary"
									onClick={() =>
										setCuepointFields([...cuepointFields, `${cuepointFields.length + 1}`])
									}
								>
									+ Cue Points
								</Button>
							</Col>
						)}
					</Row>
					<Row gutter={[16, 16]} align="middle">
						<Col span={12}>
							<Form.Item
								name={"cuepoint_offset_time"}
								label={
									isDuplicateCuePointFormOpen ? (
										<>
											Original Offset Time
											<Tooltip title={"Original Offset Time can't be updated while creating clone"}>
												&nbsp;&nbsp;
												<QuestionCircleOutlined />
											</Tooltip>
										</>
									) : (
										"Offset Time"
									)
								}
							>
								<Input
									readOnly={isDuplicateCuePointFormOpen}
									placeholder={"Offset Time(in sec)"}
									onChange={e =>
										form.setFieldValue("cuepoint_offset_time", getDigitsOnly(e.target.value, true))
									}
								/>
							</Form.Item>
						</Col>
						{isDuplicateCuePointFormOpen && (
							<Col span={12}>
								<Form.Item name={"offset_for_clone"} label={"Offset for Clone"}>
									<Input
										placeholder={"+/- offset time in sec, 0 = exact copy"}
										onChange={e =>
											form.setFieldValue("offset_for_clone", getDigitsOnly(e.target.value, true))
										}
									/>
								</Form.Item>
							</Col>
						)}
						{!cuePointEditId && !isDuplicateCuePointFormOpen && (
							<Col span={12}>
								<Checkbox
									onChange={() => setCreate5SecOffset(!create5SecOffset)}
									checked={create5SecOffset}
								>
									Also create a 5 second offset version
								</Checkbox>
							</Col>
						)}
					</Row>
					<Typography.Title level={3}>Distribution Platforms</Typography.Title>
					<Checkbox
						indeterminate={indeterminate}
						onChange={onDistributionCheckAllChange}
						checked={checkAll}
						style={{ marginBottom: "4px" }}
					>
						{checkAll ? "De-Select All" : "Select All"}
					</Checkbox>
					<Checkbox.Group onChange={distributionChange} value={distributionCheckedList}>
						<Row id="cuepoint_distribution" gutter={[16, 4]} style={{ width: "70% " }}>
							{distributionPlatformData.map((platform: any) => {
								return (
									<Col span={8} key={platform.key}>
										<Checkbox value={platform.id} key={platform.key}>
											{platform.name}
										</Checkbox>
									</Col>
								);
							})}
						</Row>
					</Checkbox.Group>
				</Form>
			</Spin>
		</CuepointsModal>
	);
};

export default CuePointsFormModal;
