import { PaginationProps, message, notification } from "antd";
import { Dispatch, SetStateAction } from "react";
import { SortObj } from "../../videos/videos";
import { authAxios } from "../../utils/session_utils";
import { makeKeyInLowerCaseHelper } from "../../utils";
import { AlternateTitleData } from "./alternate.title";

const alternateTitleRoute = "alternate-title";

export const getAlternateTitlesData = async (
	setAlternateTitlesData: Dispatch<SetStateAction<AlternateTitleData[]>>,
	setLoading: Dispatch<SetStateAction<boolean>>,
	pagination: PaginationProps,
	titleSortObj: SortObj,
	searchQuery: string,
	titleFilterObj?: any
) => {
	try {
		const config: any = {
			method: "GET",
			url: `${
				process.env.REACT_APP_BACK_END_API
			}/snowflake/${alternateTitleRoute}/?pagination=${JSON.stringify(
				pagination
			)}&sortObj=${JSON.stringify(titleSortObj ?? {})}&filter=${JSON.stringify(
				titleFilterObj ?? {}
			)}&searchQuery=${searchQuery}`,
			headers: {
				"Content-Type": "application/json",
			},
		};
		const response = await authAxios(config);
		const convertedObject = await makeKeyInLowerCaseHelper(response.data.rows);
		setAlternateTitlesData(convertedObject as unknown as AlternateTitleData[]);
		setLoading(false);
	} catch (error) {
		console.error(error);
		throw new Error();
	}
};

export const saveUpdateAlternateTitle = async (values: AlternateTitleData, editId?: string) => {
	try {
		const bodyData = {
			...values,
			id: editId,
		};
		const config = {
			method: editId ? "put" : "post",
			url: `${process.env.REACT_APP_BACK_END_API}/snowflake/${alternateTitleRoute}`,
			headers: { "Content-Type": "application/json" },
			data: JSON.stringify(bodyData),
		};
		const response = await authAxios(config);
		if (response.status === 200) {
			notification.info({
				duration: 5,
				message: "Invalid Ref ID",
				description: "This ref ID does not exists in our database.",
			});
			throw new Error(response.data.message);
		}
		return response;
	} catch (error: any) {
		if (error?.response.status === 409) {
			notification.error({
				duration: 5,
        message: "Record already exists",
        description: "This alternate title already exists.",
			})
		}
		throw new Error("error in adding or updating alternate title", { cause: error });
	}
};

// export const editAlternateTitleFormHandler = () => {};

export const deleteAlternateTitle = async (titleId: string) => {
	try {
		const config = {
			method: "delete",
			url: `${process.env.REACT_APP_BACK_END_API}/snowflake/${alternateTitleRoute}/${titleId}`,
			headers: {},
		};

		const response = await authAxios(config);
		if (!response.data.err) {
			message.success(response.data.message);
		}
	} catch (error) {
		console.error("error in deleting alternate title", error);
		throw new Error("error in deleting alternate title", { cause: error });
	}
};
