import { Button, Col, Dropdown, MenuProps, Row, Space, Spin } from "antd";
import React, { FC } from "react";
import { useVideosHooks } from "./hooks/useVideosHooks";
import { CustomTable } from "../series/StyledComponent";
import AddVideosMetaDataForm from "./modals/AddVideosMetaDataForm";
import MetadataInfoModal from "./modals/MetadataInfoModal";

import {
	ComponentTitle,
	PageTitleLastCol,
	SearchForm,
	SelectedCountTag,
} from "../custom-components";
import { ClearFilterButton } from "../metadata";
import { CloseCircleOutlined, DownOutlined } from "@ant-design/icons";
import VideoFilterModal from "./modals/VideoFilterModal";
import { TabComponentProps } from "../utils/common.interface";
import useScreenSizeHook from "../utils/useScreenSizeHook";
import VideoPlayer from "../custom-components/VideoPlayer";
import { HomeTabKeyEnum } from "../utils";
import AddVideosToListModal from "./modals/bulk-videos/AddVideosToListModal";
import AddItemsToDistributionPlatformModal from "../custom-components/AddItemsToDistributionPlatformModal";
import BulkVideosTaggingModal from "./modals/bulk-videos/BulkVideosTaggingModal";
import CommonExportModal from "./modals/export";
import BulkDeleteModal from "./modals/bulk-videos/BulkDelete";

const VideoComponent: FC<TabComponentProps> = () => {
	const { height } = useScreenSizeHook();
	const {
		videosData,
		setIsLoading,
		isLoading,
		columnVideos,
		isOpenVideosForm,
		form,
		saveVideosData,
		formArray,
		setFormArray,
		isMetadataInfoModalOpen,
		metadataInfo,
		handleCloseMetadataModal,
		videoTitle,
		videoEditId,
		addNewInputHelper,
		handleCancel,
		durationValue,
		setDurationValue,
		relatedMetadata,
		metadataSelector,
		isAddNewMetadata,
		ref_id,
		languages,
		changeHandler,
		tagsData,
		handleAddCustomTag,
		handleTableChange,
		clearFilter,
		filterModalCancelHandler,
		isFilterVideoModalOpen,
		getFileteredVideoData,
		setIsFilterVideoModalOpen,
		seriesData,
		exportCSV,
		distributionPlatform,
		isExportModalOpen,
		setIsExportModalOpen,
		metadataEditId,
		enableEditForm,
		searchData,
		pagination,
		videoPlayerDetails,
		closeVideo,
		distributionCheckedList,
		distributionChange,
		onDistributionCheckAllChange,
		checkAll,
		indeterminate,
		editVideoData,
		videoLists,
		videoFilterObj,
		selectedVideoData,
		isAddToListModalOpen,
		setIsAddToListModalOpen,
		isAddToDistributorsModalOpen,
		setIsAddToDistributorsModalOpen,
		getVideosDataHandler,
		setDistributionCheckedList,
		isBulkTaggingModalOpen,
		setIsBulkTaggingModalOpen,
		exportFormat,
		setExportFormat,
		hiddenEpisodesHandler,
		isFilteredData,
		isBulkDeleteModalOpen,
		setIsBulkDeleteModalOpen,
		clearVideosSelection,
		activeDatesList,
		setActiveDatesList,
		setIsAddNewMetadata,
		setMetadataEditId,
	} = useVideosHooks();

	const bulkActionItems: MenuProps["items"] = [
		{
			label: <span>Video Lists</span>,
			key: "video-list",
			onClick: () => setIsAddToListModalOpen(true),
		},
		{
			label: <span>Distribution</span>,
			key: "distribution-platform",
			onClick: () => setIsAddToDistributorsModalOpen(true),
		},
		{
			label: <span>Tags</span>,
			key: "tags",
			onClick: () => setIsBulkTaggingModalOpen(true),
		},
		{
			label: <span>Delete</span>,
			key: "delete",
			onClick: () => setIsBulkDeleteModalOpen(true),
		},
	];

	return (
		<Spin spinning={isLoading}>
			<Row justify="space-between" align="middle">
				<Col>
					<ComponentTitle>Videos / Meta Data</ComponentTitle>
				</Col>
				<Col>
					{selectedVideoData.length > 0 && (
						<Space>
							<SelectedCountTag color="green">
								<span>{selectedVideoData.length} Selected</span>
								<span title="Clear Selection" onClick={clearVideosSelection} id="close-icon">
									X
								</span>
							</SelectedCountTag>
							<Dropdown menu={{ items: bulkActionItems }} trigger={["click"]}>
								<Button>
									Actions <DownOutlined />
								</Button>
							</Dropdown>
						</Space>
					)}
				</Col>
				<PageTitleLastCol>
					<Button type="primary" onClick={hiddenEpisodesHandler}>
						{videoFilterObj.showHiddenEpisodes ? "Hide" : "Show"} Inactive
					</Button>
					&nbsp;
					<Button onClick={() => setIsExportModalOpen(true)}>Export</Button>
					&nbsp;
					<Button onClick={() => setIsFilterVideoModalOpen(true)}>Filter</Button>
					{isFilteredData && (
						<ClearFilterButton
							onClick={clearFilter}
							title="Remove Filter"
							icon={<CloseCircleOutlined />}
						/>
					)}
					<SearchForm
						form={form}
						searchData={searchData}
						placeholder="Search by Ref ID or Metadata Title"
					/>
				</PageTitleLastCol>
			</Row>
			<CustomTable
				onChange={handleTableChange}
				columns={columnVideos}
				dataSource={videosData}
				scroll={{ y: height - 265 }}
				pagination={pagination}
				onRow={(record: any) => {
					return {
						onClick: e => {
							if (e.metaKey || e.ctrlKey) {
								const newTab = window.open(HomeTabKeyEnum.VIDEOS + "/" + record.id, "_blank");
								newTab?.focus();
							} else {
								enableEditForm(record);
							}
						},
					};
				}}
			/>
			<AddVideosMetaDataForm
				metadataSelector={metadataSelector}
				isAddNewMetadata={isAddNewMetadata}
				setIsAddNewMetadata={setIsAddNewMetadata}
				setMetadataEditId={setMetadataEditId}
				durationValue={durationValue}
				setDurationValue={setDurationValue}
				addNewInputHelper={addNewInputHelper}
				videoEditId={videoEditId}
				setFormArray={setFormArray}
				formArray={formArray}
				form={form}
				isOpen={isOpenVideosForm}
				saveVideosData={saveVideosData}
				isLoading={isLoading}
				setIsLoading={setIsLoading}
				handleCancel={handleCancel}
				relatedMetadata={relatedMetadata}
				ref_id={ref_id}
				languages={languages}
				changeHandler={changeHandler}
				tagsData={tagsData}
				handleAddCustomTag={handleAddCustomTag}
				distributionPlatform={distributionPlatform}
				metadataEditId={metadataEditId}
				distributionCheckedList={distributionCheckedList}
				distributionChange={distributionChange}
				onDistributionCheckAllChange={onDistributionCheckAllChange}
				checkAll={checkAll}
				indeterminate={indeterminate}
				videosData={editVideoData}
				videoLists={videoLists}
				activeDatesList={activeDatesList}
				setActiveDatesList={setActiveDatesList}
			/>
			<MetadataInfoModal
				durationValue={durationValue}
				videoTitle={videoTitle}
				key="metadata-modal-info"
				handleCloseMetadataModal={handleCloseMetadataModal}
				isMetadataInfoModalOpen={isMetadataInfoModalOpen}
				metadataInfo={metadataInfo}
			/>
			<VideoFilterModal
				form={form}
				videoLists={videoLists}
				getFileteredVideoData={getFileteredVideoData}
				languageData={languages}
				seriesData={seriesData}
				tagsData={tagsData}
				videoFilterModalOpen={isFilterVideoModalOpen}
				filterModalCancelHandler={filterModalCancelHandler}
				distributionPlatformData={distributionPlatform}
			/>
			{/* <ExportModal
				exportCSV={exportCSV}
				isExportModalOpen={isExportModalOpen}
				setIsExportModalOpen={setIsExportModalOpen}
				form={form}
			/> */}
			<AddVideosToListModal
				isAddToListModalOpen={isAddToListModalOpen}
				setIsAddToListModalOpen={setIsAddToListModalOpen}
				videoLists={videoLists}
				selectedVideoData={selectedVideoData}
				getVideosDataHandler={getVideosDataHandler}
			/>
			<AddItemsToDistributionPlatformModal
				setDistributionCheckedList={setDistributionCheckedList}
				isAddToDistributorsModalOpen={isAddToDistributorsModalOpen}
				setIsAddToDistributorsModalOpen={setIsAddToDistributorsModalOpen}
				distributionPlatformData={distributionPlatform}
				distributionCheckedList={distributionCheckedList}
				distributionChange={distributionChange}
				onDistributionCheckAllChange={onDistributionCheckAllChange}
				checkAll={checkAll}
				indeterminate={indeterminate}
				selectedItemData={selectedVideoData}
				selectedItemType="Video"
				getItemsDataHandler={getVideosDataHandler}
			/>
			<BulkVideosTaggingModal
				isBulkTaggingModalOpen={isBulkTaggingModalOpen}
				setIsBulkTaggingModalOpen={setIsBulkTaggingModalOpen}
				tagsList={tagsData}
				selectedVideoData={selectedVideoData}
				getVideosDataHandler={getVideosDataHandler}
			/>
			<CommonExportModal
				exportFormat={exportFormat}
				setExportFormat={setExportFormat}
				distributionPlatformData={distributionPlatform}
				exportCSV={exportCSV}
				isExportModalOpen={isExportModalOpen}
				setIsExportModalOpen={setIsExportModalOpen}
				form={form}
			/>
			<BulkDeleteModal
				isBulkDeleteModalOpen={isBulkDeleteModalOpen}
				setIsBulkDeleteModalOpen={setIsBulkDeleteModalOpen}
				selectedData={selectedVideoData}
				getDataHandler={getVideosDataHandler}
				clearSelection={clearVideosSelection}
				bulkDeleteType="video"
			/>
			{videoPlayerDetails && <VideoPlayer {...videoPlayerDetails} closeVideo={closeVideo} />}
		</Spin>
	);
};
export default VideoComponent;
