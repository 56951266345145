import { Form, PaginationProps, message } from "antd";
import React, { Fragment, useState } from "react";
import { SETTINGS_DATA, getWholeData, updateUserPageSizeSettings } from "../../utils";
import { ColumnsType } from "antd/es/table";
import { CustomBtn } from "../../series/StyledComponent";
import { EditOutlined } from "@ant-design/icons";
import { useOutletContext } from "react-router-dom";
import { initialPaginationObj } from "../../episode/hooks/useEpisodeHook";
import { authAxios } from "../../utils/session_utils";
import { DeletePopConfirm } from "../../custom-components";

const useDistributionPlatformHook = () => {
	const [form] = Form.useForm();
	const {
		loading,
		setLoading,
		distributionPlatformData,
		setDistributionPlatformData,
		distributionPlatformSortObj,
		user,
		setUser,
	}: any = useOutletContext();
	const [isOpenDistributionPlatformForm, setIsOpenDistributionPlatformForm] =
		useState<boolean>(false);
	const [distributionPlatformEditId, setDistributionPlatformEditId] = useState<
		string | undefined
	>();
	const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
	const [pagination, setPagination] = useState<PaginationProps>({
		...initialPaginationObj,
		total: distributionPlatformData.length,
		pageSize: user.page_sizes?.distribution_platform ?? 10,
	});
	// const [videoFilterObj, setVideoFilterObj] = useState<VideoFilterObj | undefined>(undefined)

	const handleCancel = () => {
		form.resetFields();
		setIsOpenDistributionPlatformForm(false);
		setDistributionPlatformEditId(undefined);
		setIsEditModalOpen(false);
	};

	const handleTableChange = (
		tempPagination: PaginationProps,
		filters: any,
		sorter: any,
		{ action }: any
	) => {
		// update data according to actions
		if (action === "paginate") {
			const updatedSettingsData: SETTINGS_DATA = {
				user_id: user.user_id,
				page_sizes: { ...user.page_sizes, distribution_platform: tempPagination.pageSize },
			};
			updateUserPageSizeSettings(updatedSettingsData);
			setUser({ ...user, ...updatedSettingsData });
			setPagination(tempPagination);
		}
	};

	const saveDistributionPlatformData = async () => {
		try {
			const name = await form.getFieldValue("name");
			if (name?.length) {
				setLoading(true);
				const data: any = {
					distributionPlatformData: {
						name,
					},
				};
				if (distributionPlatformEditId) {
					data.distributionPlatformData.id = distributionPlatformEditId;
				}
				const config = {
					method: distributionPlatformEditId ? "put" : "post",
					url: `${process.env.REACT_APP_BACK_END_API}/snowflake/distribution-platform`,
					headers: {
						"Content-Type": "application/json",
					},
					data: JSON.stringify(data),
				};
				handleCancel();
				const response = await authAxios(config);
				if (!response.data.err) {
					message.success(response.data.message);
					getWholeData(
						"distribution-platform",
						setDistributionPlatformData,
						distributionPlatformSortObj,
						setLoading
					);
				}
			} else {
				message.error("Please enter partner name");
			}
		} catch (err) {
			setLoading(false);
			console.error("erro while saving tags data", err);
		}
	};

	const deleteData = async (record: any) => {
		try {
			const config = {
				method: "delete",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/distribution-platform/${record.id}`,
			};
			setLoading(true);
			const response = await authAxios(config);
			if (response.status === 200) {
				getWholeData(
					"distribution-platform",
					setDistributionPlatformData,
					distributionPlatformSortObj,
					setLoading
				);
				message.success(response.data.message);
			}
		} catch (err) {
			console.error("erro", err);
			setLoading(false);
		}
	};

	const editHelper = async (record: any) => {
		setIsEditModalOpen(true);
		form.setFieldValue("name", record.name);
		setDistributionPlatformEditId(record.id);
	};

	const distibuterPlatformColumns: ColumnsType<any> = [
		{
			title: "Distribution Platform",
			dataIndex: "name",
			key: "name",
			align: "center",
			defaultSortOrder: "ascend",
			sorter: (a: any, b: any) => a.name.localeCompare(b.name),
		},
		{
			title: "Action",
			dataIndex: "action",
			key: "action",
			align: "center",
			render: (value, record: any) => {
				return (
					<Fragment>
						<CustomBtn
							icon={<EditOutlined />}
							type={"link"}
							onClick={e => {
								e.stopPropagation();
								editHelper(record);
							}}
						/>
						<DeletePopConfirm
							title="Delete Distribution platform?"
							deleteHandler={() => deleteData(record)}
						>
							Are you sure you want to delete this distribution platform?
						</DeletePopConfirm>
					</Fragment>
				);
			},
		},
	];

	return {
		distibuterPlatformColumns,
		distributionPlatformData,
		isLoading: loading,
		isOpenDistributionPlatformForm,
		setIsOpenDistributionPlatformForm,
		handleCancel,
		handleTableChange,
		saveDistributionPlatformData,
		form,
		editHelper,
		pagination,
		isEditModalOpen,
	};
};

export default useDistributionPlatformHook;
