import React, { FC, SetStateAction, useState } from "react";
import { Button, Form, Input, FormInstance, Spin, Col, Select, Row, Image } from "antd";
import styled from "@emotion/styled";
import { v4 as uuidV4 } from "uuid";

import TitleWithLanguageFormElement from "../TitleWithLanguageFormElement";
import { CustomModal, ModalTitleWithLastModifiedDate } from "../../utils/StyledComponents";
import { LanguageData, getFileNameWithURL } from "../../videos/modals/AddVideosMetaDataForm";
import SeriesFormElement from "./SeriesFormElement";
import { SeriesFormRow, StyledDatePicker } from "../StyledComponent";
import { Ratings } from "../../../App";
import { CloseIcon } from "../../custom-components";
import { MultipleSeriesItems } from "../hooks/useSeriesHooks";
import ThumbnailFormModal from "../../thumbnails/modals/ThumbnailFormModal";
import { useThumbnailsHook } from "../../thumbnails/hooks/useThumbnailsHook";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import useDistributionData from "../../distribution_partner/hooks/useDistributionData";
import { editThumbnailHelper } from "../../thumbnails/hooks/utils";

export const ThumbnailsDataRow = styled(Row)`
	margin-bottom: 16px;
	padding-right: 8px;
	margin-top: -8px;

	& #delete {
		color: red;
	}
`;

export const ThumbnailFileNameSpan = styled.span`
	:hover {
		cursor: pointer;
		text-decoration: underline;
	}
`;

export const SubmitButton = styled(Button)`
	color: #000000 !important;
	font-weight: 600;
	background: #feea3c !important;
	border: 5px solid #feea3c !important;
	display: flex;
	justify-content: center;
	align-items: center;
`;

type Props = {
	isAddSeriesFormOpen: boolean;
	saveSeriesData(): void;
	form: FormInstance;
	isLoading: boolean;
	setIsLoading(value: boolean): void;
	handleCancel: () => void;
	editId: string | null;
	ratings: Ratings | undefined;
	languagesList: LanguageData[];
	seriesMultipleItems: MultipleSeriesItems;
	setMultipleSeriesItems: React.Dispatch<SetStateAction<MultipleSeriesItems>>;
};

const AddSeriesForm: FC<Props> = ({
	editId,
	isAddSeriesFormOpen,
	saveSeriesData,
	form,
	isLoading,
	handleCancel,
	ratings,
	languagesList,
	seriesMultipleItems,
	setMultipleSeriesItems,
}) => {
	const {
		isLoading: thumbnailIsLoading,
		isAddThumbnailFormOpen,
		setIsThumbnailFormOpen,
		form: thumbnailForm,
		distributionPlatformData,
		tagsList,
		setThumbnailEditId,
		thumbnailEditId,
		handleAddCustomTag,
	} = useThumbnailsHook(true);
	const {
		distributionCheckedList,
		setDistributionCheckedList,
		distributionChange,
		onDistributionCheckAllChange,
		checkAll,
		indeterminate,
	} = useDistributionData();

	const [previewImageData, setPreviewImageData] = useState({
		src: "",
		preview: {
			visible: false,
			src: "",
			onVisibleChange: (value: boolean) => {
				setPreviewImageData({
					...previewImageData,
					preview: { ...previewImageData.preview, visible: value, src: "" },
				});
			},
		},
	});

	const addNewElementHelper = (itemKey: "description" | "title") => {
		const tempArray: any = seriesMultipleItems[itemKey].map(item => item);
		const tempArrayLength = tempArray.length;
		tempArray.push((tempArrayLength + 1).toString());
		setMultipleSeriesItems({ ...seriesMultipleItems, [itemKey]: tempArray });
	};

	const handleThumbnailCancel = () => {
		setIsThumbnailFormOpen(false);
		thumbnailForm.resetFields();
		setDistributionCheckedList([]);
		setThumbnailEditId(undefined);
	};

	const addSeriesThumbnailsHandler = async () => {
		try {
			const {
				thumbnail_url,
				thumbnail_height,
				thumbnail_width,
				thumbnail_tags,
				thumbnail_file_name,
			} = await thumbnailForm.validateFields();

			const thumbnailValues = {
				height: thumbnail_height ?? 0,
				width: thumbnail_width ?? 0,
				url: thumbnail_url,
				thumbnail_tag_ids: thumbnail_tags ?? [],
				file_name: thumbnail_file_name ? thumbnail_file_name : getFileNameWithURL(thumbnail_url),
				thumbnails_distribution_ids: distributionCheckedList,
				id: uuidV4(),
			};

			let updatedThumbList = [];
			if (thumbnailEditId) {
				updatedThumbList = seriesMultipleItems.thumbnail.map(thumb =>
					thumb.id !== thumbnailEditId
						? thumb
						: {
								...thumb,
								...thumbnailValues,
								id: thumbnailEditId,
								is_update: thumb.existing_thumbnail ? true : false,
						  }
				);
			} else {
				updatedThumbList = [...seriesMultipleItems.thumbnail, thumbnailValues];
			}
			setMultipleSeriesItems({
				...seriesMultipleItems,
				thumbnail: updatedThumbList,
			});
			handleThumbnailCancel();
		} catch (error) {
			console.error("error in addSeriesThumbnailsHandler", error);
		}
	};

	const removeSeriesThumbnails = (id: string) => {
		const updatedThumbnailsList = seriesMultipleItems.thumbnail
			.filter(thumb => thumb.existing_thumbnail || (thumb.id !== id && !thumb.existing_thumbnail))
			.map(thumb => (thumb.id === id ? { ...thumb, is_removed: true } : thumb));
		setMultipleSeriesItems({
			...seriesMultipleItems,
			thumbnail: updatedThumbnailsList,
		});
	};

	const thumbnailEditHandler = (thumbData: any) => {
		editThumbnailHelper(
			{
				...thumbData,
				thumbnails_distribution_ids: thumbData.thumbnails_distribution_ids?.join(",") ?? "",
				thumbnail_tag_ids: thumbData.thumbnail_tag_ids?.join(",") ?? "",
			},
			thumbnailForm,
			setThumbnailEditId,
			setIsThumbnailFormOpen,
			setDistributionCheckedList
		);
	};

	return (
		<CustomModal
			ref_id="dummy_ref_id"
			title={
				editId ? (
					<ModalTitleWithLastModifiedDate
						title="Update Series Info..."
						lastModifiedDate={form.getFieldValue("updated_at")}
					/>
				) : (
					"Add Series Info..."
				)
			}
			open={isAddSeriesFormOpen}
			onOk={saveSeriesData}
			onCancel={() => handleCancel()}
			okText={editId ? "Update Series" : "Save Series"}
			maskClosable={false}
			centered
		>
			<Spin spinning={isLoading}>
				<Form form={form} name="basic" autoComplete="off" layout="vertical" requiredMark={false}>
					<SeriesFormRow gutter={[16, 16]}>
						<Col span={12}>
							<Form.Item
								label="Series Code"
								name="series_code"
								rules={[{ required: true, message: "This is required" }]}
							>
								<Input placeholder="Series Code" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Status" name="status">
								<Input placeholder="Status" />
							</Form.Item>
						</Col>
					</SeriesFormRow>
					{/* <SeriesFormRow>
						<Col span={24}>
							<LastModifiedInput />
						</Col>
					</SeriesFormRow> */}
					{/* show list of title inputs */}
					{seriesMultipleItems.title.map((ele, index) => (
						<TitleWithLanguageFormElement
							key={"title" + index}
							languagesList={languagesList}
							index={index}
						/>
					))}
					<Button type="default" onClick={() => addNewElementHelper("title")}>
						+ Title
					</Button>

					{/* show list of description inputs */}
					{seriesMultipleItems.description.map((ele, index) => (
						<SeriesFormElement key={"title" + index} languagesList={languagesList} index={index} />
					))}
					<Button
						style={{ marginBottom: "8px" }}
						type="default"
						onClick={() => addNewElementHelper("description")}
					>
						+ Description
					</Button>

					<p>Thumbnails</p>
					{seriesMultipleItems.thumbnail
						.filter(thumbData => thumbData.is_removed !== true)
						.map((thumbData: any, index) => {
							return (
								<ThumbnailsDataRow justify="space-between" key={thumbData.file_name + index}>
									<ThumbnailFileNameSpan onClick={() => thumbnailEditHandler(thumbData)}>
										{thumbData.file_name?.length ? (
											<span title={thumbData.file_name}>
												{thumbData.file_name.length > 30
													? thumbData.file_name.slice(0, 27) + "..."
													: thumbData.file_name}
											</span>
										) : (
											"No filename"
										)}
									</ThumbnailFileNameSpan>
									<span>
										<EyeOutlined
											title="View thumbnail"
											onClick={() =>
												setPreviewImageData({
													src: thumbData.preview_url,
													preview: {
														onVisibleChange: previewImageData.preview.onVisibleChange,
														src: thumbData.preview_url,
														visible: true,
													},
												})
											}
										/>{" "}
										&nbsp;
										<Image
											width={0}
											height={"auto"}
											style={{ display: "none" }}
											{...previewImageData}
											fallback="FALLBACK_IMAGE"
										/>
										<EditOutlined
											title="Edit thumbnail"
											onClick={() => thumbnailEditHandler(thumbData)}
										/>{" "}
										&nbsp;
										<DeleteOutlined
											title="Delete thumbnail"
											id="delete"
											onClick={() => removeSeriesThumbnails(thumbData.id)}
										/>
									</span>
								</ThumbnailsDataRow>
							);
						})}
					<Button
						style={{ marginBottom: "8px" }}
						type="default"
						// onClick={() => addNewElementHelper("thumbnail")}
						onClick={() => setIsThumbnailFormOpen(true)}
					>
						+ Thumb URL
					</Button>
					<SeriesFormRow gutter={[16, 16]}>
						<Col span={8}>
							<Form.Item label="US" name="rating_us">
								<Select allowClear={{ clearIcon: <CloseIcon /> }}>
									{ratings?.us?.map((rating: string, i: number) => (
										<Select.Option value={rating} key={"us" + rating + i}>
											{rating}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label="AU" name="rating_au">
								<Select allowClear={{ clearIcon: <CloseIcon /> }}>
									{ratings?.au?.map((rating: string, i: number) => (
										<Select.Option value={rating} key={"au" + rating + i}>
											{rating}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label="Canada" name="rating_canada">
								<Select allowClear={{ clearIcon: <CloseIcon /> }}>
									{ratings?.canada?.map((rating: string, i: number) => (
										<Select.Option value={rating} key={"canada" + rating + i}>
											{rating}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</SeriesFormRow>
					<Form.Item
						label="Content Provider"
						name="content_provider"
						rules={[{ required: true, message: "This is required" }]}
					>
						<Input />
					</Form.Item>
					<Form.Item label="Wurl Categories" name="wurl_categories">
						<Input />
					</Form.Item>
					<Form.Item label="Sgenno Series" name="sgenno_series">
						<Input />
					</Form.Item>
					<Form.Item label="Zype Genre" name="zype_genre">
						<Input />
					</Form.Item>
					<Form.Item label="Internal Notes" name="internal_notes">
						<Input.TextArea placeholder="Internal Notes for series" />
					</Form.Item>
					<Form.Item label="S3 Folder" name="s3_folder">
						<Input placeholder="s3://bucket-name/folder-name" />
					</Form.Item>
					<SeriesFormRow gutter={[16, 16]}>
						<Col span={12}>
							<Form.Item label="Gracenote Series TMS ID" name="gracenote_series_tms_id">
								<Input placeholder="Gracenote Series TMS ID" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Original Air Date" name="original_air_date">
								<StyledDatePicker format={["MM/DD/YYYY"]} placeholder="MM/DD/YYYY" />
							</Form.Item>
						</Col>
					</SeriesFormRow>
					<SeriesFormRow gutter={[16, 16]}>
						<Col span={12}>
							<Form.Item label="Genre" name="genre">
								<Input placeholder="Genre" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="IMDb Series ID" name="imdb_series_id">
								<Input placeholder="IMDb Series ID" />
							</Form.Item>
						</Col>
					</SeriesFormRow>
				</Form>
			</Spin>
			<ThumbnailFormModal
				handleAddCustomTag={handleAddCustomTag}
				thumbnailEditId={thumbnailEditId}
				isOpen={isAddThumbnailFormOpen}
				saveEditThumbnailData={addSeriesThumbnailsHandler}
				handleCancel={handleThumbnailCancel}
				isLoading={thumbnailIsLoading}
				form={thumbnailForm}
				distributionPlatformData={distributionPlatformData}
				distributionCheckedList={distributionCheckedList}
				distributionChange={distributionChange}
				onDistributionCheckAllChange={onDistributionCheckAllChange}
				checkAll={checkAll}
				indeterminate={indeterminate}
				tagsList={tagsList}
				isFromSeries
			/>
		</CustomModal>
	);
};

export default AddSeriesForm;
