import React from "react";
import { useOutletContext } from "react-router-dom";
import { Col, Form, Radio, Row, Select, Spin } from "antd";

import { CloseIcon, ComponentTitle, ExportButton } from "../../../custom-components";
import { TaskRow } from "../../../zype/styled-components";
import { ExportCard } from "../../video";
import useWurlExportHook from "./hooks/useWurlExportHook";
import { seriesFilterOptions, tagsFilterOptions } from "../../../utils";
import { LanguageData } from "../../../videos/modals/AddVideosMetaDataForm";

const WurlExport = () => {
	const { form, exportWurlData } = useWurlExportHook();
	const { languagesList, tagsList, seriesList, loading }: any = useOutletContext();
	tagsList.filter((tag: any) => tag.related_to.includes("video"));
	return (
		<Spin spinning={loading}>
			<Row justify="center">
				<Col>
					<ComponentTitle>Wurl Export</ComponentTitle>
				</Col>
			</Row>
			<TaskRow>
				<ExportCard
					actions={[<ExportButton type="primary" onClick={exportWurlData} key="export-zype-btn" />]}
				>
					<Form form={form} requiredMark={false} layout="vertical">
						<Form.Item
							label="On Wurl"
							name="on_wurl"
							rules={[{ required: true, message: "This is required field" }]}
						>
							<Radio.Group>
								<Radio value={true}>Yes</Radio>
								<Radio value={false}>No</Radio>
							</Radio.Group>
						</Form.Item>
						<Form.Item label="Series" name="series">
							<Select
								filterOption={seriesFilterOptions}
								mode="multiple"
								allowClear={{ clearIcon: <CloseIcon /> }}
								showSearch
								placeholder="Series"
							>
								{seriesList?.map((series: any) => (
									<Select.Option value={series.id} key={series.id}>
										{series.titles}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item label="Audience Language" name="audience_language">
							<Select
								mode="multiple"
								allowClear={{ clearIcon: <CloseIcon /> }}
								placeholder="Audience Language"
							>
								{languagesList?.map((language: LanguageData) => (
									<Select.Option value={language.NAME} key={language.ID}>
										{language.NAME}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item label="Video Tags" name="video_tags">
							<Select
								mode="multiple"
								allowClear={{ clearIcon: <CloseIcon /> }}
								placeholder="Videos that have tags"
								filterOption={tagsFilterOptions}
							>
								{tagsList?.map((tag: any) => (
									<Select.Option value={tag.id} key={tag.id}>
										{tag.title}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item label="Not Tagged(Video)" name="video_not_tagged">
							<Select
								mode="multiple"
								allowClear={{ clearIcon: <CloseIcon /> }}
								placeholder="Videos that don't have tags"
								filterOption={tagsFilterOptions}
							>
								{tagsList?.map((tag: any) => (
									<Select.Option value={tag.id} key={tag.id}>
										{tag.title}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item label="Episode Tags" name="episode_tags">
							<Select
								mode="multiple"
								allowClear={{ clearIcon: <CloseIcon /> }}
								placeholder="Episodes that have tags"
								filterOption={tagsFilterOptions}
							>
								{tagsList?.map((tag: any) => (
									<Select.Option value={tag.id} key={tag.id}>
										{tag.title}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item label="Not Tagged(Episode)" name="episode_not_tagged">
							<Select
								mode="multiple"
								allowClear={{ clearIcon: <CloseIcon /> }}
								placeholder="Episodes that don't have tags"
								filterOption={tagsFilterOptions}
							>
								{tagsList?.map((tag: any) => (
									<Select.Option value={tag.id} key={tag.id}>
										{tag.title}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Form>
				</ExportCard>
			</TaskRow>
		</Spin>
	);
};

export default WurlExport;
