import { Modal } from "antd";
import React from "react";

type WarningModalPropsType = {
	warningModalOpen: boolean;
	handleCancel: (isWarningModal?: boolean) => void;
	dataToBeDeleted: any;
	saveTagsDataHandler: () => void;
	isLoading: boolean;
};

const WaningModal: React.FC<WarningModalPropsType> = ({
	warningModalOpen,
	isLoading,
	handleCancel,
	dataToBeDeleted,
	saveTagsDataHandler,
}) => {
	const { count, missingRelation: type, tag, episodeCount } = dataToBeDeleted ?? {};
	const typeTitle = type?.[0]?.toUpperCase() + type?.slice(1);
	return (
		<Modal
			open={warningModalOpen}
			title="Warning"
			onCancel={() => handleCancel(true)}
			onOk={saveTagsDataHandler}
			cancelButtonProps={{ disabled: isLoading }}
			okButtonProps={{ loading: isLoading }}
			cancelText="Cancel"
			okText="Remove Tag"
			centered
		>
			{`Are you sure you want to remove the "${tag}" tag from ${
				count > 1 || episodeCount > 1 ? "all" : ""
			} ${count > 1 ? `${count} ${typeTitle}s` : count === 1 ? `1 ${typeTitle}` : ""} ${
				count && episodeCount ? "and" : ""
			} ${
				episodeCount > 1 ? `${episodeCount} Episodes ` : episodeCount === 1 ? "and 1 Episode " : ""
			}that currently have it?`}
		</Modal>
	);
};

export default WaningModal;
