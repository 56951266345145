import { FormInstance } from "antd";
import { Dispatch, SetStateAction } from "react";
import { authAxios } from "../../utils/session_utils";
import { PaginationProps } from "antd/lib/pagination";
import { makeKeyInLowerCaseHelper } from "../../utils";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { getFileNameWithURL } from "../../videos/modals/AddVideosMetaDataForm";
import { CaptionsFilterObj } from "./useCaptionsHook";

export const getCaptionsData = async (
	setCaptionsData: Dispatch<SetStateAction<any[]>>,
	setIsLoading: Dispatch<SetStateAction<boolean>>,
	pagination: PaginationProps,
	setPagination: Dispatch<SetStateAction<PaginationProps>>,
	captionsFilterObj?: CaptionsFilterObj
) => {
	try {
		const config: any = {
			method: "GET",
			url: `${process.env.REACT_APP_BACK_END_API}/snowflake/captions?pagination=${JSON.stringify(
				pagination
			)}&filter=${JSON.stringify(captionsFilterObj ?? {})}`,
			headers: {
				"Content-Type": "application/json",
			},
		};
		const response = await authAxios(config);
		const convertedObject = await makeKeyInLowerCaseHelper(response.data.rows);
		setPagination({ ...pagination, total: response.data.totalCaptions })
		setCaptionsData(convertedObject);
		setIsLoading(false);
	} catch (error) {
		setIsLoading(false);
		console.error(error);
	}
};

export const saveUpdateCaptionsData = async (
	values: any,
	episodeId: string | Blob,
	distributionCheckedList: CheckboxValueType[],
	captionEditId?: string
) => {
	try {
		const { caption_type, caption_language, caption_offset_time, caption_url, captionFileName } =
			values;
		const config: any = {
			method: captionEditId ? "PUT" : "POST",
			url: `${process.env.REACT_APP_BACK_END_API}/snowflake/captions`,
			headers: {
				"Content-Type": "application/json",
			},
			data: JSON.stringify({
				caption_type,
				language: caption_language,
				offset_time: caption_offset_time,
				caption_url,
				episode_id: episodeId,
				id: captionEditId,
				file_name: captionFileName,
				caption_distribution: distributionCheckedList,
			}),
		};
		const response = await authAxios(config);
		return response;
	} catch (err) {
		console.error("error in saving/ updating caption data", err);
		return { data: { err } };
	}
};

export const setDistributionFormItems = (distribution_platform_ids: string) =>
	distribution_platform_ids
		?.split(",")
		?.filter((p_id: any) => p_id?.trim()?.length)
		?.map((p_id: any) => p_id);

export const editCaptionHelper = async (
	record: any,
	form: FormInstance,
	setCaptionEditId: Dispatch<SetStateAction<string | undefined>>,
	setIsOpenCaptionsForm: Dispatch<SetStateAction<boolean>>,
	setDistributionCheckedList: Dispatch<SetStateAction<CheckboxValueType[]>>,
	setEpisodeId?: Dispatch<SetStateAction<string>>
) => {
	try {
		const {
			id,
			caption_type,
			language,
			offset_time,
			ref_id,
			episode_id,
			url,
			file_name,
			caption_distribution_ids,
		} = record;
		setCaptionEditId(id);
		setEpisodeId && setEpisodeId(episode_id);
		setIsOpenCaptionsForm(true);
		const distributionIds = setDistributionFormItems(caption_distribution_ids);
		setDistributionCheckedList(distributionIds);
		form.setFieldsValue({
			caption_type,
			caption_language: language,
			caption_offset_time: offset_time,
			caption_ref_id: ref_id,
			caption_url: url,
			captionFileName: file_name ?? getFileNameWithURL(url),
		});
	} catch (err) {
		console.error("error in editHelper", err);
	}
};

export const deleteCaption = async (id: string) => {
	const config = {
		method: "DELETE",
		url: `${process.env.REACT_APP_BACK_END_API}/snowflake/captions/${id}`,
	};
	const response = await authAxios(config);
	return response;
};

export const resetCaptionForm = (form: FormInstance) => {
	form.resetFields([
		"caption_ref_id",
		"caption_type",
		"caption_language",
		"caption_offset_time",
		"caption_url",
		"captionFileName",
	]);
};