import React, { FC } from "react";
import { Button, Card, Col, Row, Typography, Upload, Form, Input } from "antd";
import styled from "@emotion/styled";
import { UploadOutlined } from "@ant-design/icons";
// import { getEmailFromSessionStorage } from "../utils/session_utils";
import useAutoPodderUploadHook from "./hooks/useAutoPodderUploadHook";
import { TaskRow } from "../zype/styled-components";
import { ComponentTitle, StledSpin } from "../custom-components";
import { TabComponentProps } from "../utils/common.interface";
import { integer } from "aws-sdk/clients/cloudfront";
import { Navigate } from "react-router-dom";

const { Dragger } = Upload;

export const StyledDragger = styled(Dragger)`
	padding: 10px;
	& .ant-upload-drag {
		padding: 10px;
	}
`;

type PodderPramsFieldType = {
  weight_300?: integer;
	weight_180?: integer;
	weight_120?: integer;
	weight_90?: integer;
	weight_60?: integer;
	weight_45?: integer;
	weight_30?: integer;
	weight_15?: integer;
	max_cascade_seconds?: integer;
};

const AutoPodderComponent: FC<TabComponentProps> = () => {
	const { loading, handleUpload, uploadProps, redirectToBatchJobs } = useAutoPodderUploadHook();
	if (redirectToBatchJobs) {
		return <Navigate to="/batch-jobs" />;
	}
	return (
		<StledSpin spinning={loading}>
			<Row justify="center">
				<Col>
					<ComponentTitle>Upload Schedule & Structure to AutoPodder</ComponentTitle>
				</Col>
			</Row>
			<TaskRow>
				<Card
					actions={[
						<Button key="import-btn" type="primary" onClick={handleUpload}>
							Submit
						</Button>,
					]}
				>
					<StyledDragger {...uploadProps}>
						<Typography.Paragraph>Please select a CSV file</Typography.Paragraph>
						<Button icon={<UploadOutlined />}>Select File</Button>
					</StyledDragger>
					
					<Row justify="center">
						<Col>
							<Typography.Paragraph>
								Auto Podder Params: <br />
								Weights: Weight setting is done Ad Length wise. If left blank, default 100 would be assumed. <br />
								Max Cascade Seconds: If left blank, default 60 Seconds would be assumed.
							</Typography.Paragraph>
						</Col>
					</Row>
					<Row justify="center">
						<Col>
							<Typography.Title level={5}>Weights</Typography.Title>
						</Col>
					</Row>
					<Form.Item<PodderPramsFieldType> label="300" name="weight_300"> <Input name="weight_300" /> </Form.Item>
					<Form.Item<PodderPramsFieldType> label="180" name="weight_180"> <Input name="weight_180" /> </Form.Item>
					<Form.Item<PodderPramsFieldType> label="120" name="weight_120"> <Input name="weight_120" /> </Form.Item>
					<Form.Item<PodderPramsFieldType> label="90" name="weight_90"> <Input name="weight_90" /> </Form.Item>
					<Form.Item<PodderPramsFieldType> label="60" name="weight_60"> <Input name="weight_60" /> </Form.Item>
					<Form.Item<PodderPramsFieldType> label="45" name="weight_45"> <Input name="weight_45" /> </Form.Item>
					<Form.Item<PodderPramsFieldType> label="30" name="weight_30"> <Input name="weight_30" /> </Form.Item>
					<Form.Item<PodderPramsFieldType> label="15" name="weight_15"> <Input name="weight_15" /> </Form.Item>
					<Row justify="center">
						<Col>
							<Typography.Title level={5}>Max Cascade Seconds</Typography.Title>
						</Col>
					</Row>
					<Form.Item<PodderPramsFieldType> label="Max Cascade Seconds" name="max_cascade_seconds"> <Input name="max_cascade_seconds"/> </Form.Item>
				</Card>
			</TaskRow>
		</StledSpin>
	);
};

export default AutoPodderComponent;
