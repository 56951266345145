import React, { FC } from "react";
import { Modal } from "antd";
import { CustomTable } from "../../series/StyledComponent";

type MetadataInfoModalType = {
	isMetadataInfoModalOpen: boolean;
	handleCloseMetadataModal: () => void;
	metadataInfo: any[];
	videoTitle: string;
	durationValue: string | number;
};

const MetadataInfoModal: FC<MetadataInfoModalType> = ({ videoTitle, handleCloseMetadataModal, isMetadataInfoModalOpen, metadataInfo }) => {
	const metadataColumns = [
		{
			title: "Language",
			dataIndex: "metadata_language",
			key: "metadata_language",
			width: "10%",
		},
		{
			title: "Title",
			dataIndex: "title",
			key: "title",
			width: "10%",
		},
		{
			title: "Clean Title",
			dataIndex: "clean_title",
			key: "clean_title",
			width: "15%",
		},
		{
			title: "Keywords",
			dataIndex: "keywords",
			key: "keywords",
			width: "15%",
		},
		{
			title: "Description 100",
			dataIndex: "description_100",
			key: "description_100",
		},
		{
			title: "Description 250",
			dataIndex: "description_250",
			key: "description_250",
		},
		{
			title: "Full Description",
			dataIndex: "description_full",
			key: "description_full",
		},
	];
	return (
		<Modal
			open={isMetadataInfoModalOpen}
			footer={null}
			title={`Metadata Modal Info ${videoTitle}`}
			width={1000}
			onCancel={handleCloseMetadataModal}
		>
			<CustomTable
				columns={metadataColumns}
				dataSource={metadataInfo}
				pagination={false}
				scroll={{ x: 1000 }}
			/>
		</Modal>
	);
};

export default MetadataInfoModal;
