import { useEffect, useState } from "react";

const useScreenSizeHook = () => {
	const [height, setHeight] = useState(0);
	const [width, setWidth] = useState(0);

	useEffect(() => {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
		window.addEventListener("resize", () => {
			setWidth(window.innerWidth);
			setHeight(window.innerHeight);
		});
		() => {
			window.removeEventListener("resize", () => {
				console.info("removed");
			});
		};
	}, []);
	return { height, width };
};

export default useScreenSizeHook;
