import React from "react";
import { ComponentTitle, PageTitleLastCol } from "../custom-components";
import { CustomTable } from "../series/StyledComponent";
import useScreenSizeHook from "../utils/useScreenSizeHook";
import { Col, Row } from "antd";
import { useBatchJobsHook } from "./hooks/useBatchJobsHook";

const BatchJobsComponent = () => {
	const { height } = useScreenSizeHook();
	const {  getColumnsBatchJobs, batchJobsData, isLoading, pagination } = useBatchJobsHook();
	
	return (
		<>
			<Row justify="space-between" align="middle">
				<Col>
					<ComponentTitle>Batch Jobs</ComponentTitle>
				</Col>
				<PageTitleLastCol>
				</PageTitleLastCol>
			</Row>
			<CustomTable
				columns={getColumnsBatchJobs()}
				dataSource={batchJobsData}
				scroll={{ y: height - 240 }}
				loading={isLoading}
				pagination={pagination}
			/>
		</>
	);
};

export default BatchJobsComponent;
