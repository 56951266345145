import React from "react";
import { StyledFilterModal } from "../../episode/modals/EpisodeFilterModal";
import { Col, Form, FormInstance, Input } from "antd";
import { getDigitsOnly } from "../../utils";
import { BorderedRow, FilterInfoTitle } from "../../custom-components";
import { VideoLists } from "../../video-lists/hooks/useVideoListsHook";
import {
	DistributionPlatformsItem,
	EpisodeRelatedFiltersForAssets,
} from "../../custom-components/form/fliter";

const { Item } = Form;

type CuePointFilterModalProps = {
	cuePointFilterModalOpen: boolean;
	form: FormInstance;
	getFilteredCuePoints: () => void;
	cuePointFilterModalCancelHandler: () => void;
	seriesData: any[];
	distributionPlatformData: any[];
	videoLists: VideoLists[];
};

const CuePointFilterModal: React.FC<CuePointFilterModalProps> = ({
	cuePointFilterModalOpen,
	form,
	seriesData,
	getFilteredCuePoints,
	distributionPlatformData,
	cuePointFilterModalCancelHandler,
	videoLists,
}) => {
	return (
		<StyledFilterModal
			open={cuePointFilterModalOpen}
			okText="Filter"
			cancelText="Close"
			onOk={getFilteredCuePoints}
			title="Filter Cue Points By"
			maskClosable={false}
			centered
			onCancel={cuePointFilterModalCancelHandler}
		>
			<FilterInfoTitle>Show cuepoints that belong to Episodes with these filters:</FilterInfoTitle>
			<Form form={form} layout="vertical">
				<EpisodeRelatedFiltersForAssets seriesData={seriesData} videoLists={videoLists} />
				<FilterInfoTitle>Show cuepoints that match these filters:</FilterInfoTitle>
				<BorderedRow>
					<Col span={24}>
						<DistributionPlatformsItem
							distributionPlatformData={distributionPlatformData}
							mode="multiple"
							name="distribution_platforms_filter"
						/>
					</Col>
					<Col span={24}>
						<Item name="offset_filter" label="Offset Time(in seconds)">
							<Input
								placeholder="Offset Time(in seconds)"
								onChange={e => form.setFieldValue("offset_filter", getDigitsOnly(e.target.value))}
							/>
						</Item>
					</Col>
				</BorderedRow>
			</Form>
		</StyledFilterModal>
	);
};

export default CuePointFilterModal;
