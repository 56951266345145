import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, Col, Row, Spin, message } from "antd";
import { Checkbox } from "antd/lib";

import { BulkAddDataModal, VideoSearchItem } from ".";
import { DistributionDataPropsTypes } from "../episode";
import { SearchOutlined } from "@ant-design/icons";
import { PlatformData } from "../distribution_partner/hooks/useDistributionData";
import { authAxios } from "../utils/session_utils";

export type SelectedItemData = { key: string; id: string };

interface AddItemsToPlatformInterface extends DistributionDataPropsTypes {
	isAddToDistributorsModalOpen: boolean;
	setIsAddToDistributorsModalOpen: Dispatch<SetStateAction<boolean>>;
	distributionPlatformData: PlatformData[];
	selectedItemData: SelectedItemData[];
	selectedItemType: string;
	getItemsDataHandler: (params: any) => void;
	setDistributionCheckedList: Dispatch<SetStateAction<any[]>>;
}

const AddItemsToDistributionPlatformModal: React.FC<AddItemsToPlatformInterface> = ({
	isAddToDistributorsModalOpen,
	setIsAddToDistributorsModalOpen,
	distributionCheckedList,
	setDistributionCheckedList,
	distributionChange,
	distributionPlatformData,
	onDistributionCheckAllChange,
	checkAll,
	indeterminate,
	selectedItemData,
	selectedItemType,
	getItemsDataHandler,
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [searchText, setSearchText] = useState<string>("");

	const addItemsToDistributionPlatform = async () => {
		try {
			setIsLoading(true);
			const data = {
				item_type: selectedItemType,
				item_ids: selectedItemData.map(item => item.id),
				distributor_ids: distributionCheckedList,
			};
			const config = {
				method: "POST",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/bulk/add-items-to-distributors`,
				data: JSON.stringify(data),
				headers: { "Content-Type": "application/json" },
			};
			const response = await authAxios(config);
			message.success(response.data.message, 3);
			await getItemsDataHandler({});
			setIsLoading(false);
			closeHandler();
		} catch (error) {
			setIsLoading(false);
			message.error(`${selectedItemType} could not be attached to distributions platforms`);
			console.error("error addItemsToDistributionPlatform", error);
		}
	};

	const removeItemsFromDistributionPlatforms = async () => {
		try {
			setIsLoading(true);
			const data = {
				item_type: selectedItemType,
				item_ids: selectedItemData.map(item => item.id),
				distributor_ids: distributionCheckedList,
			};
			const config = {
				method: "POST",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/bulk/remove-items-from-distributors`,
				data: JSON.stringify(data),
				headers: { "Content-Type": "application/json" },
			};
			const response = await authAxios(config);
			message.success(response.data.message, 3);
			await getItemsDataHandler({});
			setIsLoading(false);
			closeHandler();
		} catch (error) {
			setIsLoading(false);
			message.error(`${selectedItemType} could not be removed from distributions platforms`);
			console.error("error removeItemsFromDistributionPlatforms", error);
		}
	};

	const closeHandler = () => {
		setIsAddToDistributorsModalOpen(false);
		setDistributionCheckedList([]);
	};
	return (
		<BulkAddDataModal
			open={isAddToDistributorsModalOpen}
			onCancel={closeHandler}
			title="Add or Remove Distribution:"
			centered
			footer={
				<>
					<Button
						onClick={removeItemsFromDistributionPlatforms}
						disabled={!distributionCheckedList?.length || isLoading}
						danger
					>
						Remove
					</Button>
					<Button
						onClick={addItemsToDistributionPlatform}
						disabled={!distributionCheckedList?.length || isLoading}
						type="primary"
					>
						Add
					</Button>
				</>
			}
		>
			<Spin spinning={isLoading}>
				<VideoSearchItem
					placeholder="Search Distribution"
					onChange={e => setSearchText(e.target.value)}
					suffix={<SearchOutlined />}
				/>
				{!searchText && (
					<Checkbox
						indeterminate={indeterminate}
						onChange={onDistributionCheckAllChange}
						checked={checkAll}
					>
						{checkAll ? "De-Select All" : "Select All"}
					</Checkbox>
				)}
				<Checkbox.Group onChange={distributionChange} value={distributionCheckedList}>
					<Row gutter={[0, 4]}>
						{distributionPlatformData
							.filter(platform =>
								searchText ? platform.name.toLowerCase().includes(searchText?.toLowerCase()) : true
							)
							.map((platform: any) => {
								return (
									<Col span={24} key={platform.key}>
										<Checkbox value={platform.id} key={platform.key}>
											<b>{platform.name}</b>
										</Checkbox>
									</Col>
								);
							})}
					</Row>
				</Checkbox.Group>
			</Spin>
		</BulkAddDataModal>
	);
};

export default AddItemsToDistributionPlatformModal;
