// src/hooks/invoiceUploadHook.tsx
import { useState, useEffect } from 'react';
import { authAxios } from "../../../utils/session_utils";

interface DropdownValues {
  partners: string[];
  channels: string[];
  territories: string[];
  platforms: string[];
  domains: string[];
  labels: string[];
  partnersByDomain: Record<string, string[]>;
  channelsByDomain: Record<string, string[]>;
  channelsByPartner: Record<string, string[]>;
  territoriesByPartner: Record<string, string[]>;
  partnersByPlatform: Record<string, string[]>;
  platformsPartnersTerritories:  Record<string, string[]>;
  platformsByDomain: Record<string, string[]>;
}

export const useDropdownValues = () => {
  const [dropdownValues, setDropdownValues] = useState<DropdownValues>({
    partners: [],
    channels: [],
    territories: [],
    platforms: [],
    domains: [],
    labels: [],
    platformsByDomain: {},
    partnersByDomain: {},
    channelsByDomain: {},
    channelsByPartner: {},
    territoriesByPartner: {},
    partnersByPlatform: {},
    platformsPartnersTerritories: {},
  });

  useEffect(() => {
    const fetchDropdownValues = async () => {
      try {
        const response = await authAxios({
          method: "GET",
          url: `${process.env.REACT_APP_BACK_END_API}/snowflake/register/dropdown_values`,
          headers: {
            "Content-Type": "application/json",
          },
        });

        const { data } = response;

        setDropdownValues(data);
      } catch (error) {
        console.error('Error fetching dropdown values:', error);
      }
    };

    fetchDropdownValues();
  }, []);

  return dropdownValues;
};
