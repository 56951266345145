import React, { FC } from "react";
import { Col, Form, Input, Select } from "antd";
import styled from "@emotion/styled";
import { LanguageData } from "./AddVideosMetaDataForm";

export const TitleContainer = styled.div`
	border: 1px solid #ccc;
	border-radius: 8px;
	padding: 18px 16px 0px;
	position: relative;
	margin-bottom: 16px;
	margin-top: 30px;
`;

export const TitleFormText = styled.p`
	position: absolute;
	left: 14px;
	top: -12px;
	background: #fff;
	padding: 0 4px;
`;
type Props = {
	languagesList: LanguageData[];
	ele: any;
	index: any;
};
const MetaForm: FC<Props> = ({ languagesList, index }: Props) => {
	return (
		<TitleContainer key={index}>
			<TitleFormText>Metadata</TitleFormText>
			<Col span={12}>
				<Form.Item
					key={`${index}_metaLanguage`}
					name={`${index}_metaLanguage`}
					rules={[{ required: true, message: "Required Field" }]}
				>
					<Select placeholder="Language">
						{languagesList?.map((language: LanguageData) => (
							<Select.Option value={language.NAME} key={language.ID}>
								{language.NAME}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</Col>
			<Form.Item
				label={"Title"}
				key={`${index}_title`}
				name={`${index}_title`}
				rules={[{ required: true, message: "Required Field" }]}
			>
				<Input placeholder="Title" />
			</Form.Item>
			<Form.Item label={"Clean Title"} key={`${index}_clean_title`} name={`${index}_clean_title`}>
				<Input placeholder="Clean Title" />
			</Form.Item>
			<Form.Item
				label={"Keywords"}
				key={`${index}_keywords`}
				name={`${index}_keywords`}
				// rules={[{ required: true, message: "Required Field" }]}
			>
				<Input placeholder="Keywords" />
			</Form.Item>
			<Form.Item
				label={"Description 100"}
				key={`${index}_description_100`}
				name={`${index}_description_100`}
			>
				<Input.TextArea showCount maxLength={100} />
			</Form.Item>
			<Form.Item
				label={"Description 250"}
				key={`${index}_description_250`}
				name={`${index}_description_250`}
				// rules={[{ required: true, message: "Required Field" }]}
			>
				<Input.TextArea showCount maxLength={250} />
			</Form.Item>
			<Form.Item
				label={"Full Description"}
				key={`${index}_description_full`}
				name={`${index}_description_full`}
			>
				<Input.TextArea showCount />
			</Form.Item>
		</TitleContainer>
	);
};

export default MetaForm;
