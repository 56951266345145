import React from 'react';
import { useEffect, useState } from "react";
import { initialPaginationObj } from "../../episode/hooks/useEpisodeHook";
import { useOutletContext } from "react-router";
import { PaginationProps, Tag, Typography, Table, TableColumnsType, Modal, Button, Select, message, Flex} from "antd";
import { getBatchJobsData } from "../../utils";
import { authAxios } from '../../utils/session_utils';
import moment from 'moment';


export const useBatchJobsHook = () => {
	
	const { user }: any = useOutletContext();
	const [batchJobsData, setBatchJobsData] = useState([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [pagination, setPagination] = useState<PaginationProps>({
		...initialPaginationObj,
		pageSize: user.page_sizes?.metadata ?? 10,
	});

	const handleUploadSFClick = async (data: any) => {
		const config: any = {
			method: "POST",
			url: `${process.env.REACT_APP_BACK_END_API}/snowflake/batch-jobs/upload-autopodder-prelogs`,
			headers: {
				"Content-Type": "application/json",
			},
			data: JSON.stringify({
				strategyName: data.strategyName,
				batchJobId: data.batchJobId,
			}),
		};
		const response = await authAxios(config);
		if (response.status === 200) {
			message.success(response.data.message + ' ' + response.data.result.Location);
		} else {
			message.error(response.data.message);
		}

		setIsLoading(false);
	}

	useEffect(() => {
		setIsLoading(true);
		getDataHanlder();
	}, [pagination.pageSize, pagination.current]);

	useEffect(() => {
		const interval = setInterval(() => {
			setIsLoading(true);
			getDataHanlder();
		}, 30000); // 30 seconds
		return () => clearInterval(interval);
	}, []);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [modalRows, setModalRows] = useState([]);
	const [modalTitle, setModalTitle] = useState("Unfilled Time");
	const [modalBatchJobId, setModalBatchJobId] = useState("");

	const handleModalOk = () => {
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

	const modalColumns = [
		{
			title: "Slot_ID",
			dataIndex: "slot_id",
			key: "slot_id",
			sorter: (a: any, b: any) => parseInt(a.slot_id.split('_')[1]) - parseInt(b.slot_id.split('_')[1]),
			sortDirections: ['ascend' as const, 'descend' as const, 'ascend' as const],
		},
		{
			title: "Date",
			dataIndex: "date_str",
			key: "date_str"
		},
		{
			title: "Unfilled Time",
			dataIndex: "unfilledTime",
			key: "unfilledTime",
			sorter: (a: any, b: any) => parseInt(a.unfilledTime) - parseInt(b.unfilledTime),
			defaultSortOrder: 'ascend' as const,
			sortDirections: ['ascend' as const, 'descend' as const, 'ascend' as const],
		},
	];

	const getDataHanlder = async () => {
		await getBatchJobsData(setBatchJobsData, setIsLoading, pagination);
	};

	const getColumnsBatchJobs = () => {
		const columnBatchJobs = [
			{
				title: "Job",
				dataIndex: "job_created_at",
				key: "job_created_at",
				width: "9%",
				render: (job_created_at: any) => {
					const localDate = moment(job_created_at).local();
					return localDate.format("MM/DD/YYYY HH:mm");
				}
			},
			{
				title: "toEmail",
				dataIndex: "useremail",
				key: "toEmail",
				width: "12%",
			},
			{
				title: "Status",
				dataIndex: "job_status",
				key: "status",
				render: (job_status: any) => {
					const status = job_status.status;
					const message = job_status.messsage;
					let spreadsheetURL = null;
					if(job_status.spreadsheetURL && job_status.spreadsheetURL.length > 0) {
						spreadsheetURL = job_status.spreadsheetURL.includes("http") ? job_status.spreadsheetURL : null;
					}
	
					return <Flex vertical={true} align='center'>
						<Tag color={status === "RUNNING" ? "#108ee9" : status === "FAILED" ? "#f50" : status === "FINISHED" ? "#87d068" : "blue"}>
							{status}
						</Tag>
						{message && status === "FAILED"  && <Typography.Text><br />{message}<br /></Typography.Text>}
						{ spreadsheetURL && (
									<a href={spreadsheetURL} target="_blank" rel="noreferrer" className='regular-link'>
										(Result Sheet)
									</a>
								)
						}
					</Flex>;
				}
			},
			{
				title: "",
				dataIndex: "job_result_json",
				key: "details",
				width: "48%",
				render: (json_string: any, record: any) => {
					if(json_string.length > 0) {
						const json_result = JSON.parse(json_string);
						
	
						interface DataType {
							key: React.Key;
							plan: string;
							batchJobId: string;
							'unfilled time': string;
							'total dollars': string;
							'unfilled dates': { planName: string, unfilledDates: any[] };
						}
	
						// eslint-disable-next-line prefer-const
						let data: DataType[] = [];
						// console.log(json_result);
						let i = 0;
						for(const planName in json_result.data) {
							const unfilledDatesSet = new Set();
							i++;
							const row = json_result.data[planName];
							// console.log(json_result.data[planName].slots_with_unfilled_time);
							for(let j = 0; j < json_result.data[planName].slots_with_unfilled_time.length; j++) {
								const slot = json_result.data[planName].slots_with_unfilled_time[j];
								unfilledDatesSet.add(slot.date_str);
							}
							data.push({
								key: i,
								batchJobId: record.id,
								plan: planName,
								'unfilled time': row['unfilledTime'],
								'total dollars': row['totalDollars'],
								'unfilled dates': { planName: planName, unfilledDates: Array.from(unfilledDatesSet) },
							});
						}
						
						const columns: TableColumnsType<DataType> = [
							{
								title: 'Plan',
								dataIndex: 'plan',
								key: 'plan',
								width: '35%',
							},
							{
								title: 'Unfilled Time',
								dataIndex: 'unfilled time',
								key: 'unfilled time',
								width: '18%',
							},
							{
								title: 'Dollars',
								dataIndex: 'total dollars',
								key: 'dollars',
								width: '15%',
							},
							{
								title: 'Unfilled Dates',
								dataIndex: 'unfilled dates',
								key: 'unfilled dates',
								render: (data: any, record: any, index: number) => {
									const planName = data.planName;
									const dates = data.unfilledDates;
									const yyyyMMDDPartsStringArr: string[] = [];
									for(let i = 0; i < dates.length; i++) {
										const yyyyMMDDParts = dates[i].split('-');
										yyyyMMDDPartsStringArr.push(yyyyMMDDParts[1] + '/' + yyyyMMDDParts[2]);
									}
									return <a onClick={
										() => {
											setModalBatchJobId(record.batchJobId);
											setModalTitle(planName + " = " + json_result.data[planName].unfilledTime + " seconds");
											setModalRows(json_result.data[planName].slots_with_unfilled_time);
											setIsModalVisible(true);
										}
									} className='regular-link'>	{yyyyMMDDPartsStringArr.join(',')} </a>;
								}
							},
						];										
						

						return ((data.length > 0) ? 
								<Flex vertical={true}>
									<Table columns={columns} dataSource={data} pagination={false} size='small' scroll={{ y: 140 }} />
									<Modal title={modalTitle} open={isModalVisible} onOk={handleModalOk} onCancel={handleModalCancel} >
										<Table columns={modalColumns} dataSource={modalRows} pagination={false} size='small' scroll={{ y: 540 }} />
									</Modal>
								</Flex>
							: "");
					} else {
						return "";
					}
				}
			},
			{
				title: "",
				dataIndex: "action",
				key: "action",
				render: (text: any, record: any) => {
					if(record.job_result_json === "" || record.job_result_json === null) {
						return "";
					}
					const json_result = JSON.parse(record.job_result_json);
					// console.log(json_result.data);
					if(json_result.data){
						const options = Object.keys(json_result.data).map((key) => {
							const disabled = false; // (json_result.data[key].unfilledTime === 0) ? false : true;
							return { value: key, label: key, disabled: disabled };
						});
						
						return (
							<Flex vertical={true}>
								<Select placeholder="Select a plan"  options={options} onChange={(value) => {
									const buttonSFUpload = document.getElementById(`buttonSFUpload_${record.id}`);
									if(buttonSFUpload) {
										buttonSFUpload.setAttribute("data-strategy-name", value);
									}
								}
								} />
								<Button type="primary" id={`buttonSFUpload_${record.id}`} onClick={(element) => {
									const strategyName = element.currentTarget.getAttribute("data-strategy-name");
									if(strategyName) {
										setIsLoading(true);
										handleUploadSFClick({ strategyName, batchJobId: record.id });
									}
								}}>SF Upload</Button>
							</Flex>
						);
					
					}
				}
			},
		];
		
		return columnBatchJobs;
	};
	
	return { getColumnsBatchJobs, batchJobsData, isLoading, pagination };
};

