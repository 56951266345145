import { useState } from "react";
import { message } from "antd";

import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { authAxios, getEmailFromSessionStorage } from "../../../utils/session_utils";
import { useLocation, useOutletContext } from "react-router-dom";
import { uploaderContent } from "..";

const useVideosImportHook = () => {
	const { pathname } = useLocation();
	const { user }: any = useOutletContext();
	const [loading, setLoading] = useState<boolean>(false);
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [file, setFile] = useState<any>(null);

	const handleFileRemove = () => {
		setFile(null);
		setFileList([]);
		return true;
	};

	const beforeUpload = (file: any) => {
		setFileList([file]);
		setFile(file);
		return false;
	};

	const uploadProps: UploadProps = {
		accept: ".csv",
		onRemove: handleFileRemove,
		beforeUpload: beforeUpload,
		fileList,
	};

	const handleUpload = async () => {
		if (!file) {
			message.error("Please insert a file first!");
		} else {
			setLoading(true);
			const formData = new FormData();
			formData.append("file", fileList[0] as RcFile);
			formData.append("name", user.name);

			try {
				// const { email } = await form.validateFields();
				const toEmail = getEmailFromSessionStorage();
				formData.append("email", toEmail as string);

				const config = {
					method: "POST",
					url: `${process.env.REACT_APP_BACK_END_API}${uploaderContent[pathname]["apiEndpoint"]}`,
					data: formData,
					headers: {
						"Content-Type": "multipart/form-data",
					},
				};
				const response = await authAxios(config);
				// const response = await axios.post(`${process.env.REACT_APP_BACK_END_API}/import/data`, formData, {
				// 	headers: {
				// 		"Content-Type": "multipart/form-data",
				// 	},
				// });
				if (!response.data.err) {
					message.success(response.data.message);
					setFile(null);
					setFileList([]);
				} else {
					message.error("Something went wrong");
				}
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				console.error(error);
				if (error?.response?.data?.message) {
					message.error(error.response.data.message, 5);
				}
			}
		}
	};
	return { pathname, loading, file, handleUpload, uploadProps };
};

export default useVideosImportHook;
