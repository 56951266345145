import { EditOutlined } from "@ant-design/icons";
import { CustomBtn } from "../../series/StyledComponent";
import React, { Fragment } from "react";
import { Tag } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import { ColumnsType } from "antd/es/table";
import { PlayVideoIcon } from "../../videos/hooks/useVideosHooks";
import { TextWithCopyButton } from "./cuePointColumns";
import { DeletePopConfirm, DescriptionText } from "../../custom-components";
import { lastModifiedCol, refIdColumn } from "../../utils/table-columns";

export const getColumnEpisodes = (
	deleteEpisodeData: (id: string) => void,
	loading: boolean,
	episodeFilterObj: any
) => {
	const columnEpisode: ColumnsType<object> = [
		{
			...refIdColumn,

			width: "90px",
			sorter: (a: any, b: any) => a.ref_id.localeCompare(b.ref_id),
			defaultSortOrder: "ascend",
		},
		lastModifiedCol(),
		{
			title: "Title",
			dataIndex: "title",
			key: "title",
			width: "10%",
			render: (value: string, record: any) => {
				const titles = record.meta_titles;
				if (titles?.length) {
					const defaultTitle = titles[0].title;
					const englishTitle = titles.filter((t: any) => t.language === "English")?.[0]?.["title"];
					return <TextWithCopyButton value={englishTitle ?? defaultTitle} />;
				}
				return null;
			},
		},
		{
			title: "Internal Rating",
			dataIndex: "internal_rating",
			key: "internal_rating",
		},
		{
			title: "Status Code",
			dataIndex: "status",
			key: "status",
		},
		{
			title: "Air Date",
			dataIndex: "original_air_date",
			key: "original_air_date",
			sorter: (a: any, b: any) => moment(a.original_air_date).diff(moment(b.original_air_date)),
			render: value => {
				return (
					<span>
						{value?.toString()?.length ? moment(value, "YYYY-MM-DD").format("MM/DD/YYYY") : "-"}
					</span>
				);
			},
		},
		{
			title: "Rating US",
			dataIndex: "rating_us",
			key: "rating_us",
		},
		{
			title: "Rating AU",
			dataIndex: "rating_au",
			key: "rating_au",
		},
		{
			title: "Rating Canada",
			dataIndex: "rating_canada",
			key: "rating_canada",
		},
		{
			title: "Episode #",
			dataIndex: "episode",
			key: "episode_number",
			sorter: (a: any, b: any) => a.episode - b.episode,
		},
		{
			title: "Season #",
			dataIndex: "season",
			key: "season_number",
			sorter: (a: any, b: any) => a.season - b.season,
		},
		{
			title: "Series Title",
			dataIndex: "series_titles",
			key: "series_titles",
		},
		{
			title: "Tags",
			dataIndex: "episode_tag_titles",
			key: "episode_tag_titles",
			render: (value: string) => {
				return value?.length
					? value?.split(",")?.map(tag => (
							<Tag key={tag} color="success" title={tag}>
								{tag}
							</Tag>
					  ))
					: null;
			},
		},
		{
			title: "Internal Notes",
			dataIndex: "internal_notes",
			key: "internal_notes",
			align: "center",
			render: (value: string) => <DescriptionText text={value} />,
		},
		{
			title: "Action",
			dataIndex: "action",
			key: "action",
			align: "right",
			render: (value, record: any) => {
				return (
					<Fragment>
						<Link to={`/episode/${record.ref_id}`}>
							<CustomBtn
								onClick={e => {
									if (e.metaKey || e.ctrlKey) {
										e.stopPropagation();
									}
									sessionStorage.setItem("episodeFilter", JSON.stringify(episodeFilterObj));
								}}
								icon={<EditOutlined />}
								type={"link"}
							/>
						</Link>
						<DeletePopConfirm
							title="Delete Episode?"
							deleteHandler={() => deleteEpisodeData(record)}
						>
							Are you sure you want to delete this episode data? This will delete this episode and
							all the videos related to the episode.
						</DeletePopConfirm>
					</Fragment>
				);
			},
		},
	];
	return columnEpisode;
};

export const getCaptionDataColumns = (
	deleteCaptionData: (id: string) => void,
	editCaptionHelper: (record: any) => void,
	loading: boolean
) => {
	const captionDataColumns: ColumnsType<object> = [
		{
			...refIdColumn,
			width: "90px",
		},
		{
			title: "Language",
			dataIndex: "language",
			key: "language",
			width: "100px",
		},
		{
			title: "Offset Time",
			dataIndex: "offset_time",
			key: "offset_time",
			width: "100px",
		},
		{
			title: "Caption Type",
			dataIndex: "caption_type",
			key: "caption_type",
			width: "100px",
		},
		{
			title: "URL",
			dataIndex: "url",
			key: "url",
			width: "220px",
		},
		{
			title: "Distribution Platforms",
			dataIndex: "caption_distribution_names",
			key: "caption_distribution_names",
			width: "220px",
			render: (value: any) => {
				return value
					? value?.split(",").map((title: string) => (
							<Tag key={title} title={title}>
								{title}
							</Tag>
					  ))
					: "";
			},
		},
		{
			title: "Action",
			dataIndex: "action",
			key: "action",
			align: "right",
			width: "80px",
			render: (value, record: any) => {
				return (
					<Fragment>
						<CustomBtn
							onClick={e => {
								e.stopPropagation();
								editCaptionHelper(record);
							}}
							icon={<EditOutlined />}
							type={"link"}
						/>
						<DeletePopConfirm
							title="Delete caption?"
							deleteHandler={() => deleteCaptionData(record.id)}
						>
							Are you sure you want to delete this caption?
						</DeletePopConfirm>
					</Fragment>
				);
			},
		},
	];
	return captionDataColumns;
};

export const getEpisodeVideColumns = (
	editVideoHelper: (record: any) => void,
	deleteVideoData: (record: any) => void,
	loading: boolean,
	playVideo: (record: any) => void
) => {
	const episodeVideoColumns: ColumnsType<object> = [
		{
			title: "Title",
			dataIndex: "title",
			key: "title",
			width: "200px",
		},
		{
			title: "File Name",
			dataIndex: "file_name",
			key: "file_name",
			width: "200px",
			className: "video-url",
			render: (value: string, record: any) => {
				return (
					<span
						onClick={e => {
							e.stopPropagation();
							if (record.url?.trim()?.length) playVideo(record);
						}}
					>
						<PlayVideoIcon />
						{value}
					</span>
				);
			},
		},
		{
			title: "Audience Language",
			dataIndex: "audience_language",
			key: "audience_language",
			width: "160px",
		},
		{
			title: "Distribution Platforms",
			dataIndex: "distribution_data",
			key: "distribution_data",
			width: "220px",
			render: (value: any) => {
				return value.map((platformData: any) => (
					<Tag key={platformData.id} title={platformData.platform_name}>
						{platformData.platform_name}
					</Tag>
				));
			},
		},
		{
			title: "Tags",
			dataIndex: "tags",
			key: "tags",
			width: "200px",
			render: (value: any) => {
				return value
					? value?.split(",").map((title: string) => (
							<Tag key={title} color="success" title={title}>
								{title}
							</Tag>
					  ))
					: "";
			},
		},
		{
			title: "Action",
			dataIndex: "action",
			key: "action",
			align: "right",
			width: "80px",
			render: (value, record: any) => {
				return (
					<Fragment>
						<CustomBtn
							onClick={e => {
								e.stopPropagation();
								editVideoHelper(record);
							}}
							icon={<EditOutlined />}
							type={"link"}
						/>
						<DeletePopConfirm title="Delete Video?" deleteHandler={() => deleteVideoData(record)}>
							Are you sure you want to delete this video?
						</DeletePopConfirm>
					</Fragment>
				);
			},
		},
	];
	return episodeVideoColumns;
};
