import { Button, Col, Row, Spin, message } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import React, { Dispatch, SetStateAction, useState } from "react";
import { authAxios } from "../../../utils/session_utils";
import { BulkAddDataModal, VideoSearchItem } from "../../../custom-components";
import { Checkbox } from "antd/lib";
import { SearchOutlined } from "@ant-design/icons";
import { SelectedVideoData } from "../../videos";
import { GetVideosDataHandler } from "../../hooks/useVideosHooks";

interface BulkVideosTaggingModalInterface {
	isBulkTaggingModalOpen: boolean;
	setIsBulkTaggingModalOpen: Dispatch<SetStateAction<boolean>>;
	tagsList: any[];
	selectedVideoData: SelectedVideoData[];
	getVideosDataHandler: (params: GetVideosDataHandler) => void;
}

const BulkVideosTaggingModal: React.FC<BulkVideosTaggingModalInterface> = ({
	isBulkTaggingModalOpen,
	setIsBulkTaggingModalOpen,
	tagsList,
	selectedVideoData,
	getVideosDataHandler,
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [searchText, setSearchText] = useState<string>("");
	const [tagCheckedList, setTagCheckedList] = useState<string[]>([]);
	tagsList = tagsList.filter(tag => tag.related_to.includes("video"));
	const tagListPlainOptions = tagsList.map(list => ({
		value: list.id,
		label: list.name,
	}));
	const tagListIds = tagsList.map(list => list.id);
	const checkAll = tagListPlainOptions.length === tagCheckedList.length;
	const indeterminate =
		tagCheckedList.length > 0 && tagCheckedList.length < tagListPlainOptions.length;

	const tagListChange = (list: string[]) => {
		setTagCheckedList(list);
	};

	const onTagCheckAllChange = (e: CheckboxChangeEvent) => {
		setTagCheckedList(e.target.checked ? tagListIds : []);
	};

	const tagBulkVideos = async () => {
		try {
			setIsLoading(true);
			const data = {
				video_ids: selectedVideoData.map(videoData => videoData.id),
				tag_ids: tagCheckedList,
			};
			const config = {
				method: "POST",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/bulk/add-video-tags`,
				data: JSON.stringify(data),
				headers: { "Content-Type": "application/json" },
			};
			const response = await authAxios(config);
			message.success(response.data.message);
			await getVideosDataHandler({});
			setIsLoading(false);
			closeHandler();
		} catch (error) {
			setIsLoading(false);
			message.error("Could not save video list");
			console.error("error addBulkVideosToList", error);
		}
	};

	const untagBulkVideos = async () => {
		try {
			setIsLoading(true);
			const data = {
				video_ids: selectedVideoData.map(videoData => videoData.id),
				tag_ids: tagCheckedList,
			};
			const config = {
				method: "POST",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/bulk/remove-video-tags`,
				data: JSON.stringify(data),
				headers: { "Content-Type": "application/json" },
			};
			const response = await authAxios(config);
			message.success(response.data.message);
			await getVideosDataHandler({});
			setIsLoading(false);
			closeHandler();
		} catch (error) {
			setIsLoading(false);
			message.error("Selected videos could not be removed from selected lists.");
			console.error("error removeBulkVideosFromList", error);
		}
	};

	const closeHandler = () => {
		setIsBulkTaggingModalOpen(false);
		setTagCheckedList([]);
	};

	return (
		<BulkAddDataModal
			open={isBulkTaggingModalOpen}
			onCancel={closeHandler}
			title="Add or Remove Video Tag(s):"
			centered
			footer={
				<>
					<Button onClick={untagBulkVideos} disabled={!tagCheckedList?.length || isLoading} danger>
						Remove
					</Button>
					<Button
						onClick={tagBulkVideos}
						disabled={!tagCheckedList?.length || isLoading}
						type="primary"
					>
						Add
					</Button>
				</>
			}
		>
			<Spin spinning={isLoading}>
				<VideoSearchItem
					placeholder="Search Tags"
					onChange={e => setSearchText(e.target.value)}
					suffix={<SearchOutlined />}
				/>
				{!searchText && (
					<Checkbox indeterminate={indeterminate} onChange={onTagCheckAllChange} checked={checkAll}>
						{checkAll ? "De-Select All" : "Select All"}
					</Checkbox>
				)}
				<Checkbox.Group onChange={tagListChange} value={tagCheckedList}>
					<Row gutter={[0, 4]}>
						{tagsList
							.filter(tag =>
								searchText ? tag.title.toLowerCase().includes(searchText?.toLowerCase()) : true
							)
							.map((tag: any) => {
								return (
									<Col span={24} key={tag.key}>
										<Checkbox value={tag.id} key={tag.key}>
											<b>{tag.title}</b>
										</Checkbox>
									</Col>
								);
							})}

						{/* {searchText?.trim()?.length ? (
							<CreateNewListCol onClick={createNewVideoList} span={24}>
								<span>
									<b>&quot;{searchText}&quot;</b>
								</span>{" "}
								&nbsp;
								<span>(Create new)</span>
							</CreateNewListCol>
						) : null} */}
					</Row>
				</Checkbox.Group>
			</Spin>
		</BulkAddDataModal>
	);
};

BulkVideosTaggingModal.displayName = "VideosTaggingModal";
export default BulkVideosTaggingModal;
