import React, { Dispatch, FC, SetStateAction } from "react";
import { Modal, Radio, RadioChangeEvent, Space, Typography } from "antd";

type CuePointExportModalProps = {
	isOpen: boolean;
	exportCuePoints: () => void;
	closeCuePointsExportModal: () => void;
	setCuePointTimeFormat: Dispatch<SetStateAction<string>>;
	cuePointTimeFormat: string;
};

const CuePointExportModal: FC<CuePointExportModalProps> = ({
	isOpen,
	exportCuePoints,
	closeCuePointsExportModal,
	setCuePointTimeFormat,
	cuePointTimeFormat,
}) => {
	const onChange = (e: RadioChangeEvent) => {
		setCuePointTimeFormat(e.target.value);
	};
	return (
		<Modal
			centered
			open={isOpen}
			onOk={exportCuePoints}
			onCancel={closeCuePointsExportModal}
			okText="Export"
			title="Export Cue Points"
			maskClosable={false}
		>
			<Typography.Title level={4}>Time Format</Typography.Title>
			<Radio.Group onChange={onChange} value={cuePointTimeFormat}>
				<Space direction="vertical">
					<Radio value="HH:mm:ss">HH:MM:SS</Radio>
					<Radio value="HH:mm:ss;frames">HH:MM:SS;frames</Radio>
					<Radio value="HH:mm:ss.SSS">HH:MM:SS.milliseconds</Radio>
					<Radio value="seconds">Seconds</Radio>
				</Space>
			</Radio.Group>
		</Modal>
	);
};

export default CuePointExportModal;
