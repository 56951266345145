// helpers.ts
import { message } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { InvoiceDataType } from '../../../utils/common.interface';
import { getUploadSignedURL } from '../../../utils/index';
import { authAxios } from "../../../utils/session_utils";

export const handleInputChange = (
  value: any,
  key: string,
  column: string,
  dataSource: InvoiceDataType[],
  setDataSource: React.Dispatch<React.SetStateAction<InvoiceDataType[]>>
) => {
  const newData = dataSource.map(item => (item.key === key ? { ...item, [column]: value } : item));
  setDataSource(newData);
};

export const handleAddLine = (
  dataSource: InvoiceDataType[],
  setDataSource: React.Dispatch<React.SetStateAction<InvoiceDataType[]>>
) => {
  const newLine: InvoiceDataType = {
    key: `${dataSource.length + 1}`,
    partner: '',
    paymentDate: '',
    channel: '',
    territory: '',
    platform: '',
    domain: '',
    label: '',
    registerName: '',
    amount: '',
    selected: false,
    allocationStrategy: {
      platform: true,
      partner: true,
      channel: true,
      territory: true,
      customAllocation: {}
    }, 
    allocationLevel: '',
    allocationPeriod: '',
    linkedViewershipPool: '',
    linkedToViewership: false,
  };
  setDataSource([...dataSource, newLine]);
};

export const handleRemoveLine = (
  selectedRowKeys: React.Key[],
  dataSource: InvoiceDataType[],
  setDataSource: React.Dispatch<React.SetStateAction<InvoiceDataType[]>>,
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>
) => {
  const newData = dataSource.filter(item => !selectedRowKeys.includes(item.key));
  setDataSource(newData);
  setSelectedRowKeys([]);
};

export const handleSelectAll = (
  dataSource: InvoiceDataType[],
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>
) => {
  const allKeys = dataSource.map(item => item.key);
  setSelectedRowKeys(allKeys);
};

export const handleS3InvoiceUpload = async (
  file: UploadFile,
  url: string,
  fileName: string
) => {
  const response = await fetch(url, {
    method: 'PUT',
    body: file.originFileObj,
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to upload file');
  }

};

export const handleFileChange = async (
  { file, fileList }: { file: UploadFile; fileList: UploadFile[] },
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>,
  setCurrentFile: React.Dispatch<React.SetStateAction<UploadFile | null>>,
  setRenamedFile: React.Dispatch<React.SetStateAction<string>>,
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setUploadUrls: React.Dispatch<React.SetStateAction<{ [key: string]: { url: string; fileId: string } }>>,
  setFileId: React.Dispatch<React.SetStateAction<string | null>>
) => {
  setFileList(fileList);
  setCurrentFile(file);
  setRenamedFile(file.name);
  setIsModalVisible(true);

  try {
    const response = await getUploadSignedURL('application/octet-stream', file.name); // Fetch presigned URL with original file name
    const { url, fileId } = response;
    setUploadUrls(prev => ({ ...prev, [file.uid]: { url, fileId } }));
    setFileId(fileId);
  } catch (error) {
    console.error('Error fetching pre-signed URL:', error);
  }
};


export const handleFileSelect = (
  fileName: string,
  fileId: string,
  key: string,
  dataSource: InvoiceDataType[],
  setDataSource: React.Dispatch<React.SetStateAction<InvoiceDataType[]>>
) => {
  setDataSource(prevDataSource =>
    prevDataSource.map(item =>
      item.key === key
        ? { ...item, fileName: fileName, fileId: fileId }
        : item
    )
  );
};

export const handleSaveFile = async (
  currentFile: UploadFile | null,
  uploadUrls: { [key: string]: { url: string; fileId: string } },
  renamedFile: string,
  fileList: UploadFile[],
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>,
  renamedFiles: { [key: string]: string },
  setRenamedFiles: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>,
  dataSource: InvoiceDataType[],
  setDataSource: React.Dispatch<React.SetStateAction<InvoiceDataType[]>>,
  uploadedFiles: { [key: string]: boolean },
  setUploadedFiles: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>,
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const file = currentFile;
  if (!file) return;

  const { url, fileId } = uploadUrls[file.uid];
  const finalFileName = `${fileId}-${renamedFile}`;

  try {
    // Check if file was already uploaded
    if (!uploadedFiles[file.uid]) {
      await handleS3InvoiceUpload(file, url, finalFileName); // Ensure the renamed file is used for uploading
      message.success('File uploaded successfully');
      setUploadedFiles(prev => ({ ...prev, [file.uid]: true })); // Mark file as uploaded
    }

    const newFileList = fileList.map(f =>
      f.uid === file.uid ? { ...f, name: renamedFile } : f // Update name to just renamedFile for display
    );
    setFileList(newFileList);

    const newRenamedFiles = { ...renamedFiles, [file.uid]: renamedFile };
    setRenamedFiles(newRenamedFiles);

    const newDataSource = dataSource.map(item =>
      item.key === file.uid ? { ...item, fileName: renamedFile, fileId } : item 
    );
    setDataSource(newDataSource);

  } catch (error) {
    message.error('Error uploading file');
  }

  setIsModalVisible(false);
};




export const handleUploadData = async (
  dataSource: InvoiceDataType[],
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>,
  setUploadStatus: React.Dispatch<React.SetStateAction<string>>,
  setOperationId: React.Dispatch<React.SetStateAction<string | null>>,
  setShowUploadView: React.Dispatch<React.SetStateAction<boolean>>,
  setViewershipPool: React.Dispatch<React.SetStateAction<any>>,
  setIsProgressModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  setIsErrorModalVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsUploading(true);
  setUploadStatus('initiating');
  setIsProgressModalVisible(true);
  
  try {
    const { operationId } = await uploadInvoiceData(dataSource);
    setOperationId(operationId);
    await pollUploadStatus(operationId, setUploadStatus, setIsUploading, setShowUploadView, setViewershipPool, setIsProgressModalVisible, setErrorMessage, setIsErrorModalVisible);
  } catch (error) {
    console.error('Error uploading data:', error);
    setIsUploading(false);
    setUploadStatus('error');
    setIsProgressModalVisible(false);
    setErrorMessage(error instanceof Error ? error.message : 'An unknown error occurred');
  }
};

// In your API utility file (e.g., api.ts)
export const uploadInvoiceData = async (dataSource: InvoiceDataType[]) => {
  const response = await authAxios({
    method: 'POST',
    url: `${process.env.REACT_APP_BACK_END_API}/snowflake/invoice_upload`,
    data: dataSource,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};


export const pollOperationStatus = async (operationId: string) => {
  try {
    const response = await authAxios({
      method: 'GET',
      url: `${process.env.REACT_APP_BACK_END_API}/snowflake/payment-upload-job-status/${operationId}`,
    });
    
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      return { status: 'error', message: error.message };
    } else {
      return { status: 'error', message: 'An unknown error occurred' };
    }
  }
};


export const pollUploadStatus = async (
  operationId: string,
  setUploadStatus: (status: string) => void,
  setIsUploading: (isUploading: boolean) => void,
  setShowUploadView: (show: boolean) => void,
  setViewershipPool: React.Dispatch<React.SetStateAction<any>>,
  setIsProgressModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  setIsErrorModalVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const pollInterval = 2000; 
  const maxAttempts = 30;
  let attempts = 0;

  console.log('polling...');
  console.log('op id: ', operationId);

  const poll = async () => {
    try {
      const data = await pollOperationStatus(operationId);
      console.log('Received data:', data);  

      setUploadStatus(data.status);

      switch (data.status) {
        case 'processing':
        case 'uploaded':
        case 'normalized':
        case 'checking_for_dupes':
          attempts++;
          if (attempts < maxAttempts) {
            setTimeout(poll, pollInterval);
          } else {
            handleErrorState('Operation timed out');
          }
          break;
        case 'viewership_fetched':
          setUploadStatus('viewership_fetched');
          setIsUploading(false);
          setShowUploadView(false);
          setViewershipPool(data.data);
          setIsProgressModalVisible(false);
          break;
        case 'error':
          handleErrorState(data.message || 'An error occurred during the operation');
          break;
        default:
          console.log(`Unexpected status: ${data.status}, continuing to poll`);
          attempts++;
          if (attempts < maxAttempts) {
            setTimeout(poll, pollInterval);
          } else {
            handleErrorState(`Polling stopped after ${maxAttempts} attempts. Last status: ${data.status}`);
          }
      }
    } catch (error) {
      console.error('Error during polling:', error);
      attempts++;
      if (attempts < maxAttempts) {
        console.log(`Polling attempt ${attempts} failed. Retrying...`);
        setTimeout(poll, pollInterval);
      } else {
        handleErrorState('Polling failed after maximum attempts');
      }
    }
  };

  const handleErrorState = (errorMsg: string) => {
    setUploadStatus('error');
    setIsUploading(false);
    setIsProgressModalVisible(false);
    setErrorMessage(errorMsg);
    setIsErrorModalVisible(true);
  };

  poll();
};