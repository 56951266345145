import {
	Button,
	Checkbox,
	Col,
	Form,
	FormInstance,
	Input,
	Modal,
	Row,
	Select,
	Spin,
	Typography,
	message,
} from "antd";
import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import { getDigitsOnly, tagsFilterOptions } from "../../utils";
import { probeThumbnail } from "../../utils/video_api";
import { DistributionDataPropsTypes } from "../../episode";
import { AddTagInputContainer } from "../../videos/modals/AddVideosMetaDataForm";
import { TagOption } from "../../tags/hooks/utils";
import { CloseIcon } from "../../custom-components";

const ThumbnailModal = styled(Modal)`
	& #thumbnails_distribution {
		max-height: calc(100vh - 218px);
		overflow-y: auto;
		overflow-x: hidden;
	}
`;

interface Props extends DistributionDataPropsTypes {
	thumbnailEditId: string | undefined | null;
	isOpen: boolean;
	saveEditThumbnailData: () => void;
	handleCancel: () => void;
	isLoading: boolean;
	form: FormInstance;
	distributionPlatformData: any[];
	tagsList: any[];
	isFromSeries?: boolean;
	handleAddCustomTag: (
		inputValue: string,
		related_to: string[],
		handler: (id: string) => void
	) => void;
}

const ThumbnailFormModal: FC<Props> = ({
	thumbnailEditId,
	isOpen,
	saveEditThumbnailData,
	handleCancel,
	isLoading,
	form,
	distributionPlatformData,
	distributionCheckedList,
	distributionChange,
	onDistributionCheckAllChange,
	checkAll,
	indeterminate,
	tagsList,
	handleAddCustomTag,
	isFromSeries,
}) => {
	const [inputValue, setInputValue] = useState<string>("");
	const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);
	tagsList = tagsList.filter(tag => tag.related_to.includes("thumbnail"));
	const thumbnailDetailsHandler = async (e: any) => {
		// return;
		const thumbnailURL = e.target.value;

		if (thumbnailURL) {
			message.info("Fetching thumbnail details. Keep updating other data.");
			const response = await probeThumbnail(thumbnailURL);
			const result = response.result;
			// console.log(response);
			if (response.message === "success") {
				form.setFieldsValue({
					thumbnail_file_name: result.name,
					thumbnail_width: result.metadata.width,
					thumbnail_height: result.metadata.height,
				});
				message.success("Thumbnail details fetched successfully");
			} else {
				message.error(result.error ?? "Fetching thumbnail details failed: " + result.error);
				form.resetFields(["thumbnail_file_name", "thumbnail_width", "thumbnail_height"]);
			}
		} else {
			form.resetFields(["thumbnail_file_name", "thumbnail_width", "thumbnail_height"]);
		}
	};

	return (
		<ThumbnailModal
			centered
			width={600}
			open={isOpen}
			maskClosable={false}
			title={thumbnailEditId ? "Edit Thumbnail" : "Add Thumbnail"}
			okText={thumbnailEditId ? "Update" : "Add"}
			onOk={saveEditThumbnailData}
			onCancel={handleCancel}
			okButtonProps={{ disabled: isLoading }}
			cancelButtonProps={{ disabled: isLoading }}
		>
			<Spin spinning={isLoading}>
				<Form form={form} requiredMark={false} layout="vertical">
					<Row gutter={[16, 0]}>
						<Col span={16}>
							{!isFromSeries && (
								<Form.Item label="Ref Id" name="episode_ref_id">
									<Input style={{ width: "220px" }} disabled />
								</Form.Item>
							)}
							<Form.Item
								label="URL"
								name="thumbnail_url"
								rules={[{ required: true, message: "Required Field" }]}
							>
								<Input
									onChange={e => {
										clearTimeout(timeoutId);
										const newTimeoutId = setTimeout(async () => {
											thumbnailDetailsHandler(e);
										}, 1000);
										setTimeoutId(newTimeoutId);
									}}
									placeholder="Thumbnail URL"
								/>
							</Form.Item>
							<Form.Item label="File Name" name="thumbnail_file_name">
								<Input placeholder="File Name" />
							</Form.Item>
							<Form.Item name="thumbnail_tags" label="Thumbnail Tags">
								<Select
									allowClear={{ clearIcon: <CloseIcon /> }}
									placeholder="Thumbnail Tags"
									mode="multiple"
									filterOption={tagsFilterOptions}
									dropdownRender={menu => (
										<>
											<AddTagInputContainer>
												<Input
													value={inputValue}
													onChange={e => setInputValue(e.target.value)}
													style={{ width: "100%" }}
													placeholder="Add new thumbnail tag"
												/>
												<Button
													onClick={() => {
														if (!inputValue?.length) message.error("Please enter a value!");
														else console.log("p");
														handleAddCustomTag(inputValue, ["thumbnail"], () => {
															setInputValue("");
														});
													}}
												>
													Add
												</Button>
											</AddTagInputContainer>
											{menu}
										</>
									)}
								>
									{tagsList.map((tag: TagOption) => (
										<Select.Option value={tag.id} key={tag.id}>
											{tag.title}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Row gutter={[8, 8]}>
								<Col span={12}>
									<Form.Item label="Width (Pixels)" name="thumbnail_width">
										<Input
											placeholder="Width(in pixels)"
											onChange={e =>
												form.setFieldValue("thumbnail_width", getDigitsOnly(e.target.value))
											}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label="Height (Pixels)" name="thumbnail_height">
										<Input
											placeholder="Height(in pixels)"
											onChange={e =>
												form.setFieldValue("thumbnail_height", getDigitsOnly(e.target.value))
											}
										/>
									</Form.Item>
								</Col>
							</Row>
						</Col>
						<Col span={8}>
							<Typography.Title level={3}>Distribution Platforms</Typography.Title>
							<Checkbox
								indeterminate={indeterminate}
								onChange={onDistributionCheckAllChange}
								checked={checkAll}
							>
								{checkAll ? "De-Select All" : "Select All"}
							</Checkbox>
							<Checkbox.Group onChange={distributionChange} value={distributionCheckedList}>
								<Row id="thumbnails_distribution">
									{distributionPlatformData.map((platform: any) => {
										return (
											<Col span={24} key={platform.key}>
												<Checkbox value={platform.id} key={platform.key}>
													{platform.name}
												</Checkbox>
											</Col>
										);
									})}
								</Row>
							</Checkbox.Group>
						</Col>
					</Row>
				</Form>
			</Spin>
		</ThumbnailModal>
	);
};

export default ThumbnailFormModal;
