import React, { FC } from "react";
import { Button, Card, Col, Row, Typography, Upload } from "antd";
import styled from "@emotion/styled";
import { UploadOutlined } from "@ant-design/icons";
// import { getEmailFromSessionStorage } from "../utils/session_utils";
import useAutoPodderUploadHook from "./hooks/usePostLogUploadHook";
import { TaskRow } from "../zype/styled-components";
import { ComponentTitle, StledSpin } from "../custom-components";
import { TabComponentProps } from "../utils/common.interface";

const { Dragger } = Upload;

export const StyledDragger = styled(Dragger)`
	padding: 10px;
	& .ant-upload-drag {
		padding: 10px;
	}
`;

const PostLogProcessorComponent: FC<TabComponentProps> = () => {
	const { loading, handleUpload, uploadProps } = useAutoPodderUploadHook();
	// const decodedEmail = getEmailFromSessionStorage();
	return (
		<StledSpin spinning={loading}>
			<Row justify="center">
				<Col>
					<ComponentTitle>Upload PostLog CSV File</ComponentTitle>
				</Col>
			</Row>
			<TaskRow>
				<Card
					actions={[
						<Button key="import-btn" type="primary" onClick={handleUpload}>
							Submit
						</Button>,
					]}
				>
					<StyledDragger {...uploadProps}>
						<Typography.Paragraph>Please select a CSV file</Typography.Paragraph>
						<Button icon={<UploadOutlined />}>Select File</Button>
					</StyledDragger>
					{/* <Form.Item rules={[{ required: true, message: "Required Field" }]}>
						<Input name="toEmailInput" placeholder="Email address" value={decodedEmail}/>
					</Form.Item> */}
				</Card>
			</TaskRow>
		</StledSpin>
	);
};

export default PostLogProcessorComponent;
