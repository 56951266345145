import { FormInstance } from "rc-field-form";
import { Dispatch, SetStateAction } from "react";
import { authAxios } from "../../utils/session_utils";
import { message } from "antd";
import { setDistributionFormItems } from "../../captions/hooks/utils";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { getFileNameWithURL } from "../../videos/modals/AddVideosMetaDataForm";

export const editThumbnailHelper = (
	record: any,
	form: FormInstance,
	setThumbnailEditId: Dispatch<SetStateAction<string | undefined | null>>,
	setIsThumbnailFormOpen: Dispatch<SetStateAction<boolean>>,
	setDistributionCheckedList: Dispatch<SetStateAction<CheckboxValueType[]>>,
	setEpisodeId?: Dispatch<SetStateAction<string | undefined | null>>
) => {
	const { ref_id, episode_id, id, height, width, url, thumbnails_distribution_ids, thumbnail_tag_ids, file_name } =
		record;
	setEpisodeId && setEpisodeId(episode_id);
	setThumbnailEditId(id);
	setIsThumbnailFormOpen(true);
	const distributionIds = setDistributionFormItems(thumbnails_distribution_ids);
	setDistributionCheckedList(distributionIds);
	form.setFieldsValue({
		episode_ref_id: ref_id,
		thumbnail_url: url,
		thumbnail_height: height,
		thumbnail_width: width,
		thumbnail_tags: thumbnail_tag_ids
			?.split(",")
			?.filter((tag: string) => tag?.trim())
			?.map((tag: string) => tag),
		thumbnail_file_name: file_name ? file_name : getFileNameWithURL(url),
	});
};

export const saveUpdateThumbnails = async (
	values: any,
	episode_id: string | null | undefined,
	distributionCheckedList: CheckboxValueType[],
	editId?: string | null
) => {
	try {
		const { thumbnail_url, thumbnail_height, thumbnail_width, thumbnail_tags, thumbnail_file_name } = values;
		const data: any = {
			thumbnailsData: {
				height: thumbnail_height ?? 0,
				width: thumbnail_width ?? 0,
				url: thumbnail_url,
				episode_id,
				thumbnail_tags: thumbnail_tags ?? [],
				file_name: thumbnail_file_name ? thumbnail_file_name : getFileNameWithURL(thumbnail_url),
				thumbnails_distribution: distributionCheckedList,
			},
		};
		if (editId) {
			data["thumbnailsData"]["id"] = editId;
		}
		const config = {
			method: editId ? "PUT" : "POST",
			url: `${process.env.REACT_APP_BACK_END_API}/snowflake/thumbnails`,
			headers: {
				"Content-Type": "application/json",
			},
			data: JSON.stringify(data),
		};
		const response = await authAxios(config);
		if (response.status === 200) {
			message.success(response.data.message);
		}
		return response;
	} catch (error) {
		message.error("Something went wrong");
		console.error({ error });
	}
};

export const deleteThumbnail = async (id: string) => {
	const config = {
		method: "DELETE",
		url: `${process.env.REACT_APP_BACK_END_API}/snowflake/thumbnails/${id}`,
	};
	const response = await authAxios(config);
	return response;
};
