import styled from "@emotion/styled";
import { Button, DatePicker, Row, Table } from "antd";

export const CustomTable = styled(Table)`
	& .ant-table {
		overflow-x: auto;
	}
	& .ant-table-body {
		tr {
			cursor: pointer !important;
		}
		tr:hover td {
			background-color: #c3c5c330 !important;
		}
		td {
			padding: 8px !important;
		}
	}
	td.ant-table-cell.video-url {
		/* white-space: nowrap; */
		span {
			/* color: #47cb38 !important; */
		}
	}
`;
export const CustomBtn = styled(Button)`
	border: none !important;
`;

export const SeriesFormRow = styled(Row)`
	max-width: 472px !important;
`;

export const StyledDatePicker = styled(DatePicker)`
	width: 100%;
`;