import styled from "@emotion/styled";
import { Form, FormInstance, Input, Modal } from "antd";
import React from "react";

const { Item } = Form;

type Props = {
	isModalOpen: boolean;
	form: FormInstance;
	saveDistributionPlatformData: () => void;
	cancelFormHandler: () => void;
};

const DistributionPlatformModal = styled(Modal)`
	& .ant-modal-title {
		margin-bottom: 12px;
	}
`;

const DistributionPlatformEditModal: React.FC<Props> = ({
	form,
	isModalOpen,
	saveDistributionPlatformData,
	cancelFormHandler,
}) => {
	return (
		<DistributionPlatformModal
			title="Edit Distribution Platform Data"
			open={isModalOpen}
			onCancel={cancelFormHandler}
			onOk={saveDistributionPlatformData}
		>
			<Form form={form} requiredMark={false}>
				<Item name="name">
					<Input placeholder="Partner Name" />
				</Item>
			</Form>
		</DistributionPlatformModal>
	);
};

export default DistributionPlatformEditModal;
