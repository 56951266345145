import { message } from "antd";
import { useState } from "react";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { authAxios, getEmailFromSessionStorage } from "../../utils/session_utils";

const useAutoPodderUploadHook = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [redirectToBatchJobs, setRedirectToBatchJobs] = useState<boolean>(false);
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [file, setFile] = useState<any>(null);

	const handleFileRemove = () => {
		setFile(null);
		const index = fileList.indexOf(file);
		const newFileList = fileList.slice();
		newFileList.splice(index, 1);
		setFileList(newFileList);
		return true;
	};

	const beforeUpload = (file: any) => {
		// console.log("beforeUpload", { fileList, file });
		fileList.push(file);
		setFileList(fileList);
		setFile(file);
		return false;
	};

	const uploadProps: UploadProps = {
		accept: ".csv",
		onRemove: handleFileRemove,
		beforeUpload: beforeUpload,
		fileList,
	};

	const handleUpload = async () => {
		// access input element and get value from parent component
		// const toEmailInput = document.querySelector("input[name=toEmailInput]") as HTMLInputElement;
		const weight_300 = document.querySelector("input[name=weight_300]") as HTMLInputElement;
		const weight_180 = document.querySelector("input[name=weight_180]") as HTMLInputElement;
		const weight_120 = document.querySelector("input[name=weight_120]") as HTMLInputElement;
		const weight_90 = document.querySelector("input[name=weight_90]") as HTMLInputElement;
		const weight_60 = document.querySelector("input[name=weight_60]") as HTMLInputElement;
		const weight_45 = document.querySelector("input[name=weight_45]") as HTMLInputElement;
		const weight_30 = document.querySelector("input[name=weight_30]") as HTMLInputElement;
		const weight_15 = document.querySelector("input[name=weight_15]") as HTMLInputElement;
		const max_cascade_seconds = document.querySelector("input[name=max_cascade_seconds]") as HTMLInputElement;

		const toEmail = getEmailFromSessionStorage();
		// if email is empty or domain part is not mvmediasales.com then add error
		if (toEmail?.length === 0 || !toEmail?.endsWith("mvmediasales.com")) {
			message.error("Please enter a valid mvmediasales email address");
		} else if (fileList.length !== 2) {
			message.error("Please upload exactly 2 files, 1 for schedule and 1 for structure");
		} else {
			setLoading(true);
			const formData = new FormData();
			formData.append("file1", fileList[0] as RcFile);
			formData.append("file2", fileList[1] as RcFile);
			formData.append("toEmail", toEmail);
			formData.append("weight_300", weight_300.value);
			formData.append("weight_180", weight_180.value);
			formData.append("weight_120", weight_120.value);
			formData.append("weight_90", weight_90.value);
			formData.append("weight_60", weight_60.value);
			formData.append("weight_45", weight_45.value);
			formData.append("weight_30", weight_30.value);
			formData.append("weight_15", weight_15.value);
			formData.append("max_cascade_seconds", max_cascade_seconds.value);
			formData.append("func", "autopodder");

			try {
				const config = {
					method: "POST",
					url: `${process.env.REACT_APP_BACK_END_API}/auto-podder/upload`,
					data: formData,
					headers: {
						"Content-Type": "multipart/form-data",
					},
				};
				const response = await authAxios(config);
				if (!response.data.err) {
					message.success(response.data.message);
					setFile(null);
					setFileList([]);
					setRedirectToBatchJobs(true);
				} else {
					message.error("Something went wrong");
				}
				setLoading(false);

				// console.log(response.data); // Handle the response from the Node.js app
			} catch (error) {
				setLoading(false);
				console.error(error);
			}
		}
	};
	return { loading, file, handleUpload, uploadProps, redirectToBatchJobs};
};

export default useAutoPodderUploadHook;
