import React, { useState, useEffect } from 'react';
import { Modal, Progress, Typography } from 'antd';
const { Text } = Typography;

const uploadStatusSteps = [
  { status: 'initiating', text: 'Initiating upload', progress: 0 },
  { status: 'checking_for_dupes', text: 'Checking for duplicates', progress: 10 },
  { status: 'processing', text: 'Uploading data', progress: 30 },
  { status: 'uploaded', text: 'Normalizing data', progress: 60 },
  { status: 'normalized', text: 'Fetching viewership pools', progress: 80 },
  { status: 'viewership_fetched', text: 'Complete', progress: 100 },
];

const allocationStatusSteps = [
  { status: 'allocation_started', text: 'Initiating allocation', progress: 0 },
  { status: 'pre_processing', text: 'Pre-processing data', progress: 20 },
  { status: 'checking_rollup_table_for_dupes', text: 'Checking for duplicates', progress: 40 },
  { status: 'performing_rollups', text: 'Performing rollups', progress: 60 },
  { status: 'preparing_lambda_payloads', text: 'Preparing data for processing', progress: 80 },
  { status: 'invoking_lambda', text: 'Processing allocation', progress: 90 },
  { status: 'allocation_completed', text: 'Allocation dispatched to Lambda', progress: 100 },
];

interface ProgressModalProps {
  visible: boolean;
  uploadStatus: string;
  allocationStatus: string;
}

const ProgressModal: React.FC<ProgressModalProps> = ({ visible, uploadStatus, allocationStatus }) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [allocationProgress, setAllocationProgress] = useState(0);

  useEffect(() => {
    const currentStep = uploadStatusSteps.find(step => step.status === uploadStatus);
    if (currentStep) {
      setUploadProgress(currentStep.progress);
    }
  }, [uploadStatus]);

  useEffect(() => {
    const currentStep = allocationStatusSteps.find(step => step.status === allocationStatus);
    if (currentStep) {
      setAllocationProgress(currentStep.progress);
    }
  }, [allocationStatus]);

  
  useEffect(() => {
    if (Object.keys(allocationStatus).length > 0) {
      const currentStatus = Object.values(allocationStatus)[0];
      const currentStep = allocationStatusSteps.find(step => step.status === currentStatus);
      if (currentStep) {
        setAllocationProgress(currentStep.progress);
      }
    }
  }, [allocationStatus]);

  const renderProgressBar = (progress: number, text: string) => (
    <>
      <Progress percent={Math.round(progress)} />
      <Text>{text}</Text>
    </>
  );

  const getCurrentAllocationText = () => {
    if (Object.keys(allocationStatus).length > 0) {
      const currentStatus = Object.values(allocationStatus)[0];
      const currentStep = allocationStatusSteps.find(step => step.status === currentStatus);
      return currentStep ? currentStep.text : 'Processing';
    }
    return 'Allocating inventory';
  };

  return (
    <Modal
      title="Operation Progress"
      visible={visible}
      footer={null}
      closable={false}
    >
      {uploadStatus !== 'viewership_fetched' && renderProgressBar(uploadProgress, uploadStatusSteps.find(step => step.status === uploadStatus)?.text || 'Processing')}
      {uploadStatus === 'viewership_fetched' && renderProgressBar(allocationProgress, getCurrentAllocationText())}
    </Modal>
  );
};

export default ProgressModal;