import { useState, useEffect, useMemo } from "react";
import { Form, PaginationProps, message, notification } from "antd";
import { useOutletContext } from "react-router-dom";
import { TitleConflictData, TitleConflictFilter } from "./title-conflict";
import { initialPaginationObj } from "../../episode/hooks/useEpisodeHook";
import { SortObj } from "../../videos/videos";
import { authAxios } from "../../utils/session_utils";

const useTitleConflicts = () => {
    const [form] = Form.useForm();
    const { user }: any = useOutletContext();
    const [pagination, setPagination] = useState<PaginationProps>({
        ...initialPaginationObj,
        pageSize: user?.page_sizes?.title_conflicts || 10,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [titleConflictData, setTitleConflictData] = useState<TitleConflictData[]>([]);
    const [titleConflictEditId, setTitleConflictEditId] = useState<string | undefined>();
    const [isTitleConflictFormOpen, setIsTitleConflictFormOpen] = useState(false);
    const [titleSortObj, setTitleSortObj] = useState<SortObj>({ column: "ref_id", order: "asc" });
    const [availableMetadata, setAvailableMetadata] = useState<any[]>([]);

    const fetchTitleConflicts = () => {
        setIsLoading(true);
        authAxios({
            method: "GET",
            url: `${process.env.REACT_APP_BACK_END_API}/snowflake/title-conflict`,
        })
            .then((response: any) => {                
                // Transform the data to use lowercase property names
                const transformedData = response.data.data.map((item: any) => ({
                    id: item.ID,
                    ref_id: item.REF_ID,
                    title: item.TITLE,
                    // Add any other properties you need, following the same pattern
                }));
                    
                setTitleConflictData(transformedData);
                setPagination(prev => ({ ...prev, total: response.data.total }));
            })
            .catch((err: any) => {
                console.error('Error fetching title conflicts:', err);
                notification.error({
                    message: 'Error',
                    description: 'Failed to fetch title conflicts',
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    

    useEffect(() => {
        fetchTitleConflicts();
    }, []);

    const handleTableChange = (
        newPagination: PaginationProps,
        _: any,
        sorter: any
    ) => {
        setPagination(prev => ({ ...prev, current: newPagination.current, pageSize: newPagination.pageSize }));
        if (sorter.columnKey) {
            setTitleSortObj({
                column: sorter.columnKey,
                order: sorter.order === "ascend" ? "asc" : "desc",
            });
        }
    };

    const titleConflictColumns = useMemo(() => [
        {
            title: 'Ref ID',
            dataIndex: 'REF_ID',
            key: 'ref_id',
            sorter: true,
        },
        {
            title: 'Title',
            dataIndex: 'TITLE',
            key: 'title',
            sorter: true,
        }
    ], []);

    return {
        isLoading,
        titleConflictData,
        titleConflictColumns,
        isTitleConflictFormOpen,
        handleTableChange,
        setIsTitleConflictFormOpen,
        form,
        titleConflictEditId,
        pagination,
        availableMetadata,
        setPagination,
        fetchTitleConflicts,
    };
};

export default useTitleConflicts;