import React, { FC } from "react";
import {
	Checkbox,
	Col,
	Form,
	FormInstance,
	Input,
	Modal,
	Row,
	Select,
	Spin,
	Typography,
} from "antd";
import styled from "@emotion/styled";
import { LanguageData, setFileName } from "../../videos/modals/AddVideosMetaDataForm";
import { getDigitsOnly } from "../../utils";
import { DistributionDataPropsTypes } from "../../episode";

const CaptionsModal = styled(Modal)`
	& #caption_distribution {
		max-height: calc(100vh - 218px);
		overflow-y: auto;
		overflow-x: hidden;
	}
`;

interface Props extends DistributionDataPropsTypes {
	isCaptionsModalOpen: boolean;
	isLoading: boolean;
	editCaptionId: string | undefined;
	form: FormInstance;
	saveUpdateCaptionsData: () => void;
	cancelHandler: () => void;
	languages: any[];
	distributionPlatformData: any[];
}
const AddCaptionsForm: FC<Props> = React.memo(
	({
		isLoading,
		languages,
		saveUpdateCaptionsData,
		cancelHandler,
		editCaptionId,
		form,
		isCaptionsModalOpen,
		distributionPlatformData,
		distributionCheckedList,
		distributionChange,
		onDistributionCheckAllChange,
		checkAll,
		indeterminate,
	}) => {
		return (
			<CaptionsModal
				centered
				width={600}
				open={isCaptionsModalOpen}
				title={editCaptionId ? "Update Caption" : "Add Caption"}
				onOk={saveUpdateCaptionsData}
				onCancel={cancelHandler}
				okText={editCaptionId ? "Update" : "Add"}
				okButtonProps={{ type: "primary", disabled: isLoading }}
				cancelButtonProps={{ disabled: isLoading }}
			>
				<Spin spinning={isLoading}>
					<Form form={form} layout="vertical" requiredMark={false}>
						<Row gutter={[16, 0]}>
							<Col span={16}>
								<Row gutter={[12, 12]}>
									<Col xs={24} sm={12}>
										<Form.Item name="caption_ref_id" label="Ref ID">
											<Input disabled />
										</Form.Item>
									</Col>
									<Col xs={24} sm={12}>
										<Form.Item
											name="caption_type"
											label="Caption Type"
											rules={[{ required: true, message: "This file required!" }]}
										>
											<Select placeholder="Caption Type">
												<Select.Option value="scc">SCC</Select.Option>
												<Select.Option value="vtt">VTT</Select.Option>
												<Select.Option value="srt">SRT</Select.Option>
											</Select>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={[12, 12]}>
									<Col xs={24} sm={12}>
										<Form.Item
											name="caption_language"
											label="Language"
											rules={[{ required: true, message: "This file required!" }]}
										>
											<Select placeholder="Language">
												{languages?.map((language: LanguageData) => (
													<Select.Option value={language.NAME} key={language.ID}>
														{language.NAME}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
									<Col xs={24} sm={12}>
										<Form.Item
											name="caption_offset_time"
											label="Offset Time"
											rules={[{ required: true, message: "This file required!" }]}
										>
											<Input
												placeholder="Offset Time(in sec)"
												onChange={e =>
													form.setFieldValue("caption_offset_time", getDigitsOnly(e.target.value))
												}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Form.Item
									name="caption_url"
									label="URL"
									rules={[{ required: true, message: "This file required!" }]}
								>
									<Input
										placeholder="Caption URL"
										onChange={e => {
											setFileName(e, form, "captionFileName");
										}}
									/>
								</Form.Item>
								<Form.Item
									name="captionFileName"
									label="File Name"
									rules={[{ required: true, message: "This file required!" }]}
								>
									<Input placeholder="File Name" />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Typography.Title level={3}>Distribution Platforms</Typography.Title>
								<Checkbox
									indeterminate={indeterminate}
									onChange={onDistributionCheckAllChange}
									checked={checkAll}
								>
									{checkAll ? "De-Select All" : "Select All"}
								</Checkbox>
								<Checkbox.Group onChange={distributionChange} value={distributionCheckedList}>
									<Row>
										<Col span={24}></Col>
										{distributionPlatformData.map((platform: any) => {
											return (
												<Col span={24} key={platform.key}>
													<Checkbox value={platform.id} key={platform.key}>
														{platform.name}
													</Checkbox>
												</Col>
											);
										})}
									</Row>
								</Checkbox.Group>
							</Col>
						</Row>
					</Form>
				</Spin>
			</CaptionsModal>
		);
	}
);

AddCaptionsForm.displayName = "AddCaptionsForm";
export default AddCaptionsForm;
