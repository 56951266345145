import { Button, Col, Row } from "antd";
import { CustomTable } from "../series/StyledComponent";
import { useMetadataHook } from "./hooks/useMetadataHook";
import React, { FC, Fragment } from "react";
import AddMetadataForm from "./modals/AddMetadataForm";

import { ComponentTitle, PageTitleLastCol, SearchForm } from "../custom-components";
import MetaFilterModal from "./modals/MetaFilterModal";
import styled from "@emotion/styled";
import { TabComponentProps } from "../utils/common.interface";
import useScreenSizeHook from "../utils/useScreenSizeHook";

export const ClearFilterButton = styled(Button)`
	margin-left: 8px;
	border-radius: 50%;
`;

const Metadata: FC<TabComponentProps> = () => {
	const { height } = useScreenSizeHook();
	const {
		editId,
		columnSeries,
		metaData,
		isLoading,
		setIsLoading,
		saveMetaData,
		isAddMetaFormOpen,
		form,
		handleCancel,
		handleTableChange,
		getFilteredMetadata,
		metaFiterModalCancelHandler,
		metaFilterModalOpen,
		editHelper,
		pagination,
		setIsMetaFormOpen,
		searchData,
	} = useMetadataHook();

	return (
		<Fragment>
			<Row justify="space-between" align="middle">
				<Col>
					<ComponentTitle>Metadata</ComponentTitle>
				</Col>
				<PageTitleLastCol>
					<SearchForm
						form={form}
						searchData={searchData}
						placeholder="Search by Ref ID or Metadata Title"
					/>
					&nbsp;
					<Button type="primary" onClick={() => setIsMetaFormOpen(true)}>
						+ Add Metadata
					</Button>{" "}
				</PageTitleLastCol>
			</Row>
			<CustomTable
				onChange={handleTableChange}
				columns={columnSeries}
				dataSource={metaData}
				scroll={{ x: 1500, y: height - 265 }}
				loading={isLoading}
				pagination={pagination}
				onRow={(record: any) => {
					return {
						onClick: () => {
							editHelper(record);
						},
					};
				}}
			/>
			<AddMetadataForm
				editId={editId}
				form={form}
				isMetaFormOpen={isAddMetaFormOpen}
				saveMetaData={saveMetaData}
				isLoading={isLoading}
				setIsLoading={setIsLoading}
				handleCancel={handleCancel}
			/>
			<MetaFilterModal
				form={form}
				getFilteredMetadata={getFilteredMetadata}
				metaFilterModalOpen={metaFilterModalOpen}
				metaFiterModalCancelHandler={metaFiterModalCancelHandler}
			/>
		</Fragment>
	);
};

export default Metadata;
