import React from 'react';
import { Table, Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/es/upload/interface';

interface FileUploadTableProps {
  fileList: UploadFile[];
  renamedFiles: { [key: string]: string };
  setCurrentFile: (file: UploadFile) => void;
  setRenamedFile: (fileName: string) => void;
  setIsModalVisible: (isVisible: boolean) => void;
  handleFileChange: (info: any) => void;
}

const FileUploadTable: React.FC<FileUploadTableProps> = ({
  fileList,
  renamedFiles,
  setCurrentFile,
  setRenamedFile,
  setIsModalVisible,
  handleFileChange,
}) => {
  return (
    <Table
      dataSource={fileList}
      columns={[
        {
          title: 'Uploaded Files',
          dataIndex: 'name',
          key: 'name',
          render: (text: any, record: any) => (
            <span>{renamedFiles[record.uid] || record.name}</span>
          ),
        },
        {
          title: 'Action',
          key: 'action',
          render: (text: any, record: any) => (
            <Button
              onClick={() => {
                setCurrentFile(record);
                setRenamedFile(renamedFiles[record.uid] || record.name);
                setIsModalVisible(true);
              }}
            >
              Rename
            </Button>
          ),
        },
      ]}
      pagination={false}
      rowKey="uid"
      title={() => (
        <Upload
          onChange={handleFileChange}
          showUploadList={false}
          beforeUpload={() => false}
        >
          <Button icon={<UploadOutlined />}>Upload File</Button>
        </Upload>
      )}
    />
  );
};

export default FileUploadTable;