import React, { useState, useMemo } from 'react';
import { Table, Input, Button, notification, Space, Form, Modal, Radio, Checkbox } from 'antd';
import { SearchOutlined, FilterOutlined, SaveOutlined, EditOutlined } from '@ant-design/icons';
import { ComponentTitle } from '../../custom-components';
import useScreenSizeHook from '../../utils/useScreenSizeHook';
import { MetadataRecord } from '../hooks/useMetadata';
import { ColumnsType } from 'antd/es/table';
import { TitleConflictData } from '../hooks/title-conflict';
import { authAxios } from '../../utils/session_utils';

interface MetadataPoolProps {
  selectedConflict: TitleConflictData | null;
  onMetadataLink: (metadata: MetadataRecord, operation: string) => void;
  allMetadataData: MetadataRecord[];
  setAllMetadataData: React.Dispatch<React.SetStateAction<MetadataRecord[]>>;
  loading: boolean;
  onSaveAll: () => Promise<void>;
}

const MetadataPool: React.FC<MetadataPoolProps> = ({ 
  selectedConflict, 
  onMetadataLink, 
  allMetadataData, 
  setAllMetadataData,
  loading,
  onSaveAll
}) => {
    const { height } = useScreenSizeHook();
    const [searchText, setSearchText] = useState('');
    const [editingKey, setEditingKey] = useState('');
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedResolution, setSelectedResolution] = useState<string | null>(null);
    const [selectedMetadata, setSelectedMetadata] = useState<MetadataRecord | null>(null);
    const [primarySelection, setPrimarySelection] = useState<string | null>(null);
    const [viewershipSelections, setViewershipSelections] = useState<string[]>([]);
    const [metadataSelections, setMetadataSelections] = useState<string[]>([]);

    const isEditing = (record: MetadataRecord) => `${record.ref_id}-${record.id}` === editingKey;

    const edit = (record: MetadataRecord) => {
        form.setFieldsValue({ ...record });
        setEditingKey(`${record.ref_id}-${record.id}`);
    };

    const cancel = () => {
        setEditingKey('');
    };

    type MetadataRecordKey = keyof MetadataRecord;

    const updateMetadata = async (record: MetadataRecord) => {
      try {
          const row = await form.validateFields() as Partial<MetadataRecord>;
          
          // Identify changed fields
          const changedFields = (Object.keys(row) as MetadataRecordKey[]).reduce((acc, key) => {
              if (row[key] !== undefined && row[key] !== record[key]) {
                  acc[key] = row[key] as string;
              }
              return acc;
          }, {} as Partial<MetadataRecord>);
  
          // Add IDs to the changed fields
          const updatedRecord = {
              ...record,
              ...changedFields,
          };
  
          console.log(JSON.stringify(updatedRecord))
  
          const response = await authAxios({
              method: 'PUT',
              url: `${process.env.REACT_APP_BACK_END_API}/snowflake/metadata/partial`,
              data: updatedRecord,
          });
  
          if (response.status === 200) {
              // Update local state immediately
              setAllMetadataData(prevData => 
                prevData.map(item => item.id === updatedRecord.id ? updatedRecord : item)
              );

              notification.success({
                  message: 'Update Successful',
                  description: `Metadata for ${record.ref_id} has been updated.`,
              });
              setEditingKey('');
          } else {
              throw new Error('Update failed');
          }
      } catch (error) {
          console.error('Failed to update metadata:', error);
          notification.error({
              message: 'Update Failed',
              description: 'An error occurred while updating the metadata. Please try again.',
          });
      }
    };


    const filteredData = useMemo(() => {
        return allMetadataData.filter(item =>
            item.title.toLowerCase().includes(searchText.toLowerCase())
        );
    }, [allMetadataData, searchText]);

    const handleSearch = (value: string) => {
        setSearchText(value);
    };

    const getUniqueValues = (data: MetadataRecord[], key: keyof MetadataRecord) => {
        const uniqueValues = new Set(data.map(item => item[key]?.toString().trim()));
        return Array.from(uniqueValues).filter(Boolean).map(value => ({ text: value, value: value }));
    };

    const handleLinkClick = (record: MetadataRecord) => {
      setSelectedMetadata(record);
      setIsModalVisible(true);
  };
  const handleModalOk = () => {
    if (selectedMetadata && primarySelection) {
        let resolution = '';
        if (primarySelection === '1') {
            resolution = 'create_alt_title';
        } else if (primarySelection === '2') {
            if (viewershipSelections.includes('title') && viewershipSelections.includes('ref_id')) {
                resolution = 'update_viewership_title_and_ref_id';
            } else if (viewershipSelections.includes('title')) {
                resolution = 'update_viewership_title';
            } else if (viewershipSelections.includes('ref_id')) {
                resolution = 'update_viewership_ref_id';
            }
        } else if (primarySelection === '3') {
            if (metadataSelections.includes('title') && metadataSelections.includes('ref_id')) {
                resolution = 'update_metadata_title_and_ref_id';
            } else if (metadataSelections.includes('title')) {
                resolution = 'update_metadata_title';
            } else if (metadataSelections.includes('ref_id')) {
                resolution = 'update_metadata_ref_id';
            }
        }
        console.log('resolution: ',resolution)
        setSelectedResolution(resolution);
        onMetadataLink(selectedMetadata, resolution);
        setIsModalVisible(false);
        setPrimarySelection(null);
        setViewershipSelections([]);
        setMetadataSelections([]);
        setSelectedMetadata(null);
    }
};


const handleModalCancel = () => {
    setIsModalVisible(false);
    setPrimarySelection(null);
    setViewershipSelections([]);
    setMetadataSelections([]);
    setSelectedMetadata(null);
};



    const epStatusFilters = useMemo(() => getUniqueValues(allMetadataData, 'episode_status'), [allMetadataData]);
    const seriesStatusFilters = useMemo(() => getUniqueValues(allMetadataData, 'series_status'), [allMetadataData]);
    const seriesCodeFilters = useMemo(() => getUniqueValues(allMetadataData, 'series_code'), [allMetadataData]);
    const contentProviderFilters = useMemo(() => getUniqueValues(allMetadataData, 'content_provider'), [allMetadataData]);
    const languageFilters = useMemo(() => getUniqueValues(allMetadataData, 'language'), [allMetadataData]);

    const columns: ColumnsType<MetadataRecord> = [
      {
        title: 'Ref ID',
        dataIndex: 'ref_id',
        key: 'ref_id',
        width: 100,
        sorter: (a, b) => a.ref_id.localeCompare(b.ref_id),
        render: (text) => text, 
      },
      {
          title: 'Title',
          dataIndex: 'title',
          key: 'title',
          sorter: (a, b) => a.title.localeCompare(b.title),
          render: (text, record) => {
              const editable = isEditing(record);
              return editable ? (
                  <Form.Item
                      name="title"
                      style={{ margin: 0 }}
                      rules={[{ required: true, message: 'Title is required' }]}
                  >
                      <Input />
                  </Form.Item>
              ) : (
                  text
              );
          },
      },
      {
          title: 'Ep. Status',
          width: 100,
          dataIndex: 'episode_status',
          key: 'episode_status',
          filters: epStatusFilters,
          onFilter: (value, record) => record.episode_status?.toString().trim().toLowerCase() === value.toString().toLowerCase(),
          render: (text, record) => {
              const editable = isEditing(record);
              return editable ? (
                  <Form.Item
                      name="episode_status"
                      style={{ margin: 0 }}
                  >
                      <Input />
                  </Form.Item>
              ) : (
                  text
              );
          },
      },
      {
        title: 'Series Status',
        dataIndex: 'series_status',
        width: 100,
        key: 'series_status',
        filters: seriesStatusFilters,
        onFilter: (value, record) => record.series_status?.toString().trim().toLowerCase() === value.toString().toLowerCase(),
        render: (text, record) => {
            const editable = isEditing(record);
            return editable ? (
                <Form.Item
                    name="series_status"
                    style={{ margin: 0 }}
                >
                    <Input />
                </Form.Item>
            ) : (
                text
            );
        },
    },
      {
          title: 'Series Code',
          dataIndex: 'series_code',
          width: 80,
          key: 'series_code',
          filters: seriesCodeFilters,
          onFilter: (value, record) => record.series_code?.toString().trim().toLowerCase() === value.toString().toLowerCase(),
          render: (text, record) => {
              const editable = isEditing(record);
              return editable ? (
                  <Form.Item
                      name="series_code"
                      style={{ margin: 0 }}
                  >
                      <Input />
                  </Form.Item>
              ) : (
                  text
              );
          },
      },
      {
          title: 'Content Provider',
          dataIndex: 'content_provider',
          key: 'content_provider',
          width: 100,
          filters: contentProviderFilters,
          onFilter: (value, record) => record.content_provider?.toString().trim().toLowerCase() === value.toString().toLowerCase(),
          render: (text, record) => {
              const editable = isEditing(record);
              return editable ? (
                  <Form.Item
                      name="content_provider"
                      style={{ margin: 0 }}
                  >
                      <Input />
                  </Form.Item>
              ) : (
                  text
              );
          },
      },
      {
          title: 'Language',
          dataIndex: 'language',
          width: 110,
          key: 'language',
          filters: languageFilters,
          onFilter: (value, record) => record.language?.toString().trim().toLowerCase() === value.toString().toLowerCase(),
          render: (text, record) => {
              const editable = isEditing(record);
              return editable ? (
                  <Form.Item
                      name="language"
                      style={{ margin: 0 }}
                  >
                      <Input />
                  </Form.Item>
              ) : (
                  text
              );
          },
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (_, record) => {
            const editable = isEditing(record);
            return (
                <Space>
                    {editable ? (
                        <>
                            <Button onClick={() => updateMetadata(record)} type="link">
                                Save
                            </Button>
                            <Button onClick={cancel} type="link">
                                Cancel
                            </Button>
                        </>
                    ) : (
                        <Button disabled={editingKey !== ''} onClick={() => edit(record)} icon={<EditOutlined />}>
                            
                        </Button>
                    )}
                    <Button
                        onClick={() => handleLinkClick(record)}
                        disabled={!selectedConflict || editingKey !== ''}
                    >
                        Link
                    </Button>
                </Space>
            );
        },
    },
];
const isModalOkDisabled = !primarySelection || 
(primarySelection === '2' && viewershipSelections.length === 0) ||
(primarySelection === '3' && metadataSelections.length === 0);

    const handleSaveAll = async () => {
        try {
            await onSaveAll();
            notification.success({
                message: "Save Successful",
                description: "All records have been updated on the server.",
            });
        } catch (error) {
            notification.error({
                message: "Save Failed",
                description: "An error occurred while saving. Please try again.",
            });
        }
    };

    if (!Array.isArray(allMetadataData)) {
        console.error('allMetadataData is not an array:', allMetadataData);
        notification.error({
            message: "Data Error",
            description: "Unable to load metadata. Please try again later.",
        });
        return <div>Error: Unable to load metadata. Please try again later.</div>;
    }

    return (
      <Form form={form} component={false}>
          <ComponentTitle>Metadata Pool</ComponentTitle>
          <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Space>
                  <Input.Search
                      placeholder="Search metadata"
                      onSearch={handleSearch}
                      style={{ width: 300 }}
                  />
              </Space>
              <Button 
                  icon={<SaveOutlined />} 
                  onClick={handleSaveAll}
                  type="primary"
              >
                  Reprocess Conflicts
              </Button>
          </div>
          <Table
              columns={columns}
              dataSource={filteredData}
              loading={loading}
              pagination={{
                  showSizeChanger: true,
                  showQuickJumper: true,
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                  pageSize: 10,
                  pageSizeOptions: ['10', '20', '50', '100'],
              }}
              scroll={{ y: height - 315 }}
              rowKey={(record) => `${record.ref_id}-${record.id}`}
              locale={{
                  emptyText: loading ? 'Loading...' : 'No data available',
              }}
          />
            <Modal
                title="Select Resolution"
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                okButtonProps={{ disabled: isModalOkDisabled }}
            >
                <Radio.Group onChange={(e) => setPrimarySelection(e.target.value)} value={primarySelection}>
                    <Space direction="vertical">
                        <Radio value="1">1. Create alternate title</Radio>
                        <Radio value="2">2. Update viewership record</Radio>
                        <Radio value="3">3. Update metadata record</Radio>
                    </Space>
                </Radio.Group>

                {primarySelection === '2' && (
                    <Checkbox.Group
                        style={{ marginTop: 16, marginLeft: 24 }}
                        value={viewershipSelections}
                        onChange={setViewershipSelections as any}
                    >
                        <Space direction="vertical">
                            <Checkbox value="title">a. Title</Checkbox>
                            <Checkbox value="ref_id">b. Ref_Id</Checkbox>
                        </Space>
                    </Checkbox.Group>
                )}

                {primarySelection === '3' && (
                    <Checkbox.Group
                        style={{ marginTop: 16, marginLeft: 24 }}
                        value={metadataSelections}
                        onChange={setMetadataSelections as any}
                    >
                        <Space direction="vertical">
                            <Checkbox value="title">a. Title</Checkbox>
                            <Checkbox value="ref_id">b. Ref_Id</Checkbox>
                        </Space>
                    </Checkbox.Group>
                )}
            </Modal>
      </Form>
    );
};

export default MetadataPool;



