import React, { FC, Fragment } from "react";
import { Button, Col, Form, Input, Row } from "antd";
import { ComponentTitle } from "../custom-components";
import { CloseIcon, TagFormContainer, TagFormItem, ThemedEnterOutlined } from "../tags";
import { CustomTable } from "../series/StyledComponent";
import useDistributionPlatformHook from "./hooks/useDistributionPlatformHook";
import { TabComponentProps } from "../utils/common.interface";
import useScreenSizeHook from "../utils/useScreenSizeHook";
import DistributionPlatformEditModal from "./modals/EditForm";

const DistributionPlatformComponent: FC<TabComponentProps> = () => {
	const { height } = useScreenSizeHook();
	const {
		distibuterPlatformColumns,
		distributionPlatformData,
		isOpenDistributionPlatformForm,
		setIsOpenDistributionPlatformForm,
		saveDistributionPlatformData,
		isLoading,
		handleCancel,
		form,
		handleTableChange,
		editHelper,
		pagination,
		isEditModalOpen,
	} = useDistributionPlatformHook();
	return (
		<Fragment>
			<Row justify="space-between" align="middle">
				<Col>
					<ComponentTitle>Distribution Platforms</ComponentTitle>
				</Col>
				<Col>
					{isOpenDistributionPlatformForm ? (
						<TagFormContainer>
							<Form form={form} requiredMark={false}>
								<TagFormItem name="name">
									<Input
										disabled={isLoading}
										onPressEnter={saveDistributionPlatformData}
										placeholder="Partner Name"
										suffix={<ThemedEnterOutlined />}
									/>
								</TagFormItem>
							</Form>
							<span>
								<Button onClick={saveDistributionPlatformData}>Add</Button>
							</span>
							<span>
								<CloseIcon onClick={handleCancel} />
							</span>
						</TagFormContainer>
					) : (
						<Button disabled={isLoading} onClick={() => setIsOpenDistributionPlatformForm(true)}>
							+ Add Platform
						</Button>
					)}
				</Col>
			</Row>
			<CustomTable
				columns={distibuterPlatformColumns}
				dataSource={distributionPlatformData}
				loading={isLoading}
				onChange={handleTableChange}
				scroll={{ x: 500, y: height - 265 }}
				pagination={{ ...pagination, total: 30 }}
				onRow={(record: any) => {
					return {
						onClick: () => {
							editHelper(record);
						},
					};
				}}
			/>
			<DistributionPlatformEditModal
				form={form}
				saveDistributionPlatformData={saveDistributionPlatformData}
				cancelFormHandler={handleCancel}
				isModalOpen={isEditModalOpen}
			/>
		</Fragment>
	);
};

export default DistributionPlatformComponent;
