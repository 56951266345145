import { Form, Input, Modal, Spin } from "antd";
import { FormInstance } from "antd/lib";
import React from "react";

interface VideoListFormProps {
	form: FormInstance;
	submitHandler: () => void;
	onCancel: () => void;
	isLoading: boolean;
	listEditId?: string;
	isOpen: boolean;
}

const VideoListForm: React.FC<VideoListFormProps> = ({
	form,
	submitHandler,
	onCancel,
	isLoading,
	listEditId,
	isOpen,
}) => {
	return (
		<Modal
			open={isOpen}
			title={`${!listEditId ? "Add" : "Edit"} Video List`}
			okText={`${!listEditId ? "Add" : "Update"} List`}
			cancelText="Cancel"
			onCancel={onCancel}
			onOk={submitHandler}
			okButtonProps={{ disabled: isLoading }}
			cancelButtonProps={{ disabled: isLoading }}
		>
			<Spin spinning={isLoading}>
				<Form form={form} requiredMark={false} initialValues={{ is_private: true }}>
					<Form.Item
						name="name"
						label="Name"
						rules={[{ required: true, message: "This is required field" }]}
					>
						<Input placeholder="List Name" />
					</Form.Item>
				</Form>
			</Spin>
		</Modal>
	);
};

export default VideoListForm;
