import { Modal } from "antd";
import React from "react";
import styled from "@emotion/styled";

type VideoPlayerProps = {
	signedURL?: string;
	fileName: string;
	closeVideo: () => void;
	width?: number;
	height?: number;
};

const VideoPlayerModal = styled(Modal)<{ height: number }>`
	& .ant-modal-content {
		padding: 0;
	}

	& .ant-modal-header {
		padding-left: 24px;
		margin-bottom: 0px !important;
		height: 50px;
		display: flex;
		align-items: center;
	}
`;

const VideoPlayer: React.FC<VideoPlayerProps> = ({
	fileName,
	height,
	width,
	signedURL,
	closeVideo,
}) => {
	return (
		<VideoPlayerModal
			centered
			maskClosable={false}
			height={height ?? 600}
			width={width ?? 640}
			open={Boolean(signedURL)}
			onCancel={closeVideo}
			title={fileName?.split(".")?.[0]?.slice(0, 60) ?? ""}
			footer={null}
		>
			<video controls autoPlay width={640} disablePictureInPicture controlsList="nodownload">
				<source src={signedURL} />
				<p>Your browser don&apos;t support HTML video</p>
			</video>
		</VideoPlayerModal>
	);
};

export default VideoPlayer;
