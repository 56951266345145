import React, { useEffect, useState } from "react";
import { initialPaginationObj } from "../../episode/hooks/useEpisodeHook";
import { useOutletContext } from "react-router";
import { Form, PaginationProps, message } from "antd";
import { v4 as uuidV4 } from "uuid";
import { getBulkSelectColumns } from "../../custom-components/BulkSelectColumn";

import {
	SETTINGS_DATA,
	exportCSVWithLambda,
	getThumbnailsData,
	searchHandler,
	updateUserPageSizeSettings,
	handleSelectedRowStyling,
} from "../../utils";
import getThumbnailColumns from "./columns";
import { deleteThumbnail, editThumbnailHelper, saveUpdateThumbnails } from "./utils";
import useDistributionData from "../../distribution_partner/hooks/useDistributionData";
import { TagOption, saveTagsData } from "../../tags/hooks/utils";
import { ColumnsType } from "antd/es/table";
import { GetDataHandlerParams } from "../../videos/hooks/useVideosHooks";

export interface ThumbnailsFilterObj {
	series?: string[];
	distribution_platforms?: string[];
	not_distribution_platforms?: string[];
	thumbnails_tags?: string[];
	thumbnails_not_tagged?: string[];
	searchQuery?: string;
	video_lists?: string[];
}

export type SelectedThumbnailData = { key: string; id: string };

export interface GetThumbnailsDataHandler extends GetDataHandlerParams {
	filters?: ThumbnailsFilterObj;
}

export const useThumbnailsHook = (fromSeries?: boolean) => {
	const cuepointFilterFields = [
		"series_filter",
		"thumbnails_tags_filter",
		"thumbnails_not_tagged",
		"distribution_platforms_filter",
		"not_distribution_platforms_filter",
		"video_lists_filter",
	];
	const [form] = Form.useForm();
	const {
		user,
		setUser,
		distributionPlatformData,
		tagsList,
		setTagsList,
		seriesList,
		videoLists,
	}: any = useOutletContext();
	const {
		distributionCheckedList,
		setDistributionCheckedList,
		distributionChange,
		onDistributionCheckAllChange,
		checkAll,
		indeterminate,
	} = useDistributionData();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [thumbnailsData, setThumbnailsData] = useState<any[]>([]);
	const [thumbnailEditId, setThumbnailEditId] = useState<string | null | undefined>();
	const [episodeId, setEpisodeId] = useState<string | null>();
	const [isAddThumbnailFormOpen, setIsThumbnailFormOpen] = useState<boolean>(false);
	const [isAddToDistributorsModalOpen, setIsAddToDistributorsModalOpen] = useState<boolean>(false);
	const [isBulkTaggingModalOpen, setIsBulkTaggingModalOpen] = useState<boolean>(false);
	const [selectedThumbnailsData, setSelectedThumbnailsData] = useState<SelectedThumbnailData[]>([]);
	const [tagsObj, setTagsObj] = useState<any>();
	const [pagination, setPagination] = useState<PaginationProps>({
		...initialPaginationObj,
		pageSize: user.page_sizes?.metadata ?? 10,
	});
	const [thumbnailsFilterObj, setThumbnailsFilterObj] = useState<ThumbnailsFilterObj>();
	const [isThumbnailsFilterModalOpen, setIsThumbnailsfilterModalOpen] = useState<boolean>(false);
	const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [isBulkDeleteModalOpen, setIsBulkDeleteModalOpen] = useState<boolean>(false);

	useEffect(() => {
		if (!fromSeries) {
			setIsLoading(true);
			getDataHandler({});
		}
	}, [searchQuery]);

	useEffect(() => {
		const selectedThumbnailsData = JSON.parse(
			sessionStorage.getItem("selectedThumbnailsData") || "[]"
		);
		setSelectedThumbnailsData(selectedThumbnailsData);
		const selectedCuePointsKeys = selectedThumbnailsData.map((item: any) => item.key);
		handleSelectedRowStyling(selectedCuePointsKeys);
	}, [thumbnailsData]);

	const getDataHandler = async ({
		filters,
		currentPaginationInfo,
		searchString,
	}: GetThumbnailsDataHandler) => {
		setIsLoading(true);
		await getThumbnailsData(
			setThumbnailsData,
			setIsLoading,
			filters || searchString
				? { ...initialPaginationObj, pageSize: pagination.pageSize }
				: currentPaginationInfo ?? pagination,
			setPagination,
			{
				...(filters ?? thumbnailsFilterObj),
				searchQuery: searchString ?? searchQuery,
			}
		);
	};

	const handleTableChange = (pagination: PaginationProps, _: any, sorter: any, { action }: any) => {
		switch (action) {
			case "paginate": {
				if (pagination.pageSize !== user.page_sizes.thumbnails) {
					const updatedSettingsData: SETTINGS_DATA = {
						user_id: user.user_id,
						page_sizes: { ...user.page_sizes, thumbnails: pagination.pageSize },
					};
					updateUserPageSizeSettings(updatedSettingsData);
					setUser({ ...user, ...updatedSettingsData });
				}
				getDataHandler({ currentPaginationInfo: pagination });
				break;
			}
		}
	};

	const clearThumbnailsSelection = () => {
		const selectedThumbnailsKeys = selectedThumbnailsData.map((item: any) => item.key);
		handleSelectedRowStyling(selectedThumbnailsKeys, true);
		sessionStorage.removeItem("selectedThumbnailsData");
		setSelectedThumbnailsData([]);
	};

	const handleAddCustomTag = async (
		customTagValue: string,
		related_to: string[],
		insertValue: (tagId: string) => void
	) => {
		try {
			setIsLoading(true);
			const tagId = uuidV4();
			const values = {
				id: tagId,
				title: customTagValue,
				related_to,
			};

			await saveTagsData(values, tagsList);
			setTagsList([...tagsList, values]);
			const newKey = `${customTagValue.toLowerCase()}-${related_to}`;
			setTagsObj({ ...tagsObj, [newKey]: true });
			setIsLoading(false);
			insertValue(tagId);
		} catch (error) {
			console.error("useThumbnailsHook ~ error:", error);
			setIsLoading(false);
		}
	};

	const editHandler = (record: any) => {
		editThumbnailHelper(
			record,
			form,
			setThumbnailEditId,
			setIsThumbnailFormOpen,
			setDistributionCheckedList,
			setEpisodeId
		);
	};

	const editThumbnail = async () => {
		try {
			setIsLoading(true);
			const values = await form.validateFields();
			await saveUpdateThumbnails(values, episodeId, distributionCheckedList, thumbnailEditId);
			setIsThumbnailFormOpen(false);
			await getDataHandler({});
			setEpisodeId(null);
			setThumbnailEditId(undefined);
			form.resetFields();
		} catch (error) {
			message.error("Something went wrong!");
			console.error(error);
			setIsLoading(false);
		}
	};

	const delteThumbnailHandler = async (id: string) => {
		try {
			setIsLoading(true);
			const response = await deleteThumbnail(id);
			if (response.status === 200) {
				message.success(response.data.message);
				getDataHandler({});
			}
		} catch (error) {
			console.error(error);
			setIsLoading(false);
		}
	};

	const handleCancel = () => {
		try {
			form.resetFields();
			setIsThumbnailFormOpen(false);
			setDistributionCheckedList([]);
		} catch (error) {
			console.error(error);
		}
	};

	const exporThumbnailsCSV = async () => {
		try {
			const selectedIds = selectedThumbnailsData.map(ep => ep.id);
			const data = {
				...thumbnailsFilterObj,
				thumbnails: true,
				selectedIds,
			};
			await exportCSVWithLambda(data, "assets/thumbnails", user.name, "Thumbnails Export");
		} catch (err) {
			console.error(err);
		}
	};

	const getFilteredThumbnail = async () => {
		try {
			const {
				series_filter,
				thumbnails_tags_filter,
				thumbnails_not_tagged,
				distribution_platforms_filter,
				video_lists_filter,
				not_distribution_platforms_filter,
			} = await form.getFieldsValue(cuepointFilterFields);
			setIsThumbnailsfilterModalOpen(false);
			if (
				series_filter?.length ||
				thumbnails_tags_filter?.length ||
				thumbnails_not_tagged?.length ||
				distribution_platforms_filter?.length ||
				video_lists_filter?.length ||
				not_distribution_platforms_filter?.length
			) {
				const filters = {
					series: series_filter,
					thumbnails_tags: thumbnails_tags_filter,
					distribution_platforms: distribution_platforms_filter,
					not_distribution_platforms: not_distribution_platforms_filter,
					thumbnails_not_tagged,
					video_lists: video_lists_filter,
				};
				setThumbnailsFilterObj(filters);
				getDataHandler({ filters });
			}
		} catch (error) {
			console.error("error in getting filtered thumbnails", error);
		}
	};

	const searchData = async () => {
		try {
			setPagination({ ...initialPaginationObj, pageSize: pagination.pageSize });
			searchHandler(form, timeoutId, setTimeoutId, setSearchQuery);
		} catch (err) {
			console.error({ err });
		}
	};
	const clearFilter = () => {
		form.resetFields(cuepointFilterFields);
		setThumbnailsFilterObj(undefined);
		getDataHandler({ filters: {} });
	};
	const filterModalCancelHandler = () => {
		setIsThumbnailsfilterModalOpen(false);
	};

	let columnThumbnailsBulkSelect: ColumnsType<any> = getBulkSelectColumns(
		thumbnailsData,
		selectedThumbnailsData,
		setSelectedThumbnailsData,
		handleSelectedRowStyling,
		"selectedThumbnailsData"
	);
	columnThumbnailsBulkSelect = columnThumbnailsBulkSelect.concat(
		getThumbnailColumns(editHandler, delteThumbnailHandler)
	);

	return {
		isLoading,
		columnThumbnails: columnThumbnailsBulkSelect,
		thumbnailsData,
		isAddThumbnailFormOpen,
		setIsThumbnailFormOpen,
		handleTableChange,
		pagination,
		form,
		editThumbnail,
		handleCancel,
		thumbnailEditId,
		editHelper: editHandler,
		distributionPlatformData,
		distributionCheckedList,
		setDistributionCheckedList,
		distributionChange,
		onDistributionCheckAllChange,
		checkAll,
		indeterminate,
		tagsList,
		handleAddCustomTag,
		exporThumbnailsCSV,
		searchData,
		isThumbnailsFilterModalOpen,
		thumbnailsFilterObj,
		setThumbnailsFilterObj,
		setIsThumbnailsfilterModalOpen,
		clearFilter,
		getFilteredThumbnail,
		seriesList,
		filterModalCancelHandler,
		editHandler,
		setThumbnailEditId,
		isAddToDistributorsModalOpen,
		setIsAddToDistributorsModalOpen,
		selectedThumbnailsData,
		setSelectedThumbnailsData,
		getDataHandler,
		clearThumbnailsSelection,
		isBulkTaggingModalOpen,
		setIsBulkTaggingModalOpen,
		tagsData: tagsList,
		videoLists,
		isBulkDeleteModalOpen,
		setIsBulkDeleteModalOpen,
	};
};
