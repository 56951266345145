import React, { Dispatch, SetStateAction } from "react";
import { Button, Form, FormInstance, Modal, Select } from "antd";
import styled from "@emotion/styled";
import { PlatformData } from "../../../distribution_partner/hooks/useDistributionData";
import { distributionPlatformFilterOptions } from "../../../utils";

type ExportModalProps = {
	isExportModalOpen: boolean;
	exportCSV: () => void;
	setIsExportModalOpen: (value: boolean) => void;
	form: FormInstance;
	distributionPlatformData: PlatformData[];
	exportFormat: string;
	setExportFormat: Dispatch<SetStateAction<string>>;
};

const ExportModal = styled(Modal)`
	& .ant-modal-body {
		margin-top: 16px;
		min-height: 220px;
	}
	& .ant-modal-footer {
		display: flex;
		justify-content: center;
	}
`;

const CommonExportModal: React.FC<ExportModalProps> = ({
	isExportModalOpen,
	setIsExportModalOpen,
	form,
	exportCSV,
	distributionPlatformData,
	exportFormat,
	setExportFormat,
}) => {
	const exportOptions = [
		{
			label: "All Fields  ",
			value: "videos",
			key: "videos",
		},
		{
			label: "All Fields with Distribution",
			value: "videos-distribution",
			key: "videos-distribution",
		},
		{
			label: "Wurl",
			value: "platforms/wurl",
			key: "platforms/wurl",
		},
		{
			label: "Zype",
			value: "platforms/zype",
			key: "platforms/zype",
		},
		{
			label: "Pluto",
			value: "platforms/pluto",
			key: "platforms/pluto",
		},
		{
			label: "Amagi",
			value: "platforms/amagi",
			key: "platforms/amagi",
		},
	];

	const cancelHandler = () => {
		form.resetFields(["format"]);
		setIsExportModalOpen(false);
	};

	return (
		<ExportModal
			title="Export CSV"
			open={isExportModalOpen}
			onCancel={cancelHandler}
			footer={
				<Button type="primary" onClick={exportCSV}>
					Export CSV
				</Button>
			}
		>
			<Form requiredMark={false} form={form} initialValues={{ format: exportFormat }}>
				<Form.Item label="Format" name="format">
					<Select
						onChange={(value: string) => setExportFormat(value)}
						placeholder="Select Export Format"
						options={exportOptions}
					/>
				</Form.Item>
				{(exportFormat === "platforms/amagi" || exportFormat === "platforms/pluto") && (
					<Form.Item label="Platforms" name="secondary_platforms">
						<Select
							placeholder="Distribution Platforms"
							filterOption={distributionPlatformFilterOptions}
							mode="multiple"
							allowClear
						>
							{distributionPlatformData.map((platformData: PlatformData) => {
								return (
									<Select.Option value={platformData.name} key={platformData.id}>
										{platformData.name}
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>
				)}
			</Form>
		</ExportModal>
	);
};

export default CommonExportModal;
