import { useState } from "react";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { useOutletContext } from "react-router-dom";
export interface PlatformData {
	name: string;
	id: string;
	key: string;
}
const useDistributionData = () => {
	const { distributionPlatformData }: any = useOutletContext();
	const [distributionCheckedList, setDistributionCheckedList] = useState<CheckboxValueType[]>([]);
	const distributionPlainOptions = distributionPlatformData.map((platform: PlatformData) => ({
		value: platform.id,
		label: platform.name,
	}));
	const distributionPlatformIds = distributionPlatformData.map(
		(platform: PlatformData) => platform.id
	);
	const distributionChange = (list: CheckboxValueType[]) => {
		setDistributionCheckedList(list);
	};

	const onDistributionCheckAllChange = (e: CheckboxChangeEvent) => {
		setDistributionCheckedList(e.target.checked ? distributionPlatformIds : []);
	};

	const checkAll = distributionPlainOptions.length === distributionCheckedList.length;
	const indeterminate =
		distributionCheckedList.length > 0 &&
		distributionCheckedList.length < distributionPlainOptions.length;

	return {
		distributionCheckedList,
		setDistributionCheckedList,
		distributionPlainOptions,
		distributionPlatformIds,
		distributionChange,
		onDistributionCheckAllChange,
		checkAll,
		indeterminate,
	};
};

export default useDistributionData;
