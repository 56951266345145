import React from "react";
import useVideoListsHook from "./hooks/useVideoListsHook";
import { Button, Col, Row } from "antd";
import { CustomTable } from "../series/StyledComponent";
import { ComponentTitle, PageTitleLastCol } from "../custom-components";
import VideoListForm from "./modals/VideoListForm";
import useScreenSizeHook from "../utils/useScreenSizeHook";
import { HomeTabKeyEnum } from "../utils";

const VideoListsComponent = () => {
	const { height } = useScreenSizeHook();
	const {
		videoListsCols,
		videoListsData,
		isLoading,
		isVideoListFormOpen,
		setIsVideoListFormOpen,
		form,
		saveUpdateVideoList,
		handleCancel,
		listEditId,
		showAllLists,
		pagination,
		handleTableChange,
		editHelper,
		updateListVisibility,
	} = useVideoListsHook();
	return (
		<>
			<Row justify="space-between" align="middle">
				<Col>
					<ComponentTitle>Video Lists</ComponentTitle>
				</Col>
				<PageTitleLastCol>
					<Button type="primary" onClick={updateListVisibility}>
						{showAllLists ? "Hide Public" : "Show Public"}
					</Button>
					&nbsp;
					<Button
						type="primary"
						onClick={() => {
							setIsVideoListFormOpen(true);
						}}
					>
						+ Add List
					</Button>
				</PageTitleLastCol>
			</Row>
			<CustomTable
				onChange={handleTableChange}
				scroll={{ y: height - 265 }}
				loading={isLoading}
				columns={videoListsCols}
				dataSource={videoListsData}
				pagination={pagination}
				onRow={(record: any) => {
					return {
						onClick: e => {
							if (e.metaKey || e.ctrlKey) {
								const newTab = window.open(HomeTabKeyEnum.VIDEO_LISTS + "/" + record.id, "_blank");
								newTab?.focus();
							} else {
								editHelper(record);
							}
						},
					};
				}}
			/>
			<VideoListForm
				form={form}
				submitHandler={saveUpdateVideoList}
				onCancel={handleCancel}
				isLoading={isLoading}
				listEditId={listEditId}
				isOpen={isVideoListFormOpen}
			/>
		</>
	);
};

export default VideoListsComponent;
