import React, { FC, useEffect, useState } from "react";
import { Button, Form, Input, FormInstance, Spin, Select, Col, Row } from "antd";
import styled from "@emotion/styled";
import { CustomModal } from "../../utils/StyledComponents";
import { LanguageData } from "../../videos/modals/AddVideosMetaDataForm";
import { getLanguageList } from "../../utils";

export const SubmitButton = styled(Button)`
	color: #000000 !important;
	font-weight: 600;
	background: #feea3c !important;
	border: 5px solid #feea3c !important;
	display: flex;
	justify-content: center;
	align-items: center;
`;

type Props = {
	isMetaFormOpen: boolean;
	saveMetaData(): void;
	form: FormInstance;
	isLoading: boolean;
	setIsLoading(value: boolean): void;
	handleCancel: () => void;
	editId: string | null;
};

const AddSeriesForm: FC<Props> = ({ editId, isMetaFormOpen, saveMetaData, form, isLoading, handleCancel }) => {
	const [languagesList, setLanguageList] = useState<LanguageData[]>([]);

	useEffect(() => {
		getLanguageList(setLanguageList);
	}, []);

	return (
		<CustomModal
			ref_id="dummy_ref_id"
			title={editId ? "Update Meta Info..." : "Add Meta Info..."}
			open={isMetaFormOpen}
			onOk={() => saveMetaData()}
			onCancel={() => handleCancel()}
			okText={editId ? "Update Metadata" : "Save Metadata"}
			maskClosable={false}
			okButtonProps={{ disabled: isLoading }}
			cancelButtonProps={{ disabled: isLoading }}
			centered
		>
			<Spin spinning={isLoading}>
				<Form form={form} name="basic" autoComplete="off" layout="vertical">
					<Row>
						<Col span={11}>
							<Form.Item
								label="Language"
								key="language"
								name="language"
								rules={[{ required: true, message: "Required Field" }]}
							>
								<Select placeholder="Language">
									{languagesList?.map((language: LanguageData) => (
										<Select.Option value={language.NAME} key={language.ID}>
											{language.NAME}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={12} offset={1}>
							<Form.Item
								label="Ref ID"
								name="ref_id"
								rules={[{ required: true, message: "Required Field" }]}
							>
								<Input placeholder="Ref ID" />
							</Form.Item>
						</Col>
					</Row>
					<Form.Item
						label="Title"
						name="title"
						rules={[{ required: true, message: "Required Field" }]}
					>
						<Input placeholder="Title" />
					</Form.Item>

					<Form.Item label="Clean Title" name="clean_title">
						<Input />
					</Form.Item>
					<Form.Item
						label="Keywords"
						key="keywords"
						name="keywords"
						// rules={[{ required: true, message: "Required Field" }]}
					>
						<Input placeholder="Keywords" />
					</Form.Item>
					<Form.Item label="Description 100" name="description_100">
						<Input.TextArea showCount maxLength={100} />
					</Form.Item>
					<Form.Item
						label="Description 250"
						name="description_250"
						// rules={[{ required: true, message: "Required Field" }]}
					>
						<Input.TextArea showCount maxLength={250} />
					</Form.Item>
					<Form.Item
						label="Full Description"
						name="description_full"
						// rules={[{ required: true, message: "Required Field" }]}
					>
						<Input.TextArea showCount />
					</Form.Item>
				</Form>
			</Spin>
		</CustomModal>
	);
};

export default AddSeriesForm;
