// styles.ts
import styled from "@emotion/styled";
import { Card } from "antd";

export const StyledCard = styled(Card)`
  background-color: #dfe7e9;
`;

export const TitleGreen = styled.h2`
  color: #1db954;
`;

export const SubTitleGreen = styled.h4`
  color: #1db954;
`;
