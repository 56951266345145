import React, { FC } from 'react'
import { StyledFilterModal } from '../../episode/modals/EpisodeFilterModal'
import { Form, FormInstance, Input } from 'antd'

const { Item } = Form;

interface MetadataFilterModal {
    metaFilterModalOpen: boolean;
    form: FormInstance;
    getFilteredMetadata: () => void;
    metaFiterModalCancelHandler: () => void;
}

const MetaFilterModal: FC<MetadataFilterModal> = ({ metaFilterModalOpen, getFilteredMetadata, metaFiterModalCancelHandler, form }) => {
    return (
        <StyledFilterModal
            open={metaFilterModalOpen}
            okText="Filter"
            cancelText="Close"
            onOk={getFilteredMetadata}
            title="Filter Metadata By"
            centered
            onCancel={metaFiterModalCancelHandler}
        >
            <Form form={form} layout='vertical'>
                <Item label="Ref ID" name="ref_id_filter">
                    <Input placeholder='Ref ID' />
                </Item>
            </Form>
        </StyledFilterModal>
    )
}

export default MetaFilterModal