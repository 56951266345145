import { Form } from "antd";
import { exportCSVWithLambda } from "../../../../utils";
import { useOutletContext } from "react-router-dom";

const useWurlExportHook = () => {
	const [form] = Form.useForm();
	const { user }: any = useOutletContext();

	const exportWurlData = async () => {
		const values = await form.validateFields();
		await exportCSVWithLambda(values, "platforms/wurl", user.name, "Wurl Export");
	};
	return { form, exportWurlData };
};

export default useWurlExportHook;
