import React, { FC } from "react";
import { episodeFormFields, useEpisodeHook } from "./hooks/useEpisodeHook";
import { Button, Col, Row, Spin, MenuProps, Space, Dropdown } from "antd";
import {
	ComponentTitle,
	PageTitleLastCol,
	SearchForm,
	SelectedCountTag,
} from "../custom-components";
import ChangeStatusModal from "./modals/ChangeStatusModal";
import { CustomTable } from "../series/StyledComponent";
import AddEpisodeForm from "./AddEpisodeForm";
import AddVideosMetaDataForm from "../videos/modals/AddVideosMetaDataForm";
import EpisodeFilterModal from "./modals/EpisodeFilterModal";
import { ClearFilterButton } from "../metadata";
import { CloseCircleOutlined, DownOutlined } from "@ant-design/icons";
import { TabComponentProps } from "../utils/common.interface";
import AddCaptionsForm from "../captions/modals/AddCaptionsForm";
import useScreenSizeHook from "../utils/useScreenSizeHook";
import { useNavigate } from "react-router-dom";
import CuePointsFormModal from "../cue-points/modals/CuePointsFormModal";
import ThumbnailFormModal from "../thumbnails/modals/ThumbnailFormModal";
import VideoPlayer from "../custom-components/VideoPlayer";
import { HomeTabKeyEnum } from "../utils";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import CommonExportModal from "../videos/modals/export";

export interface DistributionDataPropsTypes {
	distributionCheckedList: any[];
	distributionChange: (checkedValue: CheckboxValueType[]) => void;
	onDistributionCheckAllChange: (e: CheckboxChangeEvent) => void;
	checkAll: boolean;
	indeterminate: boolean;
}

const EpisodeComponent: FC<TabComponentProps> = () => {
	const navigate = useNavigate();
	const { height } = useScreenSizeHook();
	const pageURL = HomeTabKeyEnum.EPISODE;
	const {
		columnEpisode,
		isLoading,
		setIsEpisodeFormOpen,
		episodeData,
		isAddEpisdoeFormOpen,
		episodeEditId,
		form,
		seriesData,
		saveUpdateEpisodeData,
		cancelEpisodeFormHandler,
		metadataSelector,
		isAddNewMetadata,
		durationValue,
		setDurationValue,
		addNewInputHelper,
		videoEditId,
		setFormArray,
		formArray,
		setIsLoading,
		cancelVideoFormHandler,
		relatedMetadata,
		languages,
		tagsData,
		handleAddCustomTag,
		ref_id,
		isAddVideoFormOpen,
		saveUpdateVideoData,
		changeHandler,
		getFileteredData,
		filterEpisodeModalOpen,
		setFilterEpisodeModalOpen,
		filterModalCancelHandler,
		isFilteredData,
		clearFilter,
		handleTableChange,
		exportCSV,
		distributionPlatform,
		isExportModalOpen,
		setIsExportModalOpen,
		captionDataHandler,
		isOpenCaptionsForm,
		cancelCaptionFormHandler,
		captionEditId,
		metadataEditId,
		searchData,
		pagination,
		ratings,
		hiddenEpisodesHandler,
		episodeFilterObj,
		saveCuePoint,
		handleCuePointCancel,
		cuePointEditId,
		isAddCuePointFormOpen,
		thumbnailEditId,
		isAddThumbnailFormOpen,
		saveEditThumbnail,
		handleThumbnailCancel,
		episodeRelatedDataTabHandler,
		episodeDataTabs,
		videoPlayerDetails,
		closeVideo,
		distributionCheckedList,
		distributionChange,
		onDistributionCheckAllChange,
		checkAll,
		indeterminate,
		isDuplicateCuePointFormOpen,
		cuepointFields,
		setCuepointFields,
		create5SecOffset,
		setCreate5SecOffset,
		videoLists,
		exportFormat,
		setExportFormat,
		activeDatesList,
		setActiveDatesList,
		setIsAddNewMetadata,
		setMetadataEditId,
		selectedEpisodeData,
		clearEpisodeSelection,
		isChangeStatusModalOpen,
		setIsChangeStatusModalOpen,
		changeStatusModalCancelHandler,
		statusModalSubmitHandler,
	} = useEpisodeHook();

	const distributionProps = {
		distributionCheckedList,
		distributionChange,
		onDistributionCheckAllChange,
		checkAll,
		indeterminate,
	};

	const bulkActionItems: MenuProps["items"] = [
		{
			label: <span>Status</span>,
			key: "status",
			onClick: () => setIsChangeStatusModalOpen(true),
		},
	];

	return (
		<Spin spinning={isLoading}>
			{isAddEpisdoeFormOpen ? (
				<AddEpisodeForm
					cancelEpisodeFormHandler={cancelEpisodeFormHandler}
					saveUpdateDataHandler={saveUpdateEpisodeData}
					seriesData={seriesData}
					form={form}
					tagsData={tagsData}
					episodeEditId={episodeEditId}
					ratings={ratings}
					episodeDataTabs={episodeDataTabs}
					episodeRelatedDataTabHandler={episodeRelatedDataTabHandler}
				/>
			) : (
				<>
					<Row justify="space-between" align="middle">
						<Col>
							<ComponentTitle>Episodes</ComponentTitle>
						</Col>
						<Col>
							{selectedEpisodeData.length > 0 && (
								<Space>
									<SelectedCountTag color="green">
										<span>{selectedEpisodeData.length} Selected</span>
										<span title="Clear Selection" onClick={clearEpisodeSelection} id="close-icon">
											X
										</span>
									</SelectedCountTag>
									<Dropdown menu={{ items: bulkActionItems }} trigger={["click"]}>
										<Button>
											Actions <DownOutlined />
										</Button>
									</Dropdown>
								</Space>
							)}
						</Col>
						<PageTitleLastCol>
							<Button type="primary" onClick={hiddenEpisodesHandler}>
								{episodeFilterObj.showHiddenEpisodes ? "Hide" : "Show"} Inactive
							</Button>
							&nbsp;
							<Button
								type="primary"
								onClick={() => {
									form.resetFields();
									setIsEpisodeFormOpen(true);
								}}
							>
								+ Add Episode
							</Button>
							&nbsp;
							<Button onClick={() => setIsExportModalOpen(true)}>Export</Button>
							&nbsp;
							<Button onClick={() => setFilterEpisodeModalOpen(true)}>Filters</Button>
							{isFilteredData && (
								<ClearFilterButton onClick={() => clearFilter()} icon={<CloseCircleOutlined />} />
							)}
							<SearchForm
								form={form}
								searchData={searchData}
								placeholder="Search by Ref ID or Metadata Title"
							/>
						</PageTitleLastCol>
					</Row>
					<CustomTable
						onChange={handleTableChange}
						columns={columnEpisode}
						dataSource={episodeData}
						scroll={{ x: 2000, y: height - 265 }}
						pagination={pagination}
						onRow={(record: any) => {
							return {
								onClick: e => {
									if (e.metaKey || e.ctrlKey) {
										const newTab = window.open(pageURL + "/" + record.ref_id, "_blank");
										newTab?.focus();
									} else {
										sessionStorage.setItem("episodeFilter", JSON.stringify(episodeFilterObj));
										navigate(`${pageURL}/${record.ref_id}`);
									}
								},
							};
						}}
					/>
				</>
			)}
			{isAddVideoFormOpen && (
				<AddVideosMetaDataForm
					metadataSelector={metadataSelector}
					isAddNewMetadata={isAddNewMetadata}
					setIsAddNewMetadata={setIsAddNewMetadata}
					setMetadataEditId={setMetadataEditId}
					durationValue={durationValue}
					setDurationValue={setDurationValue}
					addNewInputHelper={addNewInputHelper}
					videoEditId={videoEditId}
					setFormArray={setFormArray}
					formArray={formArray}
					form={form}
					isOpen={isAddVideoFormOpen}
					saveVideosData={saveUpdateVideoData}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					handleCancel={cancelVideoFormHandler}
					relatedMetadata={relatedMetadata}
					ref_id={ref_id}
					languages={languages}
					changeHandler={changeHandler}
					tagsData={tagsData}
					handleAddCustomTag={handleAddCustomTag}
					distributionPlatform={distributionPlatform}
					metadataEditId={metadataEditId}
					videosData={episodeDataTabs.data.videos}
					videoLists={videoLists}
					activeDatesList={activeDatesList}
					setActiveDatesList={setActiveDatesList}
					{...distributionProps}
				/>
			)}
			{filterEpisodeModalOpen && (
				<EpisodeFilterModal
					filterModalCancelHandler={filterModalCancelHandler}
					form={form}
					filterEpisodeModalOpen={filterEpisodeModalOpen}
					seriesData={seriesData}
					getFileteredData={getFileteredData}
					tagsData={tagsData}
					distributionPlatform={distributionPlatform}
				/>
			)}
			{isChangeStatusModalOpen && (
				<ChangeStatusModal
					cancelHandler={changeStatusModalCancelHandler}
					modalOpen={isChangeStatusModalOpen}
					form={form}
					submitHandler={statusModalSubmitHandler}
				/>
			)}
			{/* <ExportModal
				exportCSV={exportCSV}
				isExportModalOpen={isExportModalOpen}
				setIsExportModalOpen={setIsExportModalOpen}
				form={form}
			/> */}
			{isOpenCaptionsForm && (
				<AddCaptionsForm
					isLoading={isLoading}
					cancelHandler={cancelCaptionFormHandler}
					editCaptionId={captionEditId}
					form={form}
					isCaptionsModalOpen={isOpenCaptionsForm}
					languages={languages}
					saveUpdateCaptionsData={captionDataHandler}
					distributionPlatformData={distributionPlatform}
					{...distributionProps}
				/>
			)}
			{isAddCuePointFormOpen && (
				<CuePointsFormModal
					create5SecOffset={create5SecOffset}
					setCreate5SecOffset={setCreate5SecOffset}
					cuepointFields={cuepointFields}
					setCuepointFields={setCuepointFields}
					cuePointEditId={cuePointEditId}
					saveEditCuePointsData={saveCuePoint}
					isOpen={isAddCuePointFormOpen}
					handleCancel={handleCuePointCancel}
					isLoading={isLoading}
					form={form}
					distributionPlatformData={distributionPlatform}
					isDuplicateCuePointFormOpen={isDuplicateCuePointFormOpen}
					{...distributionProps}
				/>
			)}
			{isAddThumbnailFormOpen && (
				<ThumbnailFormModal
					handleAddCustomTag={handleAddCustomTag}
					thumbnailEditId={thumbnailEditId}
					isOpen={isAddThumbnailFormOpen}
					saveEditThumbnailData={saveEditThumbnail}
					handleCancel={handleThumbnailCancel}
					isLoading={isLoading}
					form={form}
					distributionPlatformData={distributionPlatform}
					tagsList={tagsData}
					{...distributionProps}
				/>
			)}
			<CommonExportModal
				exportFormat={exportFormat}
				setExportFormat={setExportFormat}
				distributionPlatformData={distributionPlatform}
				isExportModalOpen={isExportModalOpen}
				setIsExportModalOpen={setIsExportModalOpen}
				form={form}
				exportCSV={exportCSV}
			/>
			{videoPlayerDetails && <VideoPlayer {...videoPlayerDetails} closeVideo={closeVideo} />}
		</Spin>
	);
};

export default EpisodeComponent;
