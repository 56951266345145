import React from "react";
import { Col, Form, FormInstance } from "antd";
import { StyledFilterModal } from "../../episode/modals/EpisodeFilterModal";
import { BorderedRow, FilterInfoTitle } from "../../custom-components";
import { VideoLists } from "../../video-lists/hooks/useVideoListsHook";
import {
	DistributionPlatformsItem,
	EpisodeRelatedFiltersForAssets,
	TagsFilterItem,
} from "../../custom-components/form/fliter";
import { TagOption } from "../../tags/hooks/utils";

interface ThumbnailFilterModalProps {
	thumbnailFilterModalOpen: boolean;
	form: FormInstance;
	getFilteredThumbnail: () => void;
	seriesData: any[];
	thumbnailFilterModalCancelHandler: () => void;
	tagsData: any[];
	distributionPlatformData: any[];
	videoLists: VideoLists[];
}

const ThumbnailFilterModal: React.FC<ThumbnailFilterModalProps> = ({
	thumbnailFilterModalOpen,
	form,
	seriesData,
	getFilteredThumbnail,
	tagsData,
	distributionPlatformData,
	thumbnailFilterModalCancelHandler,
	videoLists,
}) => {
	return (
		<StyledFilterModal
			open={thumbnailFilterModalOpen}
			okText="Filter"
			cancelText="Close"
			onOk={getFilteredThumbnail}
			title="Filter Thumbnails By"
			maskClosable={false}
			centered
			onCancel={thumbnailFilterModalCancelHandler}
		>
			<FilterInfoTitle>Show thumbnails that belong to Episodes with these filters:</FilterInfoTitle>
			<Form form={form} layout="vertical">
				<EpisodeRelatedFiltersForAssets seriesData={seriesData} videoLists={videoLists} />
				<FilterInfoTitle>Show thumbnails that match these filters:</FilterInfoTitle>
				<BorderedRow>
					<Col span={24}>
						<DistributionPlatformsItem
							distributionPlatformData={distributionPlatformData}
							mode="multiple"
							name="distribution_platforms_filter"
						/>
					</Col>
					<Col span={24}>
						<DistributionPlatformsItem
							distributionPlatformData={distributionPlatformData}
							label="Not On Distribution Platforms"
							name="not_distribution_platforms_filter"
							placeholder="Isn't distributed on"
							mode="multiple"
						/>
					</Col>
					<Col span={24}>
						<TagsFilterItem
							tagsData={tagsData}
							label="Has Tags"
							name="thumbnails_tags_filter"
							placeholder="Have Thumbnail tags"
							mode="multiple"
							for_thumbnails
						/>
					</Col>
					<Col span={24}>
						<TagsFilterItem
							tagsData={tagsData}
							label="Not Tagged"
							name="thumbnails_not_tagged"
							placeholder="Don't have Thumbnail tags"
							mode="multiple"
							for_thumbnails
						/>
					</Col>
				</BorderedRow>
			</Form>
		</StyledFilterModal>
	);
};

export default ThumbnailFilterModal;
