import React, { Fragment, useState, useEffect } from "react";
import { Form, Tag, message } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import { EditOutlined } from "@ant-design/icons";
import { useOutletContext } from "react-router-dom";

import { SETTINGS_DATA, getTagsData, updateUserPageSizeSettings } from "../../utils";
import { CustomBtn } from "../../series/StyledComponent";
import { authAxios } from "../../utils/session_utils";
import { initialPaginationObj } from "../../episode/hooks/useEpisodeHook";
import { PaginationProps } from "antd/lib/pagination";
import { TagOption, TagsFormValues, saveTagsData } from "./utils";
import { DeletePopConfirm } from "../../custom-components";

export const useTagsHooks = () => {
	const { tagsList, setTagsList, user, setUser }: any = useOutletContext();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [form] = Form.useForm();
	const [isAddTagsFormOpen, setIsTagsFormOpen] = useState<boolean>(false);
	const [editId, setEditId] = useState<string | undefined>();
	const [currentEditEpisodeData, setCurrentEditEpisodeData] = useState<any>();
	const [warningModalOpen, setWarningModalOpen] = useState<boolean>(false);
	const [dataToBeDeleted, setDataToBeDeleted] = useState<any>();
	const [pagination, setPagination] = useState<PaginationProps>({
		...initialPaginationObj,
		pageSize: user.page_sizes?.tags ?? 10,
		total: tagsList.length,
	});
	useEffect(() => {
		setPagination({ ...pagination, total: tagsList.length });
	}, [tagsList]);

	const handleTableChange = (pagination: PaginationProps, _: any, __: any, { action }: any) => {
		if (action === "paginate") {
			if (pagination.pageSize !== user.page_sizes.tags) {
				const updatedSettingsData: SETTINGS_DATA = {
					user_id: user.user_id,
					page_sizes: { ...user.page_sizes, tags: pagination.pageSize },
				};
				updateUserPageSizeSettings(updatedSettingsData);
				setUser({ ...user, ...updatedSettingsData });
			}
			setPagination(pagination);
		}
	};

	const updateTagDataWithTest = async () => {
		try {
			setIsLoading(true);
			const values: TagsFormValues = await form.validateFields();
			const allRelationList = ["video", "thumbnail"];
			const newRelationSet = new Set(values.related_to);
			let missingRelation = "";
			allRelationList.forEach((relation: string) => {
				if (!newRelationSet.has(relation)) {
					missingRelation = relation;
				}
			});
			if (missingRelation) {
				const connectionTableObj: any = {
					thumbnail: "thumbnail_tags",
					video: "video_tags",
				};
				const config = {
					url: `${process.env.REACT_APP_BACK_END_API}/snowflake/total-connections/tag/${connectionTableObj[missingRelation]}/${editId}`,
					method: "GET",
				};
				const { data } = await authAxios(config);
				if (data.count || data.episodeCount) {
					setIsLoading(false);
					setDataToBeDeleted({
						count: data.count,
						episodeCount: data.episodeCount,
						missingRelation,
						tag: values.title,
					});
					return setWarningModalOpen(true);
				}
			}
			await saveTagsDataHandler();
		} catch (error) {
			setIsLoading(false);
			console.error("Error while updating with test", error);
		}
	};

	const saveTagsDataHandler = async () => {
		try {
			setIsLoading(true);
			// update data
			const values: TagsFormValues = await form.validateFields();
			await saveTagsData(values, tagsList, editId, currentEditEpisodeData);
			handleCancel();
			await getTagsData(setTagsList, setIsLoading);
			setIsLoading(false);
		} catch (err: any) {
			setIsLoading(false);
			console.error("erro while saving tags data", err);
			const errMsg = err?.response?.data?.message;
			if (errMsg) message.error(errMsg);
		}
	};

	const deleteTagsData = async (record: TagOption) => {
		try {
			const config = {
				method: "delete",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/tags/${record.id}`,
				headers: {
					"Content-Type": "application/json",
				},
			};
			setIsLoading(true);
			const response = await authAxios(config);

			if (!response.data.err) {
				message.success(response.data.message);
				getTagsData(setTagsList, setIsLoading);
			}
		} catch (err) {
			setIsLoading(false);
			console.error("erro", err);
		}
	};
	const handleCancel = (isWarningModal?: boolean) => {
		if (isWarningModal) return setWarningModalOpen(false);
		form.resetFields();
		setIsTagsFormOpen(false);
		setWarningModalOpen(false);
		setEditId(undefined);
		setCurrentEditEpisodeData(undefined);
	};

	const editHelper = async (record: TagOption) => {
		setIsTagsFormOpen(true);
		setCurrentEditEpisodeData(record);
		form.setFieldsValue({ ...record, related_to: record.related_to.split(",") });
		setEditId(record.id);
	};
	const tagsSeries: ColumnsType<object> = [
		{
			title: "Tag",
			dataIndex: "title",
			key: "title",
			align: "center",
			render: value => {
				return <Tag color="green">{value}</Tag>;
			},
		},
		{
			title: "For",
			dataIndex: "related_to",
			key: "related_to",
			align: "center",
			sorter: (a: any, b: any) => a.related_to.localeCompare(b.related_to),
			render: value => {
				const obj: { [key: string]: string } = {
					"video": "Video/Episode",
					"thumbnail": "Thumbnail",
					"video,thumbnail": "Video/Episode, Thumbnail",
					"thumbnail,video": "Video/Episode, Thumbnail",
				};
				return obj[value];
			},
		},
		{
			title: "Action",
			dataIndex: "action",
			key: "action",
			align: "center",
			render: (value, record: any) => {
				return (
					<Fragment>
						<CustomBtn
							icon={<EditOutlined />}
							type={"link"}
							onClick={e => {
								e.stopPropagation();
								editHelper(record);
							}}
						/>
						<DeletePopConfirm title="Delete Tag?" deleteHandler={() => deleteTagsData(record)}>
							{`Are you sure you want to delete this tag? It will delete all relationship between ${
								record.related_to === "thumbnail"
									? "thumbnails"
									: record.related_to === "video"
									? "videos and episodes"
									: "videos and episodes and thumbnails"
							}`}
						</DeletePopConfirm>
					</Fragment>
				);
			},
		},
	];
	return {
		tagsSeries,
		tagsData: tagsList,
		isLoading,
		setIsLoading,
		getTagsData,
		saveTagsData: saveTagsDataHandler,
		isAddTagsFormOpen,
		setIsTagsFormOpen,
		form,
		deleteTagsData,
		handleCancel,
		editId,
		editHelper,
		pagination,
		handleTableChange,
		warningModalOpen,
		dataToBeDeleted,
		setWarningModalOpen,
		updateTagDataWithTest,
	};
};
