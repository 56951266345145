import { PaginationProps, message, notification } from "antd";
import { Dispatch, SetStateAction } from "react";
import { SortObj } from "../../videos/videos";
import { authAxios } from "../../utils/session_utils";
import { makeKeyInLowerCaseHelper } from "../../utils";
import { TitleConflictData } from "./title-conflict";

const titleConflictRoute = "title-conflict";

export const getTitleConflictsData = async (
    setTitleConflictsData: Dispatch<SetStateAction<TitleConflictData[]>>,
    setLoading: Dispatch<SetStateAction<boolean>>,
    pagination: PaginationProps,
    titleSortObj: SortObj,
    searchQuery: string,
    titleFilterObj?: any
) => {
    try {
        const config: any = {
            method: "GET",
            url: `${
                process.env.REACT_APP_BACK_END_API
            }/snowflake/${titleConflictRoute}/?pagination=${JSON.stringify(
                pagination
            )}&sortObj=${JSON.stringify(titleSortObj ?? {})}&filter=${JSON.stringify(
                titleFilterObj ?? {}
            )}&searchQuery=${searchQuery}`,
            headers: {
                "Content-Type": "application/json",
            },
        };
        const response = await authAxios(config);
        const convertedObject = await makeKeyInLowerCaseHelper(response.data.rows);
        setTitleConflictsData(convertedObject as unknown as TitleConflictData[]);
        setLoading(false);
    } catch (error) {
        console.error(error);
        throw new Error();
    }
};

export const saveUpdateTitleConflict = async (values: TitleConflictData, editId?: string) => {
    try {
        const bodyData = {
            ...values,
            id: editId,
        };
        const config = {
            method: editId ? "put" : "post",
            url: `${process.env.REACT_APP_BACK_END_API}/snowflake/${titleConflictRoute}`,
            headers: { "Content-Type": "application/json" },
            data: JSON.stringify(bodyData),
        };
        const response = await authAxios(config);
        if (response.status === 200) {
            notification.info({
                duration: 5,
                message: "Invalid Ref ID",
                description: "This ref ID does not exist in our database.",
            });
            throw new Error(response.data.message);
        }
        return response;
    } catch (error: any) {
        if (error?.response.status === 409) {
            notification.error({
                duration: 5,
                message: "Record already exists",
                description: "This title conflict already exists.",
            })
        }
        throw new Error("error in adding or updating title conflict", { cause: error });
    }
};

export const deleteTitleConflict = async (titleId: string) => {
    try {
        const config = {
            method: "delete",
            url: `${process.env.REACT_APP_BACK_END_API}/snowflake/${titleConflictRoute}/${titleId}`,
            headers: {},
        };
        const response = await authAxios(config);
        if (!response.data.err) {
            message.success(response.data.message);
        }
    } catch (error) {
        console.error("error in deleting title conflict", error);
        throw new Error("error in deleting title conflict", { cause: error });
    }
};

export const getAvailableMetadata = async () => {
    try {
        const config = {
            method: "GET",
            url: `${process.env.REACT_APP_BACK_END_API}/snowflake/metadata/available`,
            headers: {},
        };
        const response = await authAxios(config);
        return response.data;
    } catch (error) {
        console.error("error in fetching available metadata", error);
        throw new Error("error in fetching available metadata", { cause: error });
    }
};

export const assignMetadataToTitle = async (titleId: string, metadataId: string) => {
    try {
        const config = {
            method: "POST",
            url: `${process.env.REACT_APP_BACK_END_API}/snowflake/${titleConflictRoute}/assign-metadata`,
            headers: { "Content-Type": "application/json" },
            data: JSON.stringify({ titleId, metadataId }),
        };
        const response = await authAxios(config);
        return response.data;
    } catch (error) {
        console.error("error in assigning metadata to title", error);
        throw new Error("error in assigning metadata to title", { cause: error });
    }
};


export const saveAllTitleConflicts = async (titleConflicts: TitleConflictData[], userEmail: string) => {
    try {
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_BACK_END_API}/snowflake/title-conflict/batch`,
            headers: { "Content-Type": "application/json" },
            data: JSON.stringify({ titleConflicts, userEmail }),
        };

        const response = await authAxios(config);

        if (response.status === 200) {
            notification.success({
                message: "Save Successful",
                description: "All title conflicts have been sent to the server for processing.",
            });
            return response.data;
        } else {
            throw new Error("Unexpected response status");
        }
    } catch (error: any) {
        console.error("Error in saving all title conflicts", error);
        notification.error({
            message: "Save Failed",
            description: "An error occurred while sending title conflicts to the server. Please try again.",
        });
        throw new Error("Error in saving all title conflicts", { cause: error });
    }
};
