import React, { Fragment } from "react";
import { Row, Col, Button } from "antd";
import { ComponentTitle, PageTitleLastCol, SearchForm } from "../custom-components";
import useAlternateTitles from "./hooks/useAlternateTitles";
import { CustomTable } from "../series/StyledComponent";
import AlternateTitleForm from "./modal/AlternateTitleForm";
import useScreenSizeHook from "../utils/useScreenSizeHook";
import AlternateTitleFilterModal from "./modal/AlternateTitleFilter";
import { ClearFilterButton } from "../metadata";
import { CloseCircleOutlined } from "@ant-design/icons";

const AlternateTitleComponent = () => {
	const { height } = useScreenSizeHook();
	const {
		isLoading,
		alternateTitleData,
		isAlternateTitleFormOpen,
		handleTableChange,
		saveUpdateDataHandler,
		searchData,
		alternateTitleColumns,
		editHandler,
		setIsAlternateTitleFormOpen,
		form,
		alternateTitleEditId,
		handleCancel,
		pagination,
		searchWithBlankRefId,
		setSearchWithBlankRefId,
		titleFilterModalOpen,
		filterModalCancelHandler,
		setTitleFilterModalOpen,
		getFileteredAlternateData,
		isFilteredAlternateTitleData,
		clearFilter,
		syncData,
	} = useAlternateTitles();
	return (
		<Fragment>
			<Row justify="space-between" align="middle">
				<Col>
					<ComponentTitle>Alternate Titles</ComponentTitle>
				</Col>
				<PageTitleLastCol>
					<Button type="primary" onClick={syncData}>
						Move Complete Titles
					</Button>
					&nbsp;
					<Button onClick={() => setTitleFilterModalOpen(true)}>Filter</Button>
					{isFilteredAlternateTitleData && (
						<ClearFilterButton
							onClick={clearFilter}
							title="Remove Filter"
							icon={<CloseCircleOutlined />}
						/>
					)}
					&nbsp;
					<Button type="primary" onClick={() => setIsAlternateTitleFormOpen(true)}>
						+ Alternate Title
					</Button>
					<SearchForm form={form} searchData={searchData} placeholder="Search by Ref ID or Title" />
				</PageTitleLastCol>
			</Row>
			<CustomTable
				onChange={handleTableChange}
				columns={alternateTitleColumns}
				dataSource={alternateTitleData}
				scroll={{ y: height - 265 }}
				pagination={pagination}
				loading={isLoading}
				onRow={(record: any) => {
					return {
						onClick: () => {
							editHandler(record);
						},
					};
				}}
			/>
			<AlternateTitleForm
				isOpen={isAlternateTitleFormOpen}
				saveEditData={saveUpdateDataHandler}
				form={form}
				isLoading={isLoading}
				handleCancel={handleCancel}
				editId={alternateTitleEditId}
			/>
			<AlternateTitleFilterModal
				titleFilterModalOpen={titleFilterModalOpen}
				form={form}
				getFileteredAlternateData={getFileteredAlternateData}
				filterModalCancelHandler={filterModalCancelHandler}
				searchWithBlankRefId={searchWithBlankRefId}
				setSearchWithBlankRefId={setSearchWithBlankRefId}
			/>
		</Fragment>
	);
};

export default AlternateTitleComponent;
