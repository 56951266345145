import React, { FC } from "react";
import { Modal, Form, Input, FormInstance } from "antd";
import styled from "@emotion/styled";

export const StyledFilterModal = styled(Modal)`
	& .ant-modal-header {
		margin-bottom: 20px;
	}
	& .ant-modal-content {
		max-height: 100vh;
	}
	& .ant-modal-body {
		overflow-y: auto;
		overflow-x: hidden;
		max-height: calc(100vh - 120px);
	}
	& .ant-modal-close {
		display: none;
	}
`;

const { Item } = Form;

interface ChangeStatusModal {
	form: FormInstance;
	cancelHandler: () => void;
	submitHandler: () => void;
	modalOpen: boolean;
}

const ChangeStatusModal: FC<ChangeStatusModal> = React.memo(
	({
		form,
		cancelHandler,
		submitHandler,
		modalOpen,
	}) => {
		return (
			<StyledFilterModal
				open={modalOpen}
				okText="OK"
				cancelText="Close"
				title="Change Status"
				closeIcon={null}
				centered
				maskClosable={false}
				onOk={submitHandler}
				onCancel={() => {
					cancelHandler();
				}}
			>
				<Form form={form} layout="vertical">
					<Item label="Status" name="changed_status">
						<Input placeholder="Status" onChange={e => form.setFieldValue("changed_status", e.target.value)}/>
					</Item>
				</Form>
			</StyledFilterModal>
		);
	}
);

ChangeStatusModal.displayName = "Change Status Modal";

export default ChangeStatusModal;
