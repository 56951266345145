import { Row } from "antd";
import React from "react";
import { ComponentTitle } from "../custom-components";
const Wurl = () => {
	return (
		<Row>
			<ComponentTitle>WURL component</ComponentTitle>
		</Row>
	);
};

export default Wurl;
