import React, { useState } from "react";
import { Row, Col, Table } from "antd";
import { ComponentTitle } from "../../custom-components";
import { TitleConflictData } from '../hooks/title-conflict';
import { TableRowSelection, TablePaginationConfig } from "antd/es/table/interface";
import styled from 'styled-components';

const StyledDiv = styled.div`
  .ant-table-row {
    background-color: white;
  }
  .ant-table-row-selected {
    background-color: #e6f7ff;
  }
  .ant-table-row:hover {
    background-color: #f5f5f5;
  }
  .ant-table-row-selected:hover {
    background-color: #e6f7ff;
  }
`;

interface ConflictsTableProps {
    height: number;
    isLoading: boolean;
    titleConflictData: TitleConflictData[];
    titleConflictColumns: any[];
    pagination: any;
    onConflictSelect: (record: TitleConflictData) => void;
    selectedConflict: TitleConflictData | null;
    linkedMetadata: { [key: string]: { ref_id: string, operation: string, language: string } };
}

const ConflictsTable: React.FC<ConflictsTableProps> = ({
    height,
    isLoading,
    titleConflictData,
    titleConflictColumns,
    onConflictSelect,
    selectedConflict,
    linkedMetadata,
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const rowSelection: TableRowSelection<TitleConflictData> = {
        type: 'radio',
        selectedRowKeys: selectedConflict ? [selectedConflict.id!] : [],
        onChange: (selectedRowKeys: React.Key[], selectedRows: TitleConflictData[]) => {
            if (selectedRows.length > 0) {
                onConflictSelect(selectedRows[0]);
            }
        },
    };

    const transformedData = titleConflictData.map(item => ({
        ...item,
        key: item.id,
        ID: item.id,
        REF_ID: item.ref_id,
        TITLE: item.title,
        LINKED_METADATA: linkedMetadata[item.ref_id] 
        ? linkedMetadata[item.ref_id].ref_id
        : 'Not Linked'    
    }));

    const updatedColumns = titleConflictColumns.map(column => {
        if (column.dataIndex === 'TITLE' || column.key === 'TITLE') {
            return { ...column, width: 150 };
        }
        return column;
    });

    updatedColumns.push({
        title: 'Linked Metadata',
        dataIndex: 'LINKED_METADATA',
        key: 'LINKED_METADATA',
    });


    const handleTableChange = (pagination: TablePaginationConfig) => {
        setCurrentPage(pagination.current || 1);
        setPageSize(pagination.pageSize || 10);
    };

    const paginatedData = transformedData.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
    );

    return (
        <StyledDiv>
            <Row justify="space-between" align="middle">
                <Col>
                    <ComponentTitle>Title Conflicts</ComponentTitle>
                </Col>
            </Row>
            <Table<TitleConflictData>
                rowSelection={rowSelection}
                columns={updatedColumns}
                dataSource={paginatedData}
                scroll={{ y: height - 200 }}
                pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: titleConflictData.length,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                loading={isLoading}
                rowKey="id"
                onChange={handleTableChange}
            />
        </StyledDiv>
    );
};

export default ConflictsTable;
