import React from 'react';
import { Modal, Button } from 'antd';

interface ErrorModalProps {
  visible: boolean;
  message: string;
  onClose: () => void;
}

const ErrorModal: React.FC<ErrorModalProps> = ({ visible, message, onClose }) => {
  return (
    <Modal
      title="Error"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="ok" type="primary" onClick={onClose}>
          OK
        </Button>
      ]}
    >
      <p>{message}</p>
    </Modal>
  );
};

export default ErrorModal;