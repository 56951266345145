import React, { FC } from "react";
import { Form, Input, Select } from "antd";
import styled from "@emotion/styled";
import { LanguageData } from "../videos/modals/AddVideosMetaDataForm";

export const TitleContainer = styled.div`
	border: 1px solid #ccc;
	border-radius: 8px;
	padding: 18px 16px 0px;
	position: relative;
	margin-bottom: 16px;
	margin-top: 20px;
`;

export const TitleFormText = styled.p`
	position: absolute;
	left: 14px;
	top: -12px;
	background: #fff;
	padding: 0 4px;
`;
type Props = {
	languagesList: LanguageData[];
	index: any;
};
const TitleWithLanguageFormElement: FC<Props> = ({ languagesList, index }: Props) => {
	return (
		<TitleContainer key={index}>
			<TitleFormText>Title</TitleFormText>
			<Form.Item key={`${index}_titleLanguage`} name={`${index}_titleLanguage`} rules={[{ required: true, message: "This is required" }]}>
				<Select placeholder="Language">
					{languagesList?.map((language: LanguageData) => (
						<Select.Option value={language.CODE} key={language.ID}>
							{language.NAME}
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item key={`${index}_titleText`} name={`${index}_titleText`} rules={[{ required: true, message: "This is required" }]}>
				<Input placeholder="Title Text" />
			</Form.Item>
		</TitleContainer>
	);
};

export default TitleWithLanguageFormElement;
