import { AxiosRequestConfig } from "axios";
import { useOutletContext } from "react-router-dom";
import { authAxios } from "../../../utils/session_utils";

const useUserSettingsHook = () => {
	const { user, setUser }: any = useOutletContext();

	const updateSettings = (key: string, value: boolean) => {
		try {
			setUser({ ...user, [key]: value });
			const updatedSettingsData = {
				user_id: user.user_id,
				[key]: value,
			};

			const settingsRouteObj: { [key: string]: string } = {
				wide_scrollbars: "updateWideScrollbar",
			};

			const config: AxiosRequestConfig = {
				method: "PUT",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/settings/${settingsRouteObj[key]}`,
				headers: {
					"Content-Type": "application/json",
				},
				data: JSON.stringify(updatedSettingsData),
			};
			authAxios(config);
		} catch (error) {
			console.error(error);
		}
	};

	return { user, updateSettings };
};

export default useUserSettingsHook;
