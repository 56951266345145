import { Checkbox, Col, Form, Input, Row, Spin } from "antd";
import React from "react";
import styled from "@emotion/styled";

import {
	BorderedRow,
	ComponentTitle,
	ExportButton,
	FilterInfoTitle,
} from "../../custom-components";
import { TaskRow } from "../../zype/styled-components";
import { ExportCard } from "../video";
import useAssetsExportHook from "./hooks/useAssetsExportHook";
import { getDigitsOnly } from "../../utils";
import {
	CaptionTypeFilterItem,
	EpisodeRelatedFiltersForAssets,
	LanguageSelectItem,
} from "../../custom-components/form/fliter";

const AssetTypesChecbox = styled(Checkbox.Group)`
	margin: 8px 0 16px 0;
`;

const options = [
	{
		label: "Captions",
		value: "captions",
	},
	{
		label: "Cue Points",
		value: "cue-points",
	},
	{
		label: "Thumbnails",
		value: "thumbnails",
	},
];

const { Item } = Form;

const AssetsExportComponent = () => {
	const {
		form,
		exportAssetsCSV,
		loading,
		distributionPlatformData,
		isLoading,
		seriesList,
		languages,
		videoLists,
		assetTypes,
		setAssetTypes,
	} = useAssetsExportHook();

	return (
		<Spin spinning={loading}>
			<Row justify="center">
				<Col>
					<ComponentTitle>Assets Export</ComponentTitle>
				</Col>
			</Row>
			<TaskRow>
				<ExportCard
					actions={[
						<ExportButton
							loading={isLoading}
							onClick={exportAssetsCSV}
							type="primary"
							key="assets-export-btn"
						/>,
					]}
				>
					<FilterInfoTitle>
						Export Assets that belong to Episodes with these filters:
					</FilterInfoTitle>
					<Form form={form} requiredMark={false} layout="vertical">
						<EpisodeRelatedFiltersForAssets
							seriesData={seriesList}
							videoLists={videoLists}
							distributionPlatformData={distributionPlatformData}
						/>
						<FilterInfoTitle>Export Assets that match these filters:</FilterInfoTitle>
						<BorderedRow>
							<Col span={24}>
								Asset Types:{" "}
								<AssetTypesChecbox
									options={options}
									value={assetTypes}
									onChange={checkedValues => setAssetTypes(checkedValues)}
								/>
							</Col>
							<Col span={24}>
								<Item name="offset_time" label="Offset Time (Captions and Cue Points)">
									<Input
										disabled={
											!assetTypes.includes("captions") && !assetTypes.includes("cue-points")
										}
										placeholder="Offset Time(in sec)"
										onChange={e => form.setFieldValue("offset_time", getDigitsOnly(e.target.value))}
									/>
								</Item>
							</Col>
							<Col span={24}>
								<CaptionTypeFilterItem
									mode="multiple"
									name="caption_type"
									label="Caption Type (Captions only)"
									disabled={!assetTypes.includes("captions")}
								/>
							</Col>
							<Col span={24}>
								<LanguageSelectItem
									label="Caption Languages (Captions only)"
									languages={languages}
									placeholder="Caption Languages"
									mode="multiple"
									name="languages_filter"
									disabled={!assetTypes.includes("captions")}
								/>
							</Col>
						</BorderedRow>
					</Form>
				</ExportCard>
			</TaskRow>
		</Spin>
	);
};

export default AssetsExportComponent;
