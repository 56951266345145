import React from "react";
import { TitleContainer, TitleFormText } from "../TitleWithLanguageFormElement";
import { Form, Input, Select } from "antd";
import { LanguageData } from "../../videos/modals/AddVideosMetaDataForm";

type Props = {
	languagesList: LanguageData[];
	index: any;
};

const SeriesFormElement: React.FC<Props> = ({ index, languagesList }) => {
	return (
		<TitleContainer key={index}>
			<TitleFormText>Description</TitleFormText>
			<Form.Item
				key={`${index}_descriptionLanguage`}
				name={`${index}_descriptionLanguage`}
				rules={[{ required: true, message: "This is required" }]}
			>
				<Select placeholder="Language">
					{languagesList?.map((language: LanguageData) => (
						<Select.Option value={language.CODE} key={language.ID}>
							{language.NAME}
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item
				key={`${index}_descriptionText`}
				name={`${index}_descriptionText`}
				rules={[{ required: true, message: "This is required" }]}
			>
				<Input.TextArea placeholder="Description" showCount />
			</Form.Item>
		</TitleContainer>
	);
};

export default SeriesFormElement;
