import React, { Dispatch, SetStateAction } from "react";
import { ColumnsType } from "antd/es/table";
import { CustomBtn } from "../../series/StyledComponent";
import { EditOutlined } from "@ant-design/icons";
import { message } from "antd";
import { PaginationProps } from "antd/lib";
import { authAxios } from "../../utils/session_utils";
import { HomeTabKeyEnum, makeKeyInLowerCaseHelper } from "../../utils";
import { DeletePopConfirm, StyledLink } from "../../custom-components";

export interface VideoListItem {
	id: string;
	name: string;
	total_videos: string;
	created_at: string;
	updated_at: string;
	owner: string;
	is_private: boolean;
}

export const videoListsColumns = (
	editHelper: (record: any) => void,
	deleteVideoList: (record: any) => void
): ColumnsType<any> => [
	{
		title: "List Name",
		dataIndex: "name",
		key: "name",
		width: "150px",
		render: (value: string, record: any) => {
			return <StyledLink to={HomeTabKeyEnum.VIDEOS + "?list_id=" + record.id}>{value}</StyledLink>;
		},
	},
	{
		title: "#Videos",
		dataIndex: "total_videos",
		key: "total_videos",
		width: "100px",
	},
	{
		title: "Created Date",
		dataIndex: "created_at",
		key: "created_at",
		width: "250px",
	},
	{
		title: "Updated Date",
		dataIndex: "updated_at",
		key: "updated_at",
		width: "250px",
	},
	{
		title: "Owner",
		dataIndex: "owner",
		key: "owner",
		width: "150px",
	},
	{
		title: "Actions",
		dataIndex: "action",
		key: "action",
		width: "80px",
		render: (value, record: any) => {
			return (
				<>
					<CustomBtn
						title="Edit"
						icon={<EditOutlined />}
						type={"link"}
						onClick={e => {
							e.stopPropagation();
							editHelper(record);
						}}
					/>
					<DeletePopConfirm
						title="Delete Video List?"
						deleteHandler={() => deleteVideoList(record.id)}
					>
						Are you sure you want to delete this Video List?
					</DeletePopConfirm>
				</>
			);
		},
	},
];

export const getVideoLists = async (
	setVideoListsData: Dispatch<SetStateAction<any[]>>,
	setIsLoading: Dispatch<SetStateAction<boolean>> | null,
	pagination: PaginationProps,
	setPagination: Dispatch<SetStateAction<PaginationProps>> | null,
	filter: any
) => {
	try {
		const config = {
			method: "get",
			url: `${process.env.REACT_APP_BACK_END_API}/snowflake/video-list?pagination=${
				pagination ? JSON.stringify(pagination) : ""
			}&filter=${filter ? JSON.stringify(filter) : ""}`,
			headers: {
				"Content-Type": "application/json",
			},
		};
		const response = await authAxios(config);
		setPagination && setPagination({ ...pagination, total: response.data.total });
		const convertedObject = await makeKeyInLowerCaseHelper(response.data.rows);
		setVideoListsData(convertedObject);
		setIsLoading && setIsLoading(false);
	} catch (error) {
		console.error("error in fetching Video lists", error);
		setIsLoading && setIsLoading(false);
	}
};

export const saveUpdateVideoListHandler = async (data: any, listEditId?: string) => {
	try {
		const config = {
			method: !listEditId ? "POST" : "PUT",
			url: `${process.env.REACT_APP_BACK_END_API}/snowflake/video-list`,
			data: JSON.stringify(data),
			headers: { "Content-Type": "application/json" },
		};
		const response = await authAxios(config);
		message.success(response.data.message);
	} catch (error) {
		console.error("error in saveUpdateVideoListHandler", error);
		throw new Error("error in video list new data handler");
	}
};
