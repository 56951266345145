import { Form, FormInstance, Input, Modal, Select } from "antd";
import React, { FC } from "react";

const { Item } = Form;

type Props = {
	isOpen: boolean;
	submitHandler: () => void;
	onCancel: (warningModal?: boolean) => void;
	form: FormInstance;
	isLoading: boolean;
	tagsEditId?: string;
};

const AddTagsForm: FC<Props> = ({
	isLoading,
	isOpen,
	onCancel,
	form,
	submitHandler,
	tagsEditId,
}) => {
	return (
		<Modal
			open={isOpen}
			onOk={submitHandler}
			onCancel={() => onCancel()}
			okButtonProps={{ loading: isLoading }}
			cancelButtonProps={{ disabled: isLoading }}
			okText={!tagsEditId ? "Add Tag" : "Update Tag"}
			title={!tagsEditId ? "Add Tag" : "Update Tag"}
		>
			<Form form={form} requiredMark={false} layout="vertical">
				<Item
					label="Title"
					name="title"
					rules={[{ required: true, message: "This field is required" }]}
				>
					<Input placeholder="Tag Title"></Input>
				</Item>
				<Item
					label="For (video/thumbnail)"
					name="related_to"
					rules={[{ required: true, message: "This field is required" }]}
				>
					<Select placeholder="This will be used for video/episode" mode="multiple">
						<Select.Option value="video">Video/Episode</Select.Option>
						<Select.Option value="thumbnail">Thumbnail</Select.Option>
					</Select>
				</Item>
			</Form>
		</Modal>
	);
};

export default AddTagsForm;
